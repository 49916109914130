/** @jsxImportSource @emotion/react */
import { StyledTableHead, styles } from './TableHeader.styles';
import { StyledTableRow, styles as futureTableStyles } from '../../FutureTable.styles';
import { ColumnHeader } from '../ColumnHeader';

const TableHeader = ({
  headerGroups,
  columnsOptions,
  filterNamesReachable,
  visibleColumns,
  expandedColumnIds,
  handleExpandColumn,
  expandedColumnWidth,
  setExpandedColumnWidth,
  fixedColsPositions,
  actionsColumn,
}) => {
  const isSomeColumnFilterable = headerGroups[0]?.headers.some((column) => column.filterable) || false;

  return (
    <StyledTableHead className="table-thead -header" css={styles.tableHead(isSomeColumnFilterable)}>
      {headerGroups.map((headerGroup) => (
        <StyledTableRow {...headerGroup.getHeaderGroupProps()} className="table-tr" css={futureTableStyles.tableRow(false)}>
          {actionsColumn && (
            <th className="table-th table-resizable-header" data-toggle="tooltip" title="Actions" key="actions" css={styles.tableHeader}>
              <div css={styles.headerContent}>
                <div css={styles.headerText}>Actions</div>
              </div>
            </th>
          )}
          {headerGroup.headers.map((column, index) => {
            if (!visibleColumns.find((el) => el.id === column.id)?.value) {
              return null;
            }
            return (
              <ColumnHeader
                key={column.id}
                column={column}
                index={index}
                columnsOptions={columnsOptions}
                filterNamesReachable={filterNamesReachable}
                isSomeColumnFilterable={isSomeColumnFilterable}
                expandedColumnIds={expandedColumnIds}
                handleExpandColumn={handleExpandColumn}
                expandedColumnWidth={expandedColumnWidth}
                setExpandedColumnWidth={setExpandedColumnWidth}
                fixedColsPositions={fixedColsPositions}
              />
            );
          })}
        </StyledTableRow>
      ))}
    </StyledTableHead>
  );
};

export { TableHeader };
