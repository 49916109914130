/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import { StyledTableRow, styles as futureTableStyles } from '../../FutureTable.styles';
import { StyledTableBody, styles } from './TableBody.styles';
import { useTableBodyController } from './TableBody.controller';

const TableBody = ({
  getTableBodyProps,
  columnsOptions,
  rowIdKey,
  rows,
  prepareRow,
  selectedRowIds,
  onRowClick,
  visibleColumns,
  expandedColumnIds,
  expandedColumnWidth,
  setExpandedColumnWidth,
  fixedColsPositions,
  actionsColumn,
  handleDelete,
}) => {
  const { renderRowStyles, handleClick, handleTdWidthLogic } = useTableBodyController({
    columnsOptions,
    selectedRowIds,
    rowIdKey,
    onRowClick,
    expandedColumnIds,
    expandedColumnWidth,
    setExpandedColumnWidth,
  });

  const content = rows.map((row, index) => {
    prepareRow(row);
    const striped = columnsOptions.isStriped && index % 2 === 0;

    return (
      <StyledTableRow
        {...row.getRowProps()}
        style={renderRowStyles(row)}
        className={classnames('table-tr', { ' -odd': striped })}
        key={row.id}
        css={futureTableStyles.tableRow(
          columnsOptions.highlightSelection && (columnsOptions.autoSelection || selectedRowIds.length < columnsOptions.selectionLimit)
        )}
      >
        {actionsColumn && (
          <Fragment key={uuidv4()}>
            <td className="table-td" data-toggle="tooltip" title="Delete" aria-hidden="true" css={styles.td()}>
              <i className="fas fa-trash" onClick={() => handleDelete(row.original)} />
            </td>
          </Fragment>
        )}
        {row.cells.map((cell) => {
          if (!visibleColumns.find((el) => el.id === cell.column.id)?.value) {
            return null;
          }
          handleTdWidthLogic(cell);
          return (
            <Fragment key={uuidv4()}>
              <td
                {...cell.getCellProps()}
                className="table-td"
                data-toggle="tooltip"
                title={cell.value}
                aria-hidden="true"
                onClick={() => handleClick(cell)}
                css={styles.td(
                  expandedColumnIds.includes(cell.column.id),
                  expandedColumnWidth[cell.column.id],
                  cell.column.fixed,
                  fixedColsPositions[cell.column.id]
                )}
              >
                {cell.render('Cell')}
              </td>
            </Fragment>
          );
        })}
      </StyledTableRow>
    );
  });

  return (
    <StyledTableBody {...getTableBodyProps()} className="table-tbody" css={styles.tableBody(columnsOptions.isDisabled)}>
      {content}
    </StyledTableBody>
  );
};

export { TableBody };
