import { useContext } from 'react';
import { NetworkMonitoringContext } from 'context';

export const useWorksHistoryController = () => {
  const { setCellSelected, setParameterSelected } = useContext(NetworkMonitoringContext);

  const handleClickWorkItems = (cell) => {
    setCellSelected({ table: 'table6', cellName: cell.cell });
  };

  const handleClickPieChart = (cell) => {
    setParameterSelected({ table: 'table6', parameterName: cell.name });
  };

  const handleClickPieChartLegend = (label) => {
    setParameterSelected({ table: 'table6', parameterName: label.value });
  };

  const onFilterChange = (e, id) => {
    if (id === 'cell') {
      setCellSelected({ table: 'table6', cellName: e.target.value });
    } else if (id === 'parametername') {
      setParameterSelected({ table: 'table6', parameterName: e.target.value });
    }
  };

  return {
    handleClickWorkItems,
    handleClickPieChart,
    handleClickPieChartLegend,
    onFilterChange,
  };
};
