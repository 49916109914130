import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as actions from 'actions';
import { showErrorAlert } from 'utils';
import { GlobalContext } from 'context';

export const useLoginController = () => {
  const [user, setUser] = useState(null);
  const [pass, setPass] = useState(null);
  const { setUsername, setUserAdmin } = useContext(GlobalContext);

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('jwtToken')) {
      history.push('/supervisor');
    }
  }, [history]);

  const login = async () => {
    actions
      .login({ name: user, password: pass })
      .then((res) => {
        localStorage.setItem('jwtToken', res.data.token);
        setUsername(res.data.info.name);
        setUser({ user: res.data.info.name, email: res.data.info.name, user_id: 0 });
        setUserAdmin(res.data.info.admin);
        history.push('/supervisor');
      })
      .catch((err) => {
        showErrorAlert({ title: 'Login error', text: err });
      });
  };

  const onChangeUser = (e) => {
    setUser(e.target.value);
  };

  const onChangePass = (e) => {
    setPass(e.target.value);
  };

  return {
    user,
    pass,
    login,
    onChangeUser,
    onChangePass,
  };
};
