/** @jsxImportSource @emotion/react */
import { Spinner } from 'components/Spinner';
import { Card as CardReactStrap, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import { styles } from './Card.styles';
import { useContext } from 'react';
import { GlobalContext } from 'context';

export const Card = ({
  children,
  title,
  headerContent,
  footerContent,
  restricted = false,
  isLoading = false,
  height,
  width,
  bodyHeight = '46.5em',
  preRender = false,
  ...props
}) => {
  const { isUserAdmin } = useContext(GlobalContext);
  const childrenRender = () => {
    if (preRender || !isLoading) {
      return children;
    }
  };

  if (restricted && !isUserAdmin) return null;

  return (
    <CardReactStrap css={styles.card(height, width)}>
      {(title || headerContent) && (
        <CardHeader css={styles.cardHeader}>
          <CardTitle tag="h4" css={styles.cardTitle}>
            {title}
          </CardTitle>
          {headerContent}
        </CardHeader>
      )}
      <CardBody className={props.className} css={styles.cardBody(bodyHeight)}>
        {isLoading ? <Spinner overlay={preRender} /> : null}
        {childrenRender()}
      </CardBody>
      {footerContent && <CardFooter css={styles.cardFooter}>{footerContent}</CardFooter>}
    </CardReactStrap>
  );
};
