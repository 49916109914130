/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { styles } from './Popup.styles';
import { getTechnologyNameFromId, getTechnologyIdFromName } from 'utils';

function Popup({ feature, handleClickCellName, techSelected, setTechnology, handleUpdateMapTechSelection }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleClick = () => {
    let technologySelectedName = getTechnologyNameFromId(techSelected);
    if (technologySelectedName === feature.properties['type']) {
      handleClickCellName();
    } else {
      setShowConfirmationModal(true);
    }
  };

  const handleConfirmChangeTechnology = () => {
    const technologyId = getTechnologyIdFromName(feature.properties['type']);
    setTechnology(technologyId);
    handleClickCellName();
    setShowConfirmationModal(false);
    handleUpdateMapTechSelection(technologyId);
  };

  const handleCancelChangeTechnology = () => {
    setShowConfirmationModal(false);
  };

  return (
    <div className="content-popup">
      <Row>
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">{feature.geometry.type === 'LineString' ? 'Neighbors Details' : 'Cell Details'}</CardTitle>
            </CardHeader>
            <CardBody css={{ maxHeight: '240px' }}>
              {feature.geometry.type === 'LineString' ? (
                <>
                  <p>Serving Cell: {feature.properties['_servingcell']}</p>
                  <p>Target Cell: {feature.properties['_targetcell']}</p>
                  <p>Target Cell Type: {feature.properties['targetcelltype']}</p>
                </>
              ) : (
                <>
                  <p onClick={handleClick} className="popup-cell-name" css={{ cursor: 'pointer' }}>
                    Name: {feature.properties['cell_name']}
                  </p>
                  <p>Type: {feature.properties['type']}</p>
                  <p>Vendor: {feature.properties['vendor']}</p>
                  <p>State: {feature.properties['state']}</p>
                  <p>
                    Latitude,Longitude: {feature.properties['latitude']},{feature.properties['longitude']}
                  </p>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={showConfirmationModal} toggle={() => setShowConfirmationModal(false)} css={styles.modal}>
        <ModalBody>Are you sure you want to switch the selected global technology to {feature.properties['type']}?</ModalBody>
        <ModalFooter>
          <Button color="future" css={styles.modalButton} onClick={handleConfirmChangeTechnology}>
            Yes
          </Button>
          <Button color="danger" onClick={handleCancelChangeTechnology}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Popup;
