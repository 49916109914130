import { useCodirectedNeighboursController } from './CodirectedNeighbours.controller';
import { FutureTable } from 'components/Table/FutureTable';
import { Col } from 'reactstrap';
import Card from 'components/Card';

export const CodirectedNeighbours = () => {
  const {
    pageDataTable1,
    pageCountTable1,
    fetchCodirectedNeighborsDataTable1,
    pageDataTable2,
    pageCountTable2,
    fetchCodirectedNeighborsDataTable2,
    dynamicColumns,
    dynamicColsLoading,
    table1Config,
    table2Config,
  } = useCodirectedNeighboursController();

  return (
    <Col xs={12} md={12}>
      <Card title={table1Config.table_title} isLoading={dynamicColsLoading} className={pageDataTable1.data?.length === 0 ? 'card-7' : ''}>
        <FutureTable
          tableKey={table1Config.table_id}
          data={pageDataTable1.data || []}
          defaultColumns={dynamicColumns[table1Config.table_id]}
          columnsConfig={{
            isStriped: false,
            dynamicColumns: false,
            expandColumns: true,
          }}
          toolbarConfig={{
            showExport: true,
            showRowNumber: true,
          }}
          paginationEnabled={true}
          exportConfig={{
            excelName: table1Config.file_name,
            sheetName: table1Config.file_name,
            id: table1Config.table_id,
          }}
          pagData={pageDataTable1}
          fetchData={fetchCodirectedNeighborsDataTable1}
          controlledPageCount={pageCountTable1[table1Config.table_id]}
          table_id={table1Config.table_id}
        />
      </Card>
      <Card title={table2Config.table_title} isLoading={dynamicColsLoading} className={pageDataTable2.data?.length === 0 ? 'card-7' : ''}>
        <FutureTable
          tableKey={table2Config.table_id}
          data={pageDataTable2.data || []}
          defaultColumns={dynamicColumns[table2Config.table_id]}
          columnsConfig={{
            isStriped: false,
            dynamicColumns: false,
            expandColumns: true,
          }}
          toolbarConfig={{
            showExport: true,
            showRowNumber: true,
          }}
          paginationEnabled={true}
          exportConfig={{
            excelName: table2Config.file_name,
            sheetName: table2Config.file_name,
            id: table2Config.table_id,
          }}
          pagData={pageDataTable2}
          fetchData={fetchCodirectedNeighborsDataTable2}
          controlledPageCount={pageCountTable2[table2Config.table_id]}
          table_id={table2Config.table_id}
        />
      </Card>
    </Col>
  );
};
