import { useContext, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import Card from 'components/Card';
import { FutureTable } from 'components/Table/FutureTable';
import { BarChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, PieChart, Pie, Cell } from 'recharts';
import { NetworkMonitoringContext } from 'context';
import { useWorksHistoryController } from './WorksHistory.controller';
import CustomTooltip from '../CustomTooltip';
import { GlobalContext } from 'context';
import { styles } from './WorksHistory.styles';

const WorksHistory = ({
  fetchTable5Data,
  fetchTable6Data,
  fetchDateParams,
  table5Config,
  table6Config,
  graphLoading,
  pieChartOuterRadius,
  renderCustomizedLabel,
}) => {
  const { table5, table6, dynamicColumns, controlledPageCount, cellSelected, parameterSelected, chartsData, filterResetKey } =
    useContext(NetworkMonitoringContext);
  const { handleClickWorkItems, handleClickPieChart, handleClickPieChartLegend, onFilterChange } = useWorksHistoryController();
  const { tablesConfig } = useContext(GlobalContext);

  const filterMap = useMemo(
    () => [
      { id: 'cell', value: cellSelected['table6'] },
      { id: 'parametername', value: parameterSelected['table6'] },
    ],
    [cellSelected['table6'], parameterSelected['table6']]
  );

  return (
    <Col xs={12} md={12}>
      <Row>
        <Col xs={4} md={4}>
          <Card title={table5Config.table_title} height="98%" bodyHeight={window.innerWidth <= 1920 ? '80%' : '46.5em'}>
            {dynamicColumns['table5']?.length > 0 && (
              <FutureTable
                key={filterResetKey}
                tableKey={table5Config.table_id}
                data={table5.data}
                defaultColumns={dynamicColumns['table5']}
                onRowClick={handleClickWorkItems}
                columnsConfig={{
                  isStriped: false,
                  isVirtualized: true,
                  dynamicColumns: false,
                }}
                toolbarConfig={{
                  showExport: true,
                  showRowNumber: true,
                }}
                paginationEnabled={true}
                exportConfig={{
                  excelName: table5Config.file_name,
                  sheetName: table5Config.file_name,
                  id: table5Config.table_id,
                  dateRangeNeeded: true,
                }}
                pagData={table5}
                fetchData={fetchTable5Data}
                controlledPageCount={controlledPageCount['table5']}
                fetchParams={fetchDateParams}
                tableIdRequired={true}
                table_id={table5Config.table_id}
              />
            )}
          </Card>
        </Col>
        <Col xs={4} md={4}>
          <Card title={tablesConfig[20]?.table_title} height="98%" isLoading={graphLoading} bodyHeight={window.innerWidth <= 1920 ? '80%' : '46.5em'}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={chartsData['barWorks']}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="total" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
        <Col xs={4} md={4}>
          <Card title={tablesConfig[21]?.table_title} height="98%" isLoading={graphLoading} bodyHeight={window.innerWidth <= 1920 ? '80%' : '46.5em'}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={800} height={500}>
                <Tooltip content={<CustomTooltip />} />
                <Pie
                  dataKey="value"
                  data={chartsData['pieWorks']['data']}
                  cx={'50%'}
                  cy={'50%'}
                  outerRadius={pieChartOuterRadius}
                  label={renderCustomizedLabel}
                  labelLine={false}
                  minAngle={10}
                  onClick={(cell) => handleClickPieChart(cell)}
                >
                  {chartsData['pieWorks']['colors'].map((entry, index) => {
                    return <Cell key={`cellWorks-${index}`} fill={entry} style={styles.hoverable} />;
                  })}
                </Pie>
                <Legend onClick={(label) => handleClickPieChartLegend(label)} wrapperStyle={styles.hoverable} />
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Card title={table6Config.table_title}>
            {dynamicColumns['table6']?.length > 0 && (
              <FutureTable
                key={filterResetKey}
                tableKey={table6Config.table_id}
                data={table6.data}
                filterMap={filterMap}
                onFilterChange={onFilterChange}
                columnsConfig={{
                  isStriped: false,
                  isVirtualized: true,
                  dynamicColumns: false,
                  expandColumns: true,
                }}
                defaultColumns={dynamicColumns['table6']}
                toolbarConfig={{
                  showExport: true,
                  showRowNumber: true,
                }}
                paginationEnabled={true}
                exportConfig={{
                  excelName: table6Config.file_name,
                  sheetName: table6Config.file_name,
                  id: table6Config.table_id,
                  dateRangeNeeded: true,
                }}
                pagData={table6}
                fetchData={fetchTable6Data}
                controlledPageCount={controlledPageCount['table6']}
                fetchParams={fetchDateParams}
                tableIdRequired={true}
                table_id={table6Config.table_id}
              />
            )}
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default WorksHistory;
