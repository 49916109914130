import React from 'react';

import SimpleTable from 'components/Table/SimpleTable/SimpleTable';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';

function MapTable({ header, columns, title }) {
  const [showTable, setShowTable] = React.useState(false);

  return (
    <div id="text">
      <Row>
        <Col xs={12}>
          <Card id="tableCard">
            <CardHeader id="tableHeader">
              <CardTitle id="tableTitle" tag="h4">
                {title}
              </CardTitle>
              <button id="minButton" onClick={(e) => setShowTable(!showTable)}>
                <i className={showTable ? 'fa fa-arrow-down' : 'fa fa-arrow-up'}></i>
              </button>
            </CardHeader>
            {showTable && (
              <CardBody>
                <SimpleTable columns={header} data={columns} />
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default MapTable;
