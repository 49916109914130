import { useContext, useEffect } from 'react';
import * as actions from 'actions';
import { useFetchData } from 'hooks/useFetchData';
import { useFetchDynamicCols } from 'hooks/useFetchDynamicCols';
import { GlobalContext } from 'context';

export const useTrafficSteeringController = () => {
  const { pageData, pageCount: controlledPageCount, fetchData, setTableKey } = useFetchData(actions.trafficSteering);
  const { tablesConfig } = useContext(GlobalContext);

  const tableConfig = tablesConfig[74] || {};

  const { dynamicCols, dynamicColsLoading } = useFetchDynamicCols([tableConfig?.table_id]);

  useEffect(() => {
    setTableKey(tableConfig.table_id);
  }, [setTableKey, tableConfig.table_id]);

  return {
    pageData,
    controlledPageCount,
    fetchData,
    defaultColumns: dynamicCols,
    dynamicColsLoading,
    tableConfig,
  };
};
