import { INITIAL_MAIN_PANEL_OVERFLOW_VALUE } from 'utils';
import { css } from '@emotion/react';

const typographyStyles = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }

  a {
    color: #9796c1;
    &:hover,
    &:focus {
      color: #f80f519d;
    }
  }
  h1,
  .h1 {
    font-size: 3.5em;
    line-height: 1.15;
    margin-bottom: 15px * 2;

    small {
      font-weight: 700;
      text-transform: uppercase;
      opacity: 0.8;
    }
  }
  h2,
  .h2 {
    font-size: 2.5em;
    margin-bottom: 15px * 2;
  }
  h3,
  .h3 {
    font-size: 2em;
    margin-bottom: 15px * 2;
    line-height: 1.4em;
  }
  h4,
  .h4 {
    font-size: 1.714em;
    line-height: 1.45em;
    margin-top: 15px * 2;
    margin-bottom: 15px;

    & + .category,
    &.title + .category {
      margin-top: -10px;
    }
  }
  h5,
  .h5 {
    font-size: 1.57em;
    line-height: 1.4em;
    margin-bottom: 15px;
  }
  h6,
  .h6 {
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
  }
  p {
    &.description {
      font-size: 1.14em;
    }
  }

  .title {
    font-weight: 700;

    &.title-up {
      text-transform: uppercase;

      a {
        color: #2c2c2c;
        text-decoration: none;
      }
    }
    & + .category {
      margin-top: -10px;
    }
  }

  .description,
  .card-description,
  .footer-big p,
  .card .footer .stats {
    color: #9a9a9a;
    font-weight: 300;
  }
  .category,
  .card-category {
    text-transform: capitalize;
    font-weight: 400;
    color: #9a9a9a;
    font-size: 0.7142em;
  }

  .card-category {
    font-size: 1em;
  }

  .text-primary,
  a.text-primary:focus,
  a.text-primary:hover {
    color: #f96332 !important;
  }
  .text-info,
  a.text-info:focus,
  a.text-info:hover {
    color: #2ca8ff !important;
  }
  .text-success,
  a.text-success:focus,
  a.text-success:hover {
    color: #18ce0f !important;
  }
  .text-warning,
  a.text-warning:focus,
  a.text-warning:hover {
    color: #ffb236 !important;
  }
  .text-danger,
  a.text-danger:focus,
  a.text-danger:hover {
    color: #ff3636 !important;
  }

  .text-gray,
  a.text-gray:focus,
  a.text-gray:hover {
    color: #e3e3e3 !important;
  }

  .blockquote {
    border-left: none;
    border: 1px solid #888;
    padding: 20px;
    font-size: 1.1em;
    line-height: 1.8;

    small {
      color: #888;
      font-size: 0.8571em;
      text-transform: uppercase;
    }

    &.blockquote-primary {
      border-color: #f96332;
      color: #f96332;

      small {
        color: #f96332;
      }
    }

    &.blockquote-danger {
      border-color: #ff3636;
      color: #ff3636;

      small {
        color: #ff3636;
      }
    }

    &.blockquote-white {
      border-color: rgba(255, 255, 255, 0.8);
      color: #ffffff;

      small {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
`;

const globalStyles = css`
    html {
    overflow: hidden;
    }
    
    body {
        color: #2c2c2c;
        font-size: 14px;
        font-family:  "Montserrat", "Helvetica Neue", Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    .main-panel {
    height: 100vh;
    overflow: ${INITIAL_MAIN_PANEL_OVERFLOW_VALUE};
    }

    
    *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    }
    *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #878787;
    }
    *::-webkit-scrollbar-track {
    display: none:
    border-radius: 10px;
    background-color: #fafafa;
    }
    ${typographyStyles}
    `;

export { globalStyles };
