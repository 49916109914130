import { useContext, useEffect } from 'react';
import * as actions from 'actions';
import { GlobalContext } from 'context';
import { globalStyles } from './App.styles';

export const useAppController = () => {
  const { setUsername, setUserAdmin, setTablesConfig, username, setUserTimeZone, setTechnologies } = useContext(GlobalContext);

  useEffect(() => {
    const fetchTablesConfig = async () => {
      const { data } = await actions.tablesConfiguration();
      setTablesConfig(data);
    };
    const fetchTechnologies = async () => {
      const response = await actions.getTechnologies();
      setTechnologies(response);
    };
    if (username) {
      fetchTechnologies();
      fetchTablesConfig();
    }
  }, [setTablesConfig, username, setTechnologies]);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      actions.checkToken({ token: token }).then((response) => {
        setUsername(response.name);
        setUserAdmin(response.admin);
      });
    }
  }, [setUsername, setUserAdmin]);

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimeZone(userTimeZone);
  }, [setUserTimeZone]);

  return {
    globalStyles,
  };
};
