import styled from '@emotion/styled';
import { FormGroup } from 'reactstrap';

export const StyledFormGroup = styled(FormGroup)``;

const formGroupStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.4rem',
};

const modalFooterStyles = {
  display: 'flex',
  gap: '1rem',
  paddingRight: '24px',
  paddingBottom: '16px',
  paddingLeft: '24px',
  justifyContent: 'space-between',
  bottom: 0,
  position: 'absolute',

  button: {
    margin: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    width: 'auto',
  },
};

const flexStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const styles = {
  formGroup: formGroupStyles,
  modalFooter: modalFooterStyles,
  flex: flexStyles,
};
