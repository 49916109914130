import { useContext } from 'react';
import { NetworkMonitoringContext } from 'context';

export const useNetworkChangesController = () => {
  const { setCellSelected, setParameterSelected } = useContext(NetworkMonitoringContext);

  const handleClickDailyChanges = (cell) => {
    setCellSelected({ table: 'table4', cellName: cell.cell_name });
  };

  const handleClickPieChart = (cell) => {
    setParameterSelected({ table: 'table4', parameterName: cell.name });
  };

  const handleClickPieChartLegend = (label) => {
    setParameterSelected({ table: 'table4', parameterName: label.value });
  };

  const onFilterChange = (e, id) => {
    if (id === 'cell_name') {
      setCellSelected({ table: 'table4', cellName: e.target.value });
    } else if (id === 'parameter') {
      setParameterSelected({ table: 'table4', parameterName: e.target.value });
    }
  };

  return {
    handleClickDailyChanges,
    handleClickPieChart,
    handleClickPieChartLegend,
    onFilterChange,
  };
};
