/** @jsxImportSource @emotion/react */
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Label, Input } from 'reactstrap';

const CHANGE_PASSWORD_ALERT_STYLES = {
  container: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& :last-child': {
      marginBottom: '0',
    },
  },
  label: {
    fontWeight: 700,
    marginBottom: 5,
  },
  input: {
    height: 37,
    margin: '0 0 30px',
    '@media(min-width: 1744px)': {
      width: '27rem',
    },
  },
};

const WARNING_DEFAULT_TEXT = 'Something requires your attention';

const SUCCESS_DEFAULT_TEXT = 'Action has been successfully';

const AnisSwal = withReactContent(Swal);

const commonOptions = {
  allowEscapeKey: false,
  allowOutsideClick: false,
  allowEnterKey: true,
  buttonsStyling: false,
  reverseButtons: true,
  customClass: {
    confirmButton: 'btn btn-future',
    cancelButton: 'btn btn-danger',
  },
  showClass: {
    popup: '',
  },
  hideClass: {
    popup: '',
  },
};

const showPrompt = ({
  icon = 'question',
  title,
  body,
  confirmButtonText = 'Yes, continue',
  cancelButtonText = 'No, cancel',
  callbackAsyncFn,
  onClose,
}) =>
  AnisSwal.fire({
    icon,
    title,
    html: body,
    confirmButtonText,
    cancelButtonText,
    showCancelButton: true,
    ...commonOptions,
    showLoaderOnConfirm: !!callbackAsyncFn,
    ...(callbackAsyncFn && { preConfirm: callbackAsyncFn }),
  }).then((result) => onClose && onClose({ isConfirmed: result.isConfirmed }));

const showWarningAlert = ({ title, body, onClose } = {}) =>
  AnisSwal.fire({
    ...commonOptions,
    icon: 'warning',
    title: title || 'Warning',
    html: body || WARNING_DEFAULT_TEXT,
  }).then((result) => onClose && onClose({ isConfirmed: result.isConfirmed }));

const showSuccessAlert = ({ title, body, onClose } = {}) =>
  AnisSwal.fire({
    ...commonOptions,
    icon: 'success',
    title: title || 'Success',
    html: body || SUCCESS_DEFAULT_TEXT,
  }).then((result) => onClose && onClose({ isConfirmed: result.isConfirmed }));

const showErrorAlert = ({ title, text } = {}) =>
  AnisSwal.fire({
    ...commonOptions,
    icon: 'error',
    title: title || 'Error',
    text: text || 'An unexpected error has occurred',
  });

const showToast = (title = SUCCESS_DEFAULT_TEXT) =>
  AnisSwal.fire({
    toast: true,
    position: 'top-right',
    showClass: {
      popup: '',
    },
    hideClass: {
      popup: '',
    },
    icon: 'success',
    title,
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

const showChangePasswordAlert = ({ callbackAsyncFn, isUserAdmin = false }) => {
  const handleChange = () => {
    const confirmButton = AnisSwal.getConfirmButton();
    const currentPasswordValue = document.getElementById('currentPassword').value;
    const newPasswordValue = document.getElementById('newPassword').value;
    const matchedPasswordValue = document.getElementById('matchedPassword').value;

    confirmButton.disabled = !currentPasswordValue || !newPasswordValue || !matchedPasswordValue || newPasswordValue !== matchedPasswordValue;
  };

  return AnisSwal.fire({
    ...commonOptions,
    icon: 'info',
    title: 'Change Password',
    allowOutsideClick: true,
    buttonsStyling: false,
    html: (
      <div css={CHANGE_PASSWORD_ALERT_STYLES.container}>
        <Label css={CHANGE_PASSWORD_ALERT_STYLES.label}>Current password</Label>
        <Input type="password" id="currentPassword" css={CHANGE_PASSWORD_ALERT_STYLES.input} />

        <Label css={CHANGE_PASSWORD_ALERT_STYLES.label}>New password</Label>
        <Input type="password" id="newPassword" css={CHANGE_PASSWORD_ALERT_STYLES.input} />

        <Label css={CHANGE_PASSWORD_ALERT_STYLES.label}>Repeat password</Label>
        <Input type="password" id="matchedPassword" css={CHANGE_PASSWORD_ALERT_STYLES.input} />
      </div>
    ),
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    showCancelButton: true,
    showLoaderOnConfirm: true,
    didOpen: () => {
      AnisSwal.getConfirmButton().disabled = true;
      document.getElementById('currentPassword').addEventListener('input', handleChange);
      document.getElementById('newPassword').addEventListener('input', handleChange);
      document.getElementById('matchedPassword').addEventListener('input', handleChange);
    },
    preConfirm: callbackAsyncFn,
  });
};

export { showPrompt, showWarningAlert, showSuccessAlert, showErrorAlert, showToast, showChangePasswordAlert };
