import { useContext, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import Card from 'components/Card';
import { FutureTable } from 'components/Table/FutureTable';
import { BarChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, PieChart, Pie, Cell } from 'recharts';
import { NetworkMonitoringContext } from 'context';
import { useNetworkChangesController } from './NetworkChanges.controller';
import CustomTooltip from '../CustomTooltip';
import { styles } from './NetworkChanges.styles';

const NetworkChanges = ({
  fetchTable3Data,
  fetchTable4Data,
  fetchDateParams,
  getTableConfig,
  table3Config,
  table4Config,
  graphLoading,
  pieChartOuterRadius,
  renderCustomizedLabel,
}) => {
  const { table3, table4, dynamicColumns, controlledPageCount, cellSelected, parameterSelected, chartsData, filterResetKey } =
    useContext(NetworkMonitoringContext);
  const { handleClickDailyChanges, handleClickPieChart, handleClickPieChartLegend, onFilterChange } = useNetworkChangesController();

  const filterMap = useMemo(
    () => [
      { id: 'cell_name', value: cellSelected['table4'] },
      { id: 'parameter', value: parameterSelected['table4'] },
    ],
    [cellSelected['table4'], parameterSelected['table4']]
  );

  return (
    <Col xs={12} md={12}>
      <Row>
        <Col xs={4} md={4}>
          <Card title={table3Config.table_title} height="98%" bodyHeight={window.innerWidth <= 1920 ? '80%' : '46.5em'}>
            {dynamicColumns['table3']?.length > 0 && (
              <FutureTable
                key={filterResetKey}
                tableKey={table3Config.table_id}
                data={table3.data}
                defaultColumns={dynamicColumns['table3']}
                onRowClick={handleClickDailyChanges}
                columnsConfig={{
                  isStriped: false,
                  isVirtualized: true,
                  dynamicColumns: false,
                }}
                toolbarConfig={{
                  showExport: true,
                  showRowNumber: true,
                }}
                paginationEnabled={true}
                exportConfig={{
                  excelName: table3Config.file_name,
                  sheetName: table3Config.file_name,
                  id: table3Config.table_id,
                  dateRangeNeeded: true,
                }}
                pagData={table3}
                fetchData={fetchTable3Data}
                controlledPageCount={controlledPageCount['table3']}
                fetchParams={fetchDateParams}
                tableIdRequired={true}
                table_id={table3Config.table_id}
              />
            )}
          </Card>
        </Col>
        <Col xs={4} md={4}>
          <Card
            title={getTableConfig(4)?.table_title}
            height="98%"
            isLoading={graphLoading}
            bodyHeight={window.innerWidth <= 1920 ? '80%' : '46.5em'}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={chartsData['barChanges']}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="total" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
        <Col xs={4} md={4}>
          <Card
            title={getTableConfig(5)?.table_title}
            height="98%"
            isLoading={graphLoading}
            bodyHeight={window.innerWidth <= 1920 ? '80%' : '46.5em'}
          >
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={800} height={500}>
                <Tooltip content={<CustomTooltip />} />
                <Pie
                  dataKey="value"
                  data={chartsData['pieChanges']['data']}
                  cx={'50%'}
                  cy={'50%'}
                  outerRadius={pieChartOuterRadius}
                  label={renderCustomizedLabel}
                  labelLine={false}
                  minAngle={10}
                  onClick={(cell) => handleClickPieChart(cell)}
                >
                  {chartsData['pieChanges']['colors'].map((entry, index) => {
                    return <Cell key={`cellChanges-${index}`} fill={entry} style={styles.hoverable} />;
                  })}
                </Pie>
                <Legend onClick={(label) => handleClickPieChartLegend(label)} wrapperStyle={styles.hoverable} />
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Card title={table4Config.table_title}>
            {dynamicColumns['table4']?.length > 0 && (
              <FutureTable
                key={filterResetKey}
                tableKey={table4Config.table_id}
                data={table4.data}
                filterMap={filterMap}
                onFilterChange={onFilterChange}
                columnsConfig={{
                  isStriped: false,
                  isVirtualized: true,
                  dynamicColumns: false,
                  expandColumns: true,
                }}
                defaultColumns={dynamicColumns['table4']}
                toolbarConfig={{
                  showExport: true,
                  showRowNumber: true,
                }}
                paginationEnabled={true}
                exportConfig={{
                  excelName: table4Config.file_name,
                  sheetName: table4Config.file_name,
                  id: table4Config.table_id,
                  dateRangeNeeded: true,
                }}
                pagData={table4}
                fetchData={fetchTable4Data}
                controlledPageCount={controlledPageCount['table4']}
                fetchParams={fetchDateParams}
                tableIdRequired={true}
                table_id={table4Config.table_id}
              />
            )}
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default NetworkChanges;
