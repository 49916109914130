export const useTableBodyController = ({
  columnsOptions,
  selectedRowIds,
  rowIdKey,
  onRowClick,
  expandedColumnIds,
  expandedColumnWidth,
  setExpandedColumnWidth,
}) => {
  const cursorAsPointer = columnsOptions.autoSelection ? !!onRowClick : !!onRowClick && selectedRowIds.length < columnsOptions.selectionLimit;

  const renderRowStyles = (row) => ({
    cursor: cursorAsPointer ? 'pointer' : 'auto',
    ...(columnsOptions.highlightSelection &&
      selectedRowIds.some((id) => id === row.original[rowIdKey]) && {
        backgroundColor: 'rgba(0, 0, 0, 0.075)',
      }),
  });

  const handleClick = (selected) => {
    if (!columnsOptions.autoSelection && selectedRowIds.length === columnsOptions.selectionLimit) {
      return;
    }
    if (onRowClick) onRowClick(selected.row.original);
  };

  const getTextWidth = (text) => {
    let textLength = 0;
    if (text) textLength = text.length;
    return textLength * 11;
  };

  const handleTdWidthLogic = (cell) => {
    if (expandedColumnIds.includes(cell.column.id)) {
      const textWidth = getTextWidth(cell.value);
      const columnNameWidth = getTextWidth(cell.column.Header) + 30;
      const width = Math.max(textWidth, columnNameWidth);
      const columnWidthToUpdate = Math.max(width, 200);
      if (!expandedColumnWidth[cell.column.id] || width > expandedColumnWidth[cell.column.id]) {
        setExpandedColumnWidth((prevWidths) => ({
          ...prevWidths,
          [cell.column.id]: columnWidthToUpdate,
        }));
      }
    }
  };

  return { renderRowStyles, handleClick, handleTdWidthLogic };
};
