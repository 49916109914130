import styled from '@emotion/styled';
import { CardBody, Input } from 'reactstrap';

export const StyledCardBody = styled(CardBody)``;

export const StyledInput = styled(Input)``;

const imgStyles = {
  margin: 'auto',
};

const imageColStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const inputStyles = {
  width: '20rem',
};

const footerStyles = {
  margin: '1rem 2rem 0 0',
};

const imageStyles = {
  maxWidth: '75%',
  borderRadius: '0.1875rem',
};

const contentStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  // background: 'rgb(248,15,82)',
  background: 'linear-gradient(90deg, rgba(248,15,82,1) 0%, rgba(151,150,193,1) 89%)',
};

export const styles = {
  img: imgStyles,
  imageCol: imageColStyles,
  input: inputStyles,
  footer: footerStyles,
  image: imageStyles,
  content: contentStyles,
};
