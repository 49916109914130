import { NetworkMonitoringContext } from 'context';
import { useContext } from 'react';

export const useFilterController = () => {
  const {
    activeTab,
    technology,
    setTechnology,
    setAllCellSelected,
    setCellSelected,
    setParameterSelected,
    setMapTechSelection,
    mapTechSelection,
    setFilterResetKey,
  } = useContext(NetworkMonitoringContext);
  const isActiveButton = (button) => {
    if (activeTab === 'WORK_ITEMS') {
      return button.tech_id === null;
    }
    return technology === button.tech_id;
  };

  const isDisabledButton = (button) => {
    if (activeTab === 'WORK_ITEMS') {
      return button.tech_id === 1 || button.tech_id === 2 || button.tech_id === 3;
    } else if (activeTab === 'CELL_CONFIG' || activeTab === 'NETWORK_CHANGES') {
      return button.tech_id === null;
    }
    return false;
  };

  const handleTechnologyButton = (button) => {
    setTechnology(button.tech_id);
    setAllCellSelected({ cellName: '' });
    setCellSelected({ table: 'table1', cellName: '' });
    setParameterSelected({ table: 'table4', parameterName: '' });
    setFilterResetKey();

    const mapTechSelectedUpdated = Object.keys(mapTechSelection).map((tech) => {
      if (tech !== button.tech_id.toString()) {
        return { [tech]: false };
      }
      return { [tech]: true };
    });
    setMapTechSelection(Object.assign({}, ...mapTechSelectedUpdated));
  };

  return { isActiveButton, isDisabledButton, handleTechnologyButton };
};
