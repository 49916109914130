import { Route } from 'react-router-dom';
import CSONModules from './views/CSONModules';
import { useContext, useMemo } from 'react';
import UserDashboard from 'views/UserDashboard';
import { GlobalContext } from 'context';
import NetworkMonitoring from 'views/NetworkMonitoring';

export const routes = [
  {
    id: 'network_monitoring',
    path: '/supervisor',
    name: 'Network Monitoring',
    icon: {
      prefix: 'fas',
      name: 'network-wired',
    },
    component: NetworkMonitoring,
    priority: 10,
  },
  {
    id: 'cson_modules',
    path: '/csonmodules',
    name: 'CSON modules',
    icon: {
      prefix: 'fas',
      name: 'clipboard-list',
    },
    component: CSONModules,
    priority: 10,
  },
  {
    id: 'user_dashboard',
    path: '/user/dashboard',
    name: 'User Dashboard',
    icon: {
      prefix: 'fas',
      name: 'users-cog',
    },
    component: UserDashboard,
    priority: 10,
    atBottom: true,
  },
];

export const useRoutePaths = () => {
  const { isUserAdmin } = useContext(GlobalContext);
  const userPriority = isUserAdmin ? 100 : 10;

  const appRoutesFilteredByPriority = useMemo(
    () =>
      routes.filter((route) => {
        return userPriority >= route.priority;
      }),
    [userPriority]
  );

  const memoizedRoutes = useMemo(() => {
    return appRoutesFilteredByPriority.map((route) => {
      return (
        <Route path={route.path} key={route.id}>
          <route.component />
        </Route>
      );
    });
  }, [appRoutesFilteredByPriority]);

  return { appRoutes: memoizedRoutes };
};
