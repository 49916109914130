import { createContext, useReducer, useCallback, useMemo } from 'react';
import { CSONModulesReducer } from './CSONModulesReducer';

export const CSONModulesContext = createContext({});

const CONTEXT_INITIAL_STATE = {
  data: {},
  selectedTables: [],
};

export const CSONModulesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CSONModulesReducer, CONTEXT_INITIAL_STATE);

  const setData = useCallback(({ table, data }) => {
    dispatch({ type: 'SET_DATA', payload: { table, data } });
  }, []);

  const setSelectedTables = useCallback((selectedTables) => {
    dispatch({ type: 'SET_SELECTED_TABLES', payload: selectedTables });
  }, []);

  const cleanSelectedTables = useCallback(() => {
    dispatch({ type: 'CLEAN_SELECTED_TABLES' });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setData,
      setSelectedTables,
      cleanSelectedTables,
    }),
    [state, setData, setSelectedTables, cleanSelectedTables]
  );

  return <CSONModulesContext.Provider value={contextValue}>{children}</CSONModulesContext.Provider>;
};
