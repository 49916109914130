import { apiGet, apiPost, apiDelete, apiPostFile, apiGetFile, apiPostFileUpload } from '../api';
import * as urls from '../api/urls';

export const topology = async (params) => apiGet(urls.topology, { ...params })();
export const neighborsMap = async (params) => apiGet(urls.neighborsMap, { ...params })();
export const dailyChanges = async (params) => apiGet(urls.dailyChanges, { ...params })();
export const dailyChangesCell = async (params) => apiGet(urls.dailyChangesCell, { ...params })();
export const dailyChangesDay = async (params) => apiGet(urls.dailyChangesDay, { ...params })();
export const dailyChangesParameters = async (params) => apiGet(urls.dailyChangesParameters, { ...params })();
export const worksOrdersCell = async (params) => apiGet(urls.worksOrdersCell, { ...params })();
export const worksOrdersDay = async (params) => apiGet(urls.worksOrdersDay, { ...params })();
export const worksOrdersParameters = async (params) => apiGet(urls.worksOrdersParameters, { ...params })();
export const cell_polluters = async (params) => apiGet(urls.cell_polluters, { ...params })();
export const crowdsource = async (params) => apiGet(urls.crowdsource, { ...params })();
export const prop_delay = async (params) => apiGet(urls.prop_delay, { ...params })();
export const tiers = async (params) => apiGet(urls.tiers, { ...params })();
export const cells = async (params, signal) => apiGet(urls.cell, params, signal)();
export const cellColumns = async (params) => apiGet(urls.cellColumns, { ...params })();
export const getCellsConfiguration = async (params) => apiGet(urls.columnsConfiguration, { ...params })();
export const postCellsConfiguration = async (body, params) => apiPost(urls.columnsConfiguration, body, params)();
export const excludeDays = async (params) => apiGet(urls.excludeDays, { ...params })();
export const dlearfcn = async (params) => apiGet(urls.dlearfcn, { ...params })();
export const worksOrders = async (params) => apiGet(urls.worksOrders, { ...params })();
export const cellsHistory = async (params, signal) => apiGet(urls.cellHistory, { ...params }, signal)();
export const neighbors = async (params) => apiGet(urls.neighbors, { ...params })();
export const neighborsHistory = async (params) => apiGet(urls.neighborsHistory, { ...params })();
export const login = async (params) => apiPost(urls.login, params)();
export const ping = async (params) => apiGet(urls.ping, params)();
export const registerUser = async (params) => apiPost(urls.registerUser, params)();
export const getUsers = async (params) => apiGet(urls.getUsers, params)();
export const checkToken = async (params) => apiGet(urls.checkToken, params)();
export const changePassword = async (params) => apiPost(urls.changePassword, params)();
export const adminChangeUserPassword = async (body, params) => apiPost(urls.adminChangeUserPassword, body, params)();
export const deleteUser = async (params) => apiDelete(urls.deleteUser, params)();
export const resetColumnVisible = async (params) => apiPost(urls.resetColumnVisible, params)();
export const codirectedNeighbors2G = async (params) => apiGet(urls.codirectedNeighbors2G, { ...params })();
export const codirectedNeighbors5G = async (params) => apiGet(urls.codirectedNeighbors5G, { ...params })();
export const completeRangeOptimization = async (params) => apiGet(urls.completeRangeOptimization, { ...params })();
export const rangeOptimizationWorkitems = async (params) => apiGet(urls.rangeOptimizationWorkitems, { ...params })();
export const missingNeighbors = async (params) => apiGet(urls.missingNeighbors, { ...params })();
export const missingNeighboursTables = async (params) => apiGet(urls.missingNeighboursTables, { ...params })();
export const dlrsAdvanced = async (params) => apiGet(urls.dlrsAdvanced, { ...params })();
export const dlrsAdvancedDiscarded = async (params) => apiGet(urls.dlrsAdvancedDiscarded, { ...params })();
export const coordinatesOvershooters = async (params) => apiGet(urls.coordinatesOvershooters, { ...params })();
export const trafficSteering = async (params) => apiGet(urls.trafficSteering, { ...params })();
export const downloadExcel = async (body, params) => apiPostFile(urls.downloadExcel, body, params)();
export const downloadCSONInfo = async (params) => apiPostFile(urls.downloadCSONInfo, { ...params })();
export const excelsQueue = async (params) => apiGet(urls.excelsQueue, { ...params })();
export const deleteExcelFromQueue = async (params) => apiDelete(urls.deleteExcelFromQueue, { ...params })();
export const tablesConfiguration = async (params) => apiGet(urls.tablesConfiguration, { ...params })();
export const getTechnologies = async (params) => apiGet(urls.technologies, { ...params })();
export const exportColumns = async (params) => apiGetFile(urls.exportColumns, { ...params })();
export const importColumns = async (body, params) => apiPostFileUpload(urls.importColumns, body, params)();
