import { useState, useEffect, useContext, useCallback } from 'react';
import * as actions from 'actions';
import { showToast, showErrorAlert } from 'utils';
import { showPrompt } from 'utils';
import { GlobalContext } from 'context';
import { useFetchDynamicCols } from 'hooks/useFetchDynamicCols';
import { parseDateUTC } from 'utils';
import { formatDateToLocal } from 'utils';

export const useUserDashboardController = () => {
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [excelsQueue, setExcelsQueue] = useState([]);

  const { isUserAdmin, tablesConfig, userTimeZone } = useContext(GlobalContext);

  const table1Config = tablesConfig[75] || {};
  const table2Config = tablesConfig[76] || {};

  const { dynamicCols: table1DynamicCols, dynamicColsLoading: table1DynamicColsLoading } = useFetchDynamicCols([table1Config?.table_id]);
  const { dynamicCols: table2DynamicCols, dynamicColsLoading: table2DynamicColsLoading } = useFetchDynamicCols([table2Config?.table_id]);

  const USER = {
    name: '',
    password: '',
    confirm_password: '',
  };

  const [newUserState, setNewUserState] = useState(USER);

  const handleClickUserRow = (user) => {
    setSelectedUser(user);
    setNewUserState({
      password: '',
      confirm_password: '',
    });
  };

  const handleSubmit = () => {
    if (!selectedUser) {
      actions
        .registerUser(newUserState)
        .then((response) => {
          showToast(response.data.message);
          setNewUserState(USER);
          getAllUsers();
        })
        .catch((error) => {
          showErrorAlert({ title: 'Error', text: error.message });
        });
    } else {
      actions
        .adminChangeUserPassword(newUserState, selectedUser)
        .then((response) => {
          showToast(response.message);
          setNewUserState(USER);
          setSelectedUser(null);
          getAllUsers();
        })
        .catch((error) => {
          showErrorAlert({ title: 'Error', text: error.message });
        });
    }
  };

  const handleDeleteUser = () => {
    showPrompt({
      title: 'Delete User',
      body: 'Are you sure you want to delete this user?',
      callbackAsyncFn: () => deleteUser(),
    });
  };

  const deleteUser = () => {
    actions
      .deleteUser(selectedUser)
      .then((response) => {
        showToast(response.message);
        setSelectedUser(null);
        setNewUserState(USER);
        getAllUsers();
      })
      .catch((error) => {
        showErrorAlert({ title: 'Error', text: error.message });
      });
  };

  const handleDeleteExcel = (excel) => {
    showPrompt({
      title: 'Delete Excel',
      body: 'Are you sure you want to delete this excel?',
      callbackAsyncFn: () => deleteExcelFromQueue(excel),
    });
  };

  const deleteExcelFromQueue = (excel) => {
    actions
      .deleteExcelFromQueue({ id: excel._id })
      .then((response) => {
        showToast(response.message);
        getExcelsQueue();
      })
      .catch((error) => {
        showErrorAlert({ title: 'Error', text: error });
      });
  };

  const handleCancel = () => {
    setSelectedUser(null);
    setNewUserState(USER);
  };

  const getAllUsers = useCallback(() => {
    actions
      .getUsers()
      .then((response) => {
        setUserList(response);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const getExcelsQueue = useCallback(() => {
    actions
      .excelsQueue()
      .then((response) => {
        let formattedResponse = [];
        if (response.length > 0) {
          formattedResponse = response.map((item) => {
            const parsedDateUTC = parseDateUTC(item.start_at);
            const formattedDateLocal = formatDateToLocal(parsedDateUTC, userTimeZone, 'es-ES');
            return { ...item, start_at: formattedDateLocal };
          });
        }
        setExcelsQueue(formattedResponse);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [userTimeZone]);

  useEffect(() => {
    getAllUsers();
    getExcelsQueue();
  }, [getExcelsQueue, getAllUsers]);

  return {
    userList,
    selectedUser,
    newUserState,
    setNewUserState,
    excelsQueue,
    setExcelsQueue,
    isUserAdmin,
    handleClickUserRow,
    handleSubmit,
    handleDeleteUser,
    handleDeleteExcel,
    handleCancel,
    table1DynamicCols,
    table1DynamicColsLoading,
    table2DynamicCols,
    table2DynamicColsLoading,
    table1Config,
    table2Config,
  };
};
