import { createContext, useReducer, useCallback, useMemo } from 'react';
import { globalReducer } from './GlobalReducer';

export const GlobalContext = createContext({});

const CONTEXT_INITIAL_STATE = {
  username: '',
  isUserAdmin: false,
  tablesConfig: {},
  userTimeZone: '',
  technologies: [],
};

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, CONTEXT_INITIAL_STATE);
  const setUsername = useCallback((user) => {
    dispatch({ type: 'SET_USER', payload: user });
  }, []);

  const setUserAdmin = useCallback((isAdmin) => {
    dispatch({ type: 'SET_USER_ADMIN', payload: isAdmin });
  }, []);
  const setTablesConfig = useCallback((config) => {
    dispatch({ type: 'SET_TABLES_CONFIG', payload: config });
  }, []);
  const setUserTimeZone = useCallback((timeZone) => {
    dispatch({ type: 'SET_USER_TIME_ZONE', payload: timeZone });
  }, []);
  const setTechnologies = useCallback((technologies) => {
    dispatch({ type: 'SET_TECHNOLOGIES', payload: technologies });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setUsername,
      setUserAdmin,
      setTablesConfig,
      setUserTimeZone,
      setTechnologies,
    }),
    [state, setUsername, setUserAdmin, setTablesConfig, setUserTimeZone, setTechnologies]
  );
  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};
