import L from 'leaflet';
import Popup from 'components/Map/Popup/Popup';
import { propDelayStyle, agentsStyle, popupOptions } from '../variables/customMap';
import * as actions from '../actions';
import ReactDOM from 'react-dom';

export const buildBaseLayers = async (array) => {
  var layers_array = [];
  var controls_array = {};
  array.forEach((element) => {
    var basemap = L.tileLayer(element.url, {
      maxZoom: 18,
      attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    });

    layers_array.push(basemap);
    controls_array[element.label] = basemap;
  });
  return { layers: layers_array, controls: controls_array };
};

export const buildPropDelayLayer = (data) => {
  let opacity;
  let layer = L.geoJSON(data.data[0].featurecollection, {
    style: function (feature) {
      for (var i = 0; i < propDelayStyle.propDelayRangeLimit.length; i++) {
        let range = propDelayStyle.propDelayRangeLimit[i];
        if (feature.properties.pd_percentage >= range[0] && feature.properties.pd_percentage <= range[1]) {
          opacity = propDelayStyle.opacity[i];
          i = propDelayStyle.propDelayRangeLimit.length;
        }
      }

      return {
        fillColor: propDelayStyle.fillColor,
        color: propDelayStyle.color,
        fillOpacity: opacity,
        opacity: propDelayStyle.opacity,
        weight: propDelayStyle.weight,
      };
    },
    onEachFeature: function (feature, layer) {
      let value = feature.properties.pd_percentage.toFixed(2);
      layer.bindPopup('<strong>Propagation Delay - ' + value + '%');
    },
  });

  let legend = [];

  for (var i = 0; i < propDelayStyle.propDelayLegend.length; i++) {
    let value = propDelayStyle.propDelayLegend[i];
    opacity = propDelayStyle.opacity[i];

    legend.push({ color: propDelayStyle.fillColor, opacity: opacity, text: value });
  }

  return { layer: layer, legend: legend };
};

export const buildTierLayer = (data) => {
  let layer = L.geoJSON(data.data[0].featurecollection, {
    style: function (feature) {
      switch (feature.properties.id) {
        case 'tier1':
          return { color: 'red' };
        case 'tier2':
          return { color: 'blue' };
        case 'tier3':
          return { color: 'yellow' };
        case 'tier4':
          return { color: 'green' };
        default:
          return { color: 'gray' };
      }
    },
    onEachFeature: function (feature, layer) {
      let value = feature.properties.cell;
      layer.bindPopup('<strong>Tier - ' + value);
    },
  });

  let legend = [];
  legend.push({ color: 'red', text: 'tier1' });
  legend.push({ color: 'blue', text: 'tier2' });
  legend.push({ color: 'yellow', text: 'tier3' });
  legend.push({ color: 'green', text: 'tier4' });

  return { layer: layer, legend: legend };
};

export const buildCrowdsourceLayer = (data, tech) => {
  let color;
  let layer = L.geoJSON(data.data[0].featurecollection, {
    pointToLayer: function (feature, latlng) {
      for (var i = 0; i < agentsStyle.agentsRangeLimit.length; i++) {
        let range = agentsStyle.agentsRangeLimit[i];
        if (feature.properties.signal_strength >= range[0] && feature.properties.signal_strength <= range[1]) {
          color = agentsStyle.colorsForAgents[i];
          i = agentsStyle.agentsRangeLimit.length;
        }
      }

      var geojsonMarkerOptions = {
        radius: agentsStyle.radiusMin,
        fillColor: color,
        color: agentsStyle.borderColor,
        weight: agentsStyle.weight,
        fillOpacity: agentsStyle.fillOpacity,
      };

      return L.circleMarker(latlng, geojsonMarkerOptions);
    },
    onEachFeature: function (feature, layer) {
      var value =
        tech === 'LTE'
          ? `RSRP ${feature.properties.signal_strength} dBm / RSRQ ${feature.properties.rsrq} dB`
          : `RSCP ${feature.properties.signal_strength} dBm / EcNo ${feature.properties.rsrq} dB`;

      layer.bindPopup('<strong>Crowdsource - ' + value);
    },
  });

  let legend = [];

  for (var i = 0; i < agentsStyle.legendForAgents.length; i++) {
    let value = agentsStyle.legendForAgents[i];
    let opacity = agentsStyle.opacity[i];
    let color = agentsStyle.colorsForAgents[i];

    legend.push({ color: color, opacity: opacity, text: value });
  }

  return { layer: layer, legend: legend };
};

export const buildPollutersView = (data) => {
  let layer = L.geoJSON(data.data[0].featurecollection, {
    style: { color: 'yellow', borderColor: 'yellow', opacity: 0.7, fillOpacity: 0.7, weight: 2 },

    onEachFeature: function (feature, layer) {
      let value = feature.properties.agents_cell;
      layer.bindPopup('<strong>Polluter - ' + value);
    },
  });

  let legend = [];
  legend.push({ color: 'yellow', text: 'Polluters' });

  let headers = [];
  let body = [];
  let index;
  for (var i = 0; i < data.data[0].featurecollection.features.length; i++) {
    if (i === 0) {
      headers = Object.keys(data.data[0].featurecollection.features[i].properties);
      index = headers.indexOf('the_geom');
      delete headers[index];
    }

    let val = Object.values(data.data[0].featurecollection.features[i].properties);
    delete val[index];

    body.push({ className: '', data: val });
  }

  return { layer: layer, legend: legend, raw_data: { headers: headers, body: body, title: 'Polluters' } };
};

export const buildServiceAreaView = async (cell, week, tech) => {
  const prop_delay = await actions.prop_delay({ cell: cell.cell_name, week: week });
  const tiers = await actions.tiers({ cell: cell.cell_name, week: week });
  const crowdsource = await actions.crowdsource({ cell: cell.cell_name, week: week });
  const polluters = await actions.cell_polluters({ cell: cell.cell_name });
  let layers_array = [];

  if (prop_delay.data[0].featurecollection.features) {
    let map = buildPropDelayLayer(prop_delay);
    layers_array.push({ layer: map.layer, title: `Prop Delay ${cell.cell_name}`, fitBounds: false, legend: map.legend });
    map = buildTierLayer(tiers);
    layers_array.push({ layer: map.layer, title: `Tiers ${cell.cell_name}`, fitBounds: true, legend: map.legend });
    map = buildCrowdsourceLayer(crowdsource, tech);
    layers_array.push({ layer: map.layer, title: `Crowdsource ${cell.cell_name}`, fitBounds: false, legend: map.legend });
    map = buildPollutersView(polluters);
    layers_array.push({ layer: map.layer, title: `Polluters ${cell.cell_name}`, fitBounds: false, legend: map.legend, data: map.raw_data });
  }

  return layers_array;
};

function buildPopup(feature, handleClickCellName, techSelected, setTechnology, handleUpdateMapTechSelection) {
  const container = document.createElement('div');
  ReactDOM.render(
    <Popup
      feature={feature}
      handleClickCellName={handleClickCellName}
      techSelected={techSelected}
      setTechnology={setTechnology}
      handleUpdateMapTechSelection={handleUpdateMapTechSelection}
    />,
    container
  );
  return container;
}

export const buildTopologyView = async (
  tech,
  bounds,
  zoomThreshold,
  selectedCell,
  mapCellSelected,
  date = null,
  dlearfcn,
  reload,
  baseLayer,
  handleClickCellRow,
  setCellSelected,
  setMapCellSelected,
  setPrevSelectedCell,
  setTechnology,
  handleUpdateMapTechSelection
) => {
  try {
    let layersArray = baseLayer || [];
    const legend4G = [
      { color: '#006699', text: 'Active' },
      { color: 'gray', text: 'Halted' },
    ];
    const legend5G = [
      { color: '#006699', text: 'Active' },
      { color: 'gray', text: 'Halted' },
    ];
    const legend2G = [
      { color: '#006699', text: 'Active' },
      { color: 'gray', text: 'Halted' },
    ];

    if (reload) {
      setPrevSelectedCell(selectedCell);
      layersArray = [];

      const addLayer = async (techType, dlearfcnType, legendType) => {
        const topology = await actions.topology({ bbox: bounds, zoom: zoomThreshold, tech: techType, date: date });
        const cheeseLayer = L.geoJSON(topology[0].featurecollection, {
          style: function (feature) {
            if (feature.properties['cell_name'] === mapCellSelected) {
              return { color: '#733E98', borderColor: '#733E98', opacity: 0.7, fillOpacity: 0.7, weight: 2 };
            } else {
              switch (feature.properties.state) {
                case 'ACTIVE':
                  let dlearfcnValue = '';
                  switch (techType) {
                    case 'LTE':
                      dlearfcnValue = feature.properties.dlearfcn;
                      break;
                    case 'NR':
                      dlearfcnValue = feature.properties.dlnarfcn;
                      break;
                    case 'GSM':
                      dlearfcnValue = feature.properties.freqband;
                      break;
                    default:
                      break;
                  }
                  return { color: dlearfcnType[dlearfcnValue] || '#e60000', border: 0, opacity: 1, fillOpacity: 1, weight: 2 };
                case 'INACTIVE':
                  return { color: 'gray', borderColor: '#0000ff', opacity: 0.7, fillOpacity: 0.7, weight: 2 };
                default:
                  return { color: 'gray', borderColor: '#0000ff', opacity: 0.7, fillOpacity: 0.7, weight: 2 };
              }
            }
          },
          onEachFeature: function (feature, layer) {
            const handleClickCellName = () => {
              handleClickCellRow(feature.properties);
              setMapCellSelected(feature.properties['cell_name']);
              setCellSelected({ table: 'table1', cellName: feature.properties['cell_name'].slice(0, -2) });
            };
            layer.on('click', function () {
              const popupContent = buildPopup(feature, handleClickCellName, tech, setTechnology, handleUpdateMapTechSelection);
              layer.bindPopup(popupContent, popupOptions);
              layer.openPopup();
            });
          },
        });
        layersArray.push({ layer: cheeseLayer, title: techType, fitBounds: false, legend: legendType });
      };

      await addLayer('LTE', dlearfcn.LTE, legend4G);
      await addLayer('NR', dlearfcn.NR, legend5G);
      await addLayer('GSM', dlearfcn.GSM, legend2G);
    }

    return [layersArray];
  } catch (error) {
    console.error(error);
  }
};
