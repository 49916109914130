/** @jsxImportSource @emotion/react */
import { FutureTable } from 'components/Table/FutureTable';
import { Col, Button, Form, Label, Input } from 'reactstrap';
import { useUserDashboardController } from './UserDashboard.controller';
import { StyledFormGroup, styles } from './UserDashboard.styles';
import Card from 'components/Card';

export const UserDashboard = () => {
  const {
    userList,
    selectedUser,
    newUserState,
    excelsQueue,
    setNewUserState,
    isUserAdmin,
    handleClickUserRow,
    handleSubmit,
    handleDeleteUser,
    handleDeleteExcel,
    handleCancel,
    table1Config,
    table2Config,
    table1DynamicCols,
    table1DynamicColsLoading,
    table2DynamicCols,
    table2DynamicColsLoading,
  } = useUserDashboardController();

  const username = (newUserState['name'] || selectedUser?.name) ?? '';

  const isDisabled =
    !username || newUserState['password'] !== newUserState['confirm_password'] || !newUserState['password'] || !newUserState['confirm_password'];

  const queuesDefaultColumns = table2DynamicCols[table2Config.table_id] || [];

  return (
    <>
      <Col xl={isUserAdmin ? 6 : 12}>
        <Card title={table2Config.table_title} isLoading={table2DynamicColsLoading}>
          {excelsQueue.length > 0 ? (
            <FutureTable
              data={excelsQueue}
              defaultColumns={table2DynamicCols[table2Config.table_id]}
              columnsConfig={{
                isStriped: false,
                dynamicColumns: false,
              }}
              toolbarConfig={{
                showRowNumber: true,
              }}
              actionsColumn={true}
              handleDelete={handleDeleteExcel}
              excludeColumns={['_id']}
            />
          ) : (
            <p css={styles.flex}>No excels on standby</p>
          )}
        </Card>
      </Col>
      {isUserAdmin && (
        <>
          <Col xl={3}>
            <Card title={table1Config.table_title} restricted isLoading={table1DynamicColsLoading}>
              {userList ? (
                <FutureTable
                  data={userList}
                  defaultColumns={table1DynamicCols[table1Config.table_id]}
                  onRowClick={handleClickUserRow}
                  columnsConfig={{
                    isStriped: false,
                    dynamicColumns: false,
                  }}
                  toolbarConfig={{
                    showRowNumber: true,
                  }}
                />
              ) : (
                <p>No users found</p>
              )}
            </Card>
          </Col>
          <Col xl={3}>
            <Card title="User details" restricted>
              <Form css={{ marginBottom: 'auto' }}>
                <StyledFormGroup css={styles.formGroup}>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setNewUserState({ ...newUserState, name: e.target.value })}
                    disabled={!!selectedUser}
                  />

                  <Label for="password">{!!selectedUser && 'New'} Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={newUserState['password']}
                    onChange={(e) => setNewUserState({ ...newUserState, password: e.target.value })}
                  />

                  <Label for="confirm_password">Confirm Password</Label>
                  <Input
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    placeholder="Confirm Password"
                    value={newUserState['confirm_password']}
                    onChange={(e) => setNewUserState({ ...newUserState, confirm_password: e.target.value })}
                  />
                </StyledFormGroup>
              </Form>
              <div css={styles.modalFooter}>
                <Button color="future" onClick={handleSubmit} disabled={isDisabled}>
                  {!!selectedUser ? 'Update' : 'Register'}
                </Button>
                {!!selectedUser && (
                  <Button color="future" onClick={handleCancel}>
                    Cancel
                  </Button>
                )}
                {!!selectedUser && (
                  <Button color="danger" onClick={handleDeleteUser}>
                    Delete
                  </Button>
                )}
              </div>
            </Card>
          </Col>
        </>
      )}
    </>
  );
};
