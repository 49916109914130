import styled from '@emotion/styled';
import { Col, Button, FormGroup } from 'reactstrap';

export const StyledCol = styled(Col)``;

export const StyledButton = styled(Button)``;

export const StyledIcon = styled.i``;

export const StyledFormGroup = styled(FormGroup)``;

const flexStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const colStyles = (isTableExpanded, transition) => {
  const partialStyles = transition
    ? {
        width: isTableExpanded ? 0 : '50%',
        height: isTableExpanded ? 0 : '100%',
        opacity: isTableExpanded ? 0 : 1,
        transition,
      }
    : {
        width: `${isTableExpanded ? '100' : '50'}%`,
        transition: `width 0.5s ease-in-out ${isTableExpanded ? '0.45s' : ''}`,
      };

  return {
    ...partialStyles,
    flex: '0 0 auto',
  };
};

const cardButtonStyles = {
  position: 'absolute',
  right: '1rem',
  top: '1rem',
  margin: 0,
  padding: '0 0 0 0.5rem',
  '&.btn.btn-link': {
    color: 'gray',
    border: 'none',
    backgroundColor: 'transparent',
  },
  '&.btn.btn-link:hover': {
    boxShadow: 'none',
  },
  '&.btn.btn-link:not(.disabled):active': {
    color: 'lightgray',
    backgroundColor: 'transparent',
  },
};

const cardIconStyles = {
  fontSize: '16px',
};

export const styles = {
  col: colStyles,
  cardButton: cardButtonStyles,
  cardIcon: cardIconStyles,
  flex: flexStyles,
};
