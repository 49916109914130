import { useContext, useEffect } from 'react';
import * as actions from 'actions';
import { useFetchData } from 'hooks/useFetchData';
import { useFetchDynamicCols } from 'hooks/useFetchDynamicCols';
import { GlobalContext } from 'context';

export const useCodirectedNeighboursController = () => {
  const { tablesConfig } = useContext(GlobalContext);
  const table1Config = tablesConfig[23] || {};
  const table2Config = tablesConfig[24] || {};

  const { dynamicCols, dynamicColsLoading } = useFetchDynamicCols([table1Config.table_id, table2Config.table_id]);

  const {
    fetchData: fetchCodirectedNeighborsDataTable1,
    pageData: pageDataTable1,
    pageCount: pageCountTable1,
    setTableKey: setTableKeyTable1,
  } = useFetchData(actions.codirectedNeighbors2G);

  const {
    fetchData: fetchCodirectedNeighborsDataTable2,
    pageData: pageDataTable2,
    pageCount: pageCountTable2,
    setTableKey: setTableKeyTable2,
  } = useFetchData(actions.codirectedNeighbors5G);

  useEffect(() => {
    setTableKeyTable1(table1Config.table_id);
    setTableKeyTable2(table2Config.table_id);
  }, [setTableKeyTable1, setTableKeyTable2]);

  return {
    pageDataTable1,
    pageCountTable1,
    fetchCodirectedNeighborsDataTable1,
    pageDataTable2,
    pageCountTable2,
    fetchCodirectedNeighborsDataTable2,
    dynamicColumns: dynamicCols,
    dynamicColsLoading,
    table1Config,
    table2Config,
  };
};
