import { useRangeOptimizationController } from './RangeOptimization.controller';
import { FutureTable } from 'components/Table/FutureTable';
import { Col } from 'reactstrap';
import Card from 'components/Card';

export const RangeOptimization = () => {
  const {
    controlledPageCount,
    defaultColumns,
    dynamicColsLoading,
    fetchTable1Data,
    fetchTable2Data,
    table1Data,
    table2Data,
    table1Config,
    table2Config,
  } = useRangeOptimizationController();

  return (
    <Col xs={12} md={12}>
      <Card title={table1Config.table_title} isLoading={dynamicColsLoading} className={table1Data.data?.length === 0 ? 'card-7' : ''}>
        <FutureTable
          data={table1Data.data || []}
          defaultColumns={defaultColumns[table1Config.table_id]}
          columnsConfig={{
            isStriped: false,
            dynamicColumns: false,
            expandColumns: true,
          }}
          toolbarConfig={{
            showExport: true,
            showRowNumber: true,
          }}
          paginationEnabled={true}
          exportConfig={{ excelName: table1Config.file_name, sheetName: table1Config.file_name, id: table1Config.table_id }}
          pagData={table1Data}
          fetchData={fetchTable1Data}
          controlledPageCount={controlledPageCount[table1Config.table_id]}
          table_id={table1Config.table_id}
          tableKey={table1Config.table_id}
        />
      </Card>

      <Card title={table2Config.table_title} isLoading={dynamicColsLoading} className={table2Data.data?.length === 0 ? 'card-7' : ''}>
        <FutureTable
          data={table2Data.data || []}
          defaultColumns={defaultColumns[table2Config.table_id]}
          columnsConfig={{
            isStriped: false,
            dynamicColumns: false,
            expandColumns: true,
          }}
          toolbarConfig={{
            showExport: true,
            showRowNumber: true,
          }}
          paginationEnabled={true}
          exportConfig={{ excelName: table2Config.file_name, sheetName: table2Config.file_name, id: table2Config.table_id }}
          pagData={table2Data}
          fetchData={fetchTable2Data}
          controlledPageCount={controlledPageCount[table2Config.table_id]}
          table_id={table2Config.table_id}
          tableKey={table2Config.table_id}
        />
      </Card>
    </Col>
  );
};
