/** @jsxImportSource @emotion/react */
import { useCSONModulesController } from './CSONModules.controller';
import TabSelector from 'components/TabSelector';
import CodirectedNeighbours from './components/CodirectedNeighbours';
import RangeOptimization from './components/RangeOptimization';
import CoordinatesOvershooters from './components/Coordinates&Overshooters';
import MissingNeighbours from './components/MissingNeighbours';
import DLRSAdvanced from './components/DLRSAdvanced';
import TrafficSteering from './components/TrafficSteering';

export const CSONModules = () => {
  const { activeTab, setActiveTab, tabOptions } = useCSONModulesController();

  const renderedTab = (activeTab) => {
    switch (activeTab) {
      case 'CODIRECTED_NEIGHBOURS_AUDIT':
        return <CodirectedNeighbours />;
      case 'AUTO_CELLRANGE_OPTIMIZATION':
        return <RangeOptimization />;
      case 'MISSING_NEIGHBOURS':
        return <MissingNeighbours />;
      case 'COORDINATES AND OVERSHOOTERS AUDIT':
        return <CoordinatesOvershooters />;
      case 'DLRS ADVANCED BOOST':
        return <DLRSAdvanced />;
      case 'TRAFFIC STEERING':
        return <TrafficSteering />;
      default:
        return null;
    }
  };

  return (
    <>
      <TabSelector options={tabOptions} selected={activeTab} onChange={setActiveTab} showScrollIcons />
      {renderedTab(activeTab)}
    </>
  );
};
