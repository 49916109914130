import styled from '@emotion/styled';

export const StyledTableWrapper = styled.div``;

export const StyledTableRow = styled.tr``;

const TABLE_HEIGHT = 600;

const tableStyles = {
  display: 'flex',
  flex: 'auto 1',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
  overflow: 'auto',
  height: `calc(${TABLE_HEIGHT}px - 50px)`,

  '@media (max-width: 1440px)': {
    height: `calc(${TABLE_HEIGHT}px / 2)`,
  },
};

const tableWrapperStyles = (disabled) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  backgroundColor: 'transparent',
  pointerEvents: disabled ? 'none' : 'auto',

  '*': {
    boxSizing: 'border-box',
  },
});

const tableRowStyles = (applyHoverStyle) => ({
  display: 'inline-flex',
  flex: '1 0 auto',
  height: '3rem',
  maxHeight: '3rem',
  minWidth: 'fit-content',
  width: '100%',

  ...(applyHoverStyle && {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)',
    },
  }),

  '&.-odd': {
    background: 'rgba(0, 0, 0, 0.05)',
  },
});

const flexStyles = {
  display: 'flex',
  justifyContent: 'center',
};

export const styles = {
  table: tableStyles,
  tableWrapper: tableWrapperStyles,
  tableRow: tableRowStyles,
  flex: flexStyles,
};
