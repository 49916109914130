import styled from '@emotion/styled';
import { Button } from 'reactstrap';

export const StyledToolbar = styled.div``;

export const StyledButton = styled(Button)``;

export const StyledIcon = styled.i``;

export const StyledDropdownOption = styled.div``;

export const StyledRowNumber = styled.span``;

export const StyledCollapseToggler = styled.div``;

export const StyledVendorButtonsContainer = styled.div``;

export const StyledDropdownMenuContent = styled.div``;

export const StyledLoadingOverlay = styled.div``;

const flexStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const toolbarButtonStyles = {
  margin: 0,
  padding: '0 0 0 0.5rem',
  '&.btn.btn-link': {
    color: 'gray',
    border: 'none',
    backgroundColor: 'transparent',
  },

  '&.btn.btn-link:hover': {
    boxShadow: 'none',
  },

  '&.btn.btn-link:not(.disabled):active': {
    color: 'lightgray',
    backgroundColor: 'transparent',
  },
};

const toolbarIconStyles = {
  fontSize: '16px',
};

const dropdownMenuContentStyles = {
  minWidth: '225px',
  maxHeight: '280px',
  width: '16.5rem',
  overflow: 'hidden auto',
  position: 'relative',
};

const dropdownOptionStyles = {
  ...flexStyles,
  userSelect: 'none',
  marginLeft: '2.7rem',
  marginBottom: '3px',
  input: {
    cursor: 'pointer',
    marginTop: 0,
  },
  span: {
    marginLeft: '0.25rem',
    minWidth: 'max-content',
  },
};

const rowNumberStyles = {
  color: '#898989',
  fontWeight: '700',
  marginLeft: 'auto',
};

const collapseTogglerStyles = {
  ...dropdownOptionStyles,
  cursor: 'pointer',
  marginLeft: '1.5rem',
  fontWeight: 'bold',
};

const searchInputGroupStyles = {
  margin: '5px 2.5%',
  padding: 0,
  width: '95%',
};

const vendorButtonsContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '0 auto',
  width: '75%',
};

const loadingOverlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const dropdownMenuStyles = {
  transform: 'translate3d(0px, 21px, 0px) !important',
  willChange: 'transform !important',
};

export const styles = {
  toolbar: flexStyles,
  toolbarButton: toolbarButtonStyles,
  toolbarIcon: toolbarIconStyles,
  dropdownMenuContent: dropdownMenuContentStyles,
  dropdownOption: dropdownOptionStyles,
  rowNumber: rowNumberStyles,
  collapseToggler: collapseTogglerStyles,
  searchInputGroup: searchInputGroupStyles,
  vendorButtonsContainer: vendorButtonsContainerStyles,
  loadingOverlay: loadingOverlayStyles,
  dropdownMenu: dropdownMenuStyles,
};
