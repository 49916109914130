import { format } from 'date-fns';

export const barData = (data) => {
  const res = data.map((bar, index) => {
    return { name: format(new Date(bar.date), 'yyyy-MM-dd').toUpperCase(), total: parseInt(bar.total) };
  });
  return res;
};

const baseColors = [
  '#98FB98',
  '#ADD8E6',
  '#FFD700',
  '#FFA07A',
  '#FFE4B5',
  '#FF6347',
  '#FFC0CB',
  '#00FF7F',
  '#FFA500',
  '#FFE4C4',
  '#B0E0E6',
  '#FFDAB9',
  '#00FFFF',
  '#F0E68C',
  '#008B8B',
  '#D8BFD8',
  '#FAFAD2',
  '#7FFFD4',
  '#F5DEB3',
  '#87CEEB',
  '#FF4500',
  '#EE82EE',
  '#808000',
  '#FF69B4',
  '#87CEFA',
  '#DB7093',
  '#E6E6FA',
  '#BDB76B',
  '#4682B4',
  '#6B8E23',
  '#556B2F',
  '#8B4513',
  '#483D8B',
  '#3CB371',
  '#2E8B57',
  '#20B2AA',
  '#008080',
  '#8B0000',
  '#5F9EA0',
];

export const pieData = (array) => {
  let colors = [];
  let data = [];
  let others = 0;

  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (index < 5) {
      colors.push(baseColors[index % baseColors.length]);
      data.push({ name: element.parametername, value: parseInt(element.total) });
    } else {
      others += parseInt(element.total);
    }
  }
  colors.push(baseColors[baseColors.length - 1]);
  data.push({ name: 'others', value: others });

  return { data: data, colors: colors };
};
