/** @jsxImportSource @emotion/react */
import { useContext, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { StyledButton, StyledCol, StyledIcon, styles } from './CellConfiguration.styles';
import { useCellConfigurationController } from './CellConfiguration.controller';
import Card from 'components/Card';
import { FutureTable } from 'components/Table/FutureTable';
import { NetworkMonitoringContext } from 'context/networkMonitoring/NetworkMonitoringContext';
import MapWrapper from 'components/Map/MapWrapper/MapWrapper';

const CellConfiguration = ({
  fetchTable1Data,
  fetchTable2Data,
  fetchParamsTable2,
  baseLayers,
  mapLayers,
  zoomHandler,
  view,
  zoomThreshold,
  loading,
  handleResetColumnVisible,
  handleClickCellRow,
  updateVisibleColumns,
  table1Loading,
  table2Loading,
  table1Config,
  table2Config,
}) => {
  const { expanded, transition, setExpanded, onCellInfoFilterChange } = useCellConfigurationController();
  const {
    table1,
    table2,
    cellSelected,
    dynamicColumns,
    controlledPageCount,
    sharedColumnCollapseData,
    setSharedColumnCollapseData,
    technology,
    setMapTechSelection,
    mapTechSelection,
    filterResetKey,
  } = useContext(NetworkMonitoringContext);

  const firstFilterMap = useMemo(() => [{ id: 'cell_name', value: cellSelected['table1'] }], [cellSelected['table1']]);

  const secondFilterMap = useMemo(() => [{ id: 'cell_name', value: cellSelected['table2'] }], [cellSelected['table2']]);

  return (
    <Col xs={12} md={12}>
      <Row>
        <StyledCol css={styles.col(expanded)}>
          <Card
            title={table1Config?.table_title}
            headerContent={
              <StyledButton css={styles.cardButton} title="Expand table" color="link" onClick={() => setExpanded(!expanded)}>
                <StyledIcon css={styles.cardIcon} className="fas fa-expand" />
              </StyledButton>
            }
            bodyHeight="55em"
          >
            {dynamicColumns['table1']?.length > 0 && (
              <FutureTable
                key={filterResetKey}
                tableKey={table1Config?.table_id}
                data={table1.data}
                defaultColumns={dynamicColumns['table1']}
                filterMap={firstFilterMap}
                onFilterChange={onCellInfoFilterChange}
                onRowClick={handleClickCellRow}
                columnsConfig={{
                  isStriped: false,
                  dynamicColumns: false,
                  expandColumns: true,
                }}
                toolbarConfig={{
                  showFilter: true,
                  showExport: true,
                  showRowNumber: true,
                  showImportColumns: true,
                  showExportColumns: true,
                }}
                exportConfig={{
                  excelName: table1Config?.file_name,
                  sheetName: table1Config?.file_name,
                  id: table1Config?.table_id,
                }}
                paginationEnabled={true}
                pagData={table1}
                fetchData={fetchTable1Data}
                controlledPageCount={controlledPageCount['table1']}
                updateVisibleColumns={updateVisibleColumns}
                sharedColumnCollapseData={sharedColumnCollapseData}
                updateSharedColumnCollapseData={(columnCollapseData) => setSharedColumnCollapseData(columnCollapseData)}
                handleResetColumnVisible={handleResetColumnVisible}
                tableIdRequired={true}
                table_id={table1Config?.table_id}
                disabledCollapseColumns={['cell_name']}
              />
            )}
          </Card>
        </StyledCol>
        <StyledCol css={styles.col(expanded, transition)}>
          <Card className="card-60" isLoading={!baseLayers.current || loading} preRender>
            <MapWrapper
              baseLayers={baseLayers.current}
              permanentLayers={mapLayers.current.base}
              featureLayers={mapLayers.current.others}
              zoomHandler={zoomHandler}
              setView={view}
              zoomThreshold={zoomThreshold}
              technology={technology}
              setMapTechSelection={setMapTechSelection}
              mapTechSelection={mapTechSelection}
            />
          </Card>
        </StyledCol>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Card title={table2Config?.table_title}>
            {dynamicColumns['table2']?.length > 0 && (
              <FutureTable
                key={filterResetKey}
                tableKey={table2Config?.table_id}
                data={table2.data}
                defaultColumns={dynamicColumns['table2']}
                filterMap={secondFilterMap}
                columnsConfig={{
                  isStriped: false,
                  dynamicColumns: false,
                  expandColumns: true,
                }}
                toolbarConfig={{
                  showFilter: true,
                  showExport: true,
                  showRowNumber: true,
                }}
                paginationEnabled={false}
                exportConfig={{
                  excelName: table2Config?.file_name,
                  sheetName: table2Config?.file_name,
                  id: table2Config?.table_id,
                  dateRangeNeeded: true,
                  cellSelectedNeeded: true,
                }}
                fetchData={fetchTable2Data}
                updateVisibleColumns={updateVisibleColumns}
                sharedColumnCollapseData={sharedColumnCollapseData}
                updateSharedColumnCollapseData={(columnCollapseData) => setSharedColumnCollapseData(columnCollapseData)}
                handleResetColumnVisible={handleResetColumnVisible}
                fetchParams={fetchParamsTable2}
                tableIdRequired={true}
                table_id={table2Config?.table_id}
                disabledCollapseColumns={['cell_name']}
              />
            )}
          </Card>
        </Col>
      </Row>
    </Col>
  );
};
export default CellConfiguration;
