const buttonStyles = {
  maxWidth: '12rem',
};

const commonStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
};

const tecnologySelectorStyles = {
  ...commonStyles,
  justifyContent: 'center',
};

const buttonContainerStyles = {
  ...commonStyles,
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  overflow: 'auto',
};

const buttonColStyles = {
  display: 'flex',
  justifyContent: 'center',
};

export const styles = {
  button: buttonStyles,
  tecnologySelector: tecnologySelectorStyles,
  buttonContainer: buttonContainerStyles,
  buttonCol: buttonColStyles,
};
