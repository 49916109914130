/** @jsxImportSource @emotion/react */
import { useMemo, useRef } from 'react';
import { Button, Dropdown, DropdownToggle, DropdownMenu, Input, InputGroup, UncontrolledCollapse } from 'reactstrap';
import { useToolbarController } from './Toolbar.controller';
import {
  StyledToolbar,
  StyledButton,
  StyledIcon,
  StyledDropdownOption,
  StyledRowNumber,
  StyledCollapseToggler,
  StyledDropdownMenuContent,
  StyledLoadingOverlay,
  StyledVendorButtonsContainer,
  styles,
} from './Toolbar.styles';
import { Spinner } from 'components/Spinner';
import { HUAFILTER, NOKFILTER } from '../../FutureTable.controller';

const Toolbar = ({
  rowNumber,
  toolbarOptions,
  handleVisibleColumnsChange,
  handleResetColumnVisible,
  exportOptions,
  collapseColumns,
  handleVisibleColumnsCollapseChange,
  loadingColumnsSelection,
  huaFilter,
  nokFilter,
  handleVendorSelector,
  handleExpandAll,
  isExpandedAll,
  setIsLoading,
  updateVisibleColumns,
  updateSharedColumnCollapseData,
  resetFilters,
  disabledCollapseColumns,
}) => {
  const {
    dropdownVisibility,
    handleFilterClick,
    handleExportClick,
    handleImportColumnsClick,
    handleExportColumnsClick,
    toggleCollapse,
    searchTerm,
    setSearchTerm,
    filteredColumns,
  } = useToolbarController({
    exportOptions,
    collapseColumns,
    updateVisibleColumns,
    updateSharedColumnCollapseData,
    setIsLoading,
    resetFilters,
  });

  const fileInputRef = useRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const filter = useMemo(() => {
    // filter functionality only lives with dynamic columns for the time being
    if (!toolbarOptions.showFilter) {
      return null;
    }

    const parseString = (string) => {
      const parsedString = string.replace(/_/g, ' ');
      const parsedStringArray = parsedString.split(' ');
      const capitalizedStringArray = parsedStringArray.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      return capitalizedStringArray.join(' ');
    };

    const generateOptions = (options, collapseKey) => {
      const optionsArray = [];
      let isOptionMatched = false;
      Object.entries(options).forEach(([key, value]) => {
        isOptionMatched = true;
        optionsArray.push(
          <StyledDropdownOption css={styles.dropdownOption} key={key} title={key}>
            <Input
              type="checkbox"
              id={key}
              checked={value}
              onChange={(e) => handleVisibleColumnsChange(e, collapseKey)}
              disabled={disabledCollapseColumns.includes(key) ? true : false}
            />
            <span>{parseString(key)}</span>
          </StyledDropdownOption>
        );
      });
      return isOptionMatched ? optionsArray : null;
    };

    const generateCollapse = () => {
      if (!filteredColumns) return null;
      const collapseKeys = Object.keys(filteredColumns);
      const visibleColumnsGeneralState = {};
      collapseKeys.forEach((key) => {
        visibleColumnsGeneralState[key] = !Object.values(filteredColumns[key]).some((value) => value === false);
      });
      const collapseArray = [];
      collapseKeys.forEach((key, index) => {
        const options = generateOptions(filteredColumns[key], key);
        if (options) {
          collapseArray.push(
            <div key={key}>
              <StyledCollapseToggler css={styles.collapseToggler} id={key} onClick={() => toggleCollapse('collapse1')}>
                <Input
                  type="checkbox"
                  id={key}
                  checked={visibleColumnsGeneralState[key]}
                  onChange={(e) => {
                    handleVisibleColumnsCollapseChange(e, visibleColumnsGeneralState[key], filteredColumns);
                  }}
                />
                <span>
                  {parseString(key)}
                  <span className={`fas fa-caret-down`} />
                </span>
              </StyledCollapseToggler>
              <UncontrolledCollapse toggler={key.replace(/\(/g, '\\(').replace(/\)/g, '\\)')}>{options}</UncontrolledCollapse>
            </div>
          );
        }
      });
      return collapseArray;
    };

    return (
      <Dropdown isOpen={dropdownVisibility.filter} toggle={handleFilterClick}>
        <DropdownToggle data-toggle="dropdown" tag="div">
          <StyledButton css={styles.toolbarButton} title="Visible columns" color="link">
            <StyledIcon css={styles.toolbarIcon} className="fas fa-filter" name="filter" />
          </StyledButton>
        </DropdownToggle>
        <DropdownMenu css={styles.dropdownMenu}>
          {loadingColumnsSelection && (
            <StyledLoadingOverlay css={styles.loadingOverlay}>
              <Spinner />
            </StyledLoadingOverlay>
          )}
          <StyledDropdownMenuContent css={styles.dropdownMenuContent}>
            <StyledVendorButtonsContainer css={styles.vendorButtonsContainer}>
              <Button type="button" color="future" active={huaFilter} onClick={() => handleVendorSelector(HUAFILTER, !huaFilter)}>
                HUA
              </Button>
              <Button type="button" color="future" active={nokFilter} onClick={() => handleVendorSelector(NOKFILTER, !nokFilter)}>
                NOK
              </Button>
            </StyledVendorButtonsContainer>
            <InputGroup css={styles.searchInputGroup}>
              <Input type="search" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </InputGroup>

            {generateCollapse()}
          </StyledDropdownMenuContent>
        </DropdownMenu>
      </Dropdown>
    );
  }, [
    dropdownVisibility.filter,
    handleFilterClick,
    handleVisibleColumnsChange,
    handleVisibleColumnsCollapseChange,
    toggleCollapse,
    toolbarOptions.showFilter,
    searchTerm,
    setSearchTerm,
    huaFilter,
    nokFilter,
    handleVendorSelector,
    filteredColumns,
    loadingColumnsSelection,
  ]);

  const resetFilter = useMemo(
    () =>
      toolbarOptions.showFilter && handleResetColumnVisible ? (
        <StyledButton
          css={styles.toolbarButton}
          title="Restore visible columns"
          color="link"
          onClick={() => {
            handleResetColumnVisible(setIsLoading);
            handleVendorSelector(HUAFILTER, false, false);
            handleVendorSelector(NOKFILTER, false, false);
          }}
        >
          <StyledIcon css={styles.toolbarIcon} className="fas fa-broom" />
        </StyledButton>
      ) : null,
    [toolbarOptions.showFilter, handleResetColumnVisible, handleVendorSelector]
  );

  const importColumns = useMemo(() =>
    toolbarOptions.showImportColumns ? (
      <StyledButton css={styles.toolbarButton} title="Import JSON visible columns" color="link" onClick={handleButtonClick}>
        <input type="file" onChange={handleImportColumnsClick} style={{ display: 'none' }} ref={fileInputRef} />
        <StyledIcon css={styles.toolbarIcon} className="fas fa-upload" />
      </StyledButton>
    ) : null
  );

  const exportColumns = useMemo(() =>
    toolbarOptions.showExportColumns ? (
      <StyledButton css={styles.toolbarButton} title="Export JSON visible columns" color="link" onClick={handleExportColumnsClick}>
        <StyledIcon css={styles.toolbarIcon} className="fas fa-download" />
      </StyledButton>
    ) : null
  );

  const expandAll = useMemo(
    () => (
      <StyledButton
        css={styles.toolbarButton}
        title={isExpandedAll ? 'Compress all columns' : 'Expand all columns'}
        color="link"
        onClick={() => handleExpandAll()}
      >
        <StyledIcon css={styles.toolbarIcon} className={`fas ${isExpandedAll ? 'fa-compress-arrows-alt' : 'fa-expand-arrows-alt'}`} />
      </StyledButton>
    ),
    [handleExpandAll, isExpandedAll]
  );

  const exportButton = useMemo(
    () =>
      toolbarOptions.showExport ? (
        <StyledButton css={styles.toolbarButton} title="Export table" color="link" onClick={handleExportClick}>
          <StyledIcon css={styles.toolbarIcon} className="fas fa-file-export" />
        </StyledButton>
      ) : null,
    [toolbarOptions.showExport, handleExportClick]
  );

  const rows = useMemo(
    () =>
      toolbarOptions.showRowNumber ? (
        <StyledRowNumber css={styles.rowNumber}>{`${rowNumber} row${rowNumber === 1 ? '' : 's'}`}</StyledRowNumber>
      ) : null,
    [toolbarOptions.showRowNumber, rowNumber]
  );

  if (!filter && !rows) {
    return null;
  }
  return (
    <StyledToolbar css={styles.toolbar}>
      {filter}
      {resetFilter}
      {importColumns}
      {exportColumns}
      {expandAll}
      {exportButton}
      {rows}
    </StyledToolbar>
  );
};

export { Toolbar };
