import styled from '@emotion/styled';
import { DropdownMenu, Input } from 'reactstrap';

export const StyledTableHeader = styled.th``;

export const StyledHeaderContent = styled.div``;

export const StyledIcon = styled.span``;

export const StyledInput = styled(Input)``;

export const StyledHeaderText = styled.div``;

export const StyledToolbar = styled.div``;

export const StyledDropdownMenu = styled(DropdownMenu)``;

export const StyledDropdownOption = styled.div``;

export const StyledInputContainer = styled.div``;

export const StyledOptionContainer = styled.div``;

const tableHeaderStyles = (isSomeColumnFilterable, expanded, expandedColumnWidth, fixed, position) => ({
  fontSize: '12px',
  textTransform: 'uppercase',
  color: '#9a9a9a',
  backgroundColor: 'white',
  fontWeight: 400,
  marginTop: 0,
  marginLeft: 0,

  flex: '1 0 auto',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  padding: '7px 5px',
  overflow: 'hidden',
  transition: '0.3s ease',
  transitionProperty: 'width, min-width, padding, opacity',
  margin: 'auto',

  textAlign: 'left',
  lineHeight: '2.5em !important',
  position: fixed ? 'sticky' : 'relative',
  left: position || position === 0 ? `${position}px` : 'auto',
  zIndex: fixed ? 2 : 0,
  boxShadow: 'inset 0 0 0 0 transparent',
  minHeight: isSomeColumnFilterable ? '85px' : 0,
  minWidth: '200px',
  width: expanded ? (expandedColumnWidth > 200 ? `${expandedColumnWidth}px !important` : '200px') : '200px',
});

const flexStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const headerContentStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  '&:focus': {
    outline: 'none',
  },
};

const toolbarIconStyles = {
  opacity: 0.6,
  padding: '0.3rem',
  fontWeight: 900,
  cursor: 'pointer',
};

const sortableIconStyles = (sorted) => ({
  ...toolbarIconStyles,
  opacity: sorted !== 'sort' ? 1 : 0.6,
});

const searchStyles = {
  width: '90%',
};

const texOverflowStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const toolbarStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginRight: '5px',
};

const dropdownMenuStyles = {
  minWidth: '17rem',
  maxWidth: '17rem',
  maxHeight: '17.5rem',
  overflow: 'hidden auto',
};

const dropdownOptionStyles = {
  ...flexStyles,
  userSelect: 'none',
  marginLeft: '5px',
  marginBottom: '3px',
  input: {
    cursor: 'pointer',
    marginTop: 0,
  },
  span: {
    marginLeft: '0.25rem',
    minWidth: 'max-content',
  },
};

const inputContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const styles = {
  tableHeader: tableHeaderStyles,
  headerContent: headerContentStyles,
  icon: toolbarIconStyles,
  sortableIcon: sortableIconStyles,
  search: searchStyles,
  headerText: texOverflowStyles,
  toolbar: toolbarStyles,
  dropdownMenu: dropdownMenuStyles,
  dropdownOption: dropdownOptionStyles,
  inputContainer: inputContainerStyles,
  optionContainer: texOverflowStyles,
};
