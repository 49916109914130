/** @jsxImportSource @emotion/react */
import { Row, Col } from 'reactstrap';
import { StyledButton, styles } from './TabSelector.styles';
import Card from 'components/Card';

export const TabSelector = ({ options, selected, onChange, showScrollIcons }) => {
  const handleClick = (option) => {
    onChange(option);
  };

  return (
    <Col xs={12} md={12}>
      <Card className="card-stats" bodyHeight="auto">
        {showScrollIcons && (
          <StyledButton
            css={styles.firstButtonStyles}
            outline
            onClick={() => {
              const rowElement = document.getElementById('tabs-row');
              rowElement.scrollLeft -= 100;
            }}
          >
            <i className="fas fa-solid fa-chevron-left" />
          </StyledButton>
        )}
        <Row css={styles.row} id="tabs-row">
          {options.map((option) => (
            <Col css={styles.selector(selected === option.name)} key={option.name} onClick={() => handleClick(option.name)}>
              <div css={styles.tabOption} className="statistics">
                <div className={'icon'} css={styles.iconContainer(option.color)}>
                  <i className={option.icon} css={styles.icon} />
                </div>
                <div css={styles.tabTitle}>
                  <h6 css={styles.tabText}>{option.name.replace(/_/g, ' ')}</h6>
                </div>
                {option.handleDownloadClick && (
                  <div css={styles.downloadButtonWrapper}>
                    <button
                      title="Download information"
                      css={styles.downloadButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        return option.handleDownloadClick();
                      }}
                    >
                      <i className="fas fa-light fa-info" />
                    </button>
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>
        {showScrollIcons && (
          <StyledButton
            css={styles.lastButtonStyles}
            onClick={() => {
              const rowElement = document.getElementById('tabs-row');
              rowElement.scrollLeft += 100;
            }}
          >
            <i className="fas fa-solid fa-chevron-right" />
          </StyledButton>
        )}
      </Card>
    </Col>
  );
};
