import styled from '@emotion/styled';

export const StyledButton = styled.button``;

const tabTitleStyles = {
  display: 'flex',
  alignItems: 'center',
};

const rowStyles = {
  height: '100%',
  flexWrap: 'nowrap',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const scrollButtonStyles = {
  background: 'transparent',
  border: 'none',
  borderRadius: '50%',
  color: '#F80F52',
  fontSize: '1rem',
  padding: '0.5rem 1rem',
  position: 'absolute',
  top: '30%',
  zIndex: 100,

  '&:hover, &:hover:focus': {
    backgroundColor: '#F80F52',
    color: 'white',
  },
};

const firstButtonStyles = {
  ...scrollButtonStyles,
  left: '0.5rem',
};

const lastButtonStyles = {
  ...scrollButtonStyles,
  right: '0.5rem',
};

const selectorStyles = (active) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: active ? '2px solid #F80F52' : '2px solid transparent',

    '&:hover,  &:focus': {
      borderBottom: '2px solid #9796C1',
    },
  };
};

const tabOptionStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '15px',
  position: 'relative',
  textAlign: 'center',
  cursor: 'pointer',
};

const iconContainerStyles = (selectedColor) => {
  return {
    margin: '0 15px',
    color: selectedColor,
  };
};

const iconStyles = {
  fontSize: 'large',
};

const tabTextStyles = {
  marginBottom: '5px',
  color: '#9a9a9a',
  fontWeight: 400,
};

const toolbarButtonStyles = {
  margin: 0,
  color: 'gray',
  border: 'none',
  backgroundColor: 'transparent',
};

const downloadButtonWrapper = {
  fontSize: '12px',
  margin: '0 1rem',
  zIndex: 90,
};

export const styles = {
  downloadButtonWrapper,
  downloadButton: toolbarButtonStyles,
  tabTitle: tabTitleStyles,
  row: rowStyles,
  selector: selectorStyles,
  tabOption: tabOptionStyles,
  iconContainer: iconContainerStyles,
  icon: iconStyles,
  tabText: tabTextStyles,
  firstButtonStyles,
  lastButtonStyles,
};
