import styled from '@emotion/styled';
import { Spinner } from 'reactstrap';

export const StyledWrapper = styled.div``;

export const StyledSpinner = styled(Spinner)``;

const wrapperStyles = (height = '100%', overlay) => {
  const overlayStyles = {
    zIndex: '2000',
    position: 'absolute',
  };
  return {
    height,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(overlay && overlayStyles),
  };
};

const innerStyles = (size = '3rem') => ({
  height: size,
  width: size,
});

export const styles = {
  wrapper: wrapperStyles,
  inner: innerStyles,
};
