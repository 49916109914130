const urlBaseSuperAn = `${process.env.REACT_APP_BACK_PROTOCOL_SA}://${process.env.REACT_APP_BACK_HOST_SA}${process.env.REACT_APP_BACKEND_API_CONTEXT_SA}/`;

export const topology = `${urlBaseSuperAn}map/topology`;
export const neighborsMap = `${urlBaseSuperAn}map/neighbors`;
export const cell = `${urlBaseSuperAn}cell/`;
export const cellColumns = `${urlBaseSuperAn}cell/dynamic_columns`;
export const columnsConfiguration = `${urlBaseSuperAn}cell/columns_configuration`;
export const excludeDays = `${urlBaseSuperAn}cell/excludeDays`;
export const dailyChangesCell = `${urlBaseSuperAn}cell/dailyChanges/cell`;
export const dailyChangesDay = `${urlBaseSuperAn}cell/dailyChanges/day`;
export const dailyChangesParameters = `${urlBaseSuperAn}cell/dailyChanges/parameter`;
export const worksOrdersCell = `${urlBaseSuperAn}cell/worksOrder/cell`;
export const worksOrdersDay = `${urlBaseSuperAn}cell/worksOrder/day`;
export const worksOrdersParameters = `${urlBaseSuperAn}cell/worksOrder/parameter`;
export const dlearfcn = `${urlBaseSuperAn}cell/dlearfcn`;
export const dailyChanges = `${urlBaseSuperAn}cell/dailyChanges`;
export const worksOrders = `${urlBaseSuperAn}cell/worksOrder`;
export const cellHistory = `${urlBaseSuperAn}cell/history/`;
export const neighbors = `${urlBaseSuperAn}cell/neighbors/`;
export const neighborsHistory = `${urlBaseSuperAn}cell/neighbors/history/`;
export const cell_polluters = `${urlBaseSuperAn}cell/getPolluters`;
export const crowdsource = `${urlBaseSuperAn}cell/getCrowdsource`;
export const prop_delay = `${urlBaseSuperAn}cell/getPropDelay`;
export const tiers = `${urlBaseSuperAn}cell/getTier`;
export const login = `${urlBaseSuperAn}sign/login`;
export const ping = `${urlBaseSuperAn}sign/ping`;
export const registerUser = `${urlBaseSuperAn}sign/signup`;
export const getUsers = `${urlBaseSuperAn}user`;
export const checkToken = `${urlBaseSuperAn}user/me`;
export const changePassword = `${urlBaseSuperAn}user/password/change`;
export const adminChangeUserPassword = `${urlBaseSuperAn}user/password/change`;
export const deleteUser = `${urlBaseSuperAn}user`;
export const resetColumnVisible = `${urlBaseSuperAn}user/config/restore`;
export const codirectedNeighbors2G = `${urlBaseSuperAn}cell/codirected_neighbors_audit_2g`;
export const codirectedNeighbors5G = `${urlBaseSuperAn}cell/codirected_neighbors_audit_5g`;
export const completeRangeOptimization = `${urlBaseSuperAn}cell/range_optimization`;
export const rangeOptimizationWorkitems = `${urlBaseSuperAn}cell/range_optimization_workitems`;
export const missingNeighbors = `${urlBaseSuperAn}cell/missing_neighbors`;
export const missingNeighboursTables = `${urlBaseSuperAn}cell/missing_neighbors_tables`;
export const dlrsAdvanced = `${urlBaseSuperAn}cell/dlrs_advanced_boost`;
export const dlrsAdvancedDiscarded = `${urlBaseSuperAn}cell/dlrs_advanced_boost_discarded`;
export const coordinatesOvershooters = `${urlBaseSuperAn}cell/coordinates_and_overshooters_audit`;
export const trafficSteering = `${urlBaseSuperAn}cell/traffic_steering`;
export const downloadExcel = `${urlBaseSuperAn}cell/excel/file`;
export const downloadCSONInfo = `${urlBaseSuperAn}cell/pdf`;
export const excelsQueue = `${urlBaseSuperAn}user/queue`;
export const deleteExcelFromQueue = `${urlBaseSuperAn}user/queue`;
export const tablesConfiguration = `${urlBaseSuperAn}cell/tables_configuration`;
export const technologies = `${urlBaseSuperAn}cell/technologies`;
export const exportColumns = `${urlBaseSuperAn}cell/export_columns`;
export const importColumns = `${urlBaseSuperAn}cell/import_columns`;
