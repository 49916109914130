function urlWithParams(url, params) {
  if (params) {
    url = url + '?';
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        url = url + `${key}=${params[key]}&`;
      }
    }
  }
  return url;
}

export const apiGet = (url, params, signal) => () => {
  return fetch(urlWithParams(url, params), {
    method: 'GET',
    headers: localStorage.getItem('jwtToken')
      ? new Headers({
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        })
      : new Headers({ 'Content-type': 'application/json' }),
    signal: signal,
  })
    .then((v) => {
      if (v.status === 405) {
        let error = {
          error: {
            code: 405,
            message: 'Method not allowed',
          },
        };
        return error;
      } else {
        if (v.status === 401) {
          localStorage.removeItem('jwtToken');
          window.location.href = '/login';
          let error = {
            error: {
              code: 401,
              message: 'Unauthorized',
            },
          };
          return error;
        } else {
          return v.json();
        }
      }
    })
    .then((r) => {
      if (r.error) {
        return Promise.reject(r.error);
      }
      return r;
    })
    .catch((err) => {
      if (err.name === 'AbortError') {
        console.log('Request aborted:', err);
        return;
      } else if (err === 'TypeError: Failed to fetch') {
        return Promise.reject({ code: 500, message: 'Something went wrong' });
      } else {
        return Promise.reject(err);
      }
    });
};

export const apiPut = (url, obj, params) => () => {
  return fetch(urlWithParams(url, params), {
    method: 'PUT',
    body: JSON.stringify(obj),
    headers: localStorage.getItem('jwtToken')
      ? new Headers({
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        })
      : new Headers({ 'Content-type': 'application/json' }),
  })
    .then((v) => {
      if (v.status === 401) {
        localStorage.removeItem('jwtToken');
        window.location.href = '/login';
        let error = {
          error: {
            code: 401,
            message: 'Unhautorized',
          },
        };
        return error;
      } else {
        return v.text();
      }
    })
    .then((r) => {
      if (r.error) {
        return Promise.reject(r.error);
      }
      return (r = r.data);
    })
    .catch((err) => {
      if (err === 'TypeError: Failed to fetch') {
        return Promise.reject({ code: 500, message: 'Something was wrong' });
      } else {
        return Promise.reject(err);
      }
    });
};

export const apiPutFile = (url, obj, params) => () => {
  return fetch(urlWithParams(url, params), {
    method: 'PUT',
    body: obj,
    headers: localStorage.getItem('jwtToken')
      ? new Headers({
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        })
      : new Headers({ 'Content-type': 'application/json' }),
  })
    .then((v) => {
      localStorage.removeItem('jwtToken');
      if (v.status === 401) {
        let error = {
          error: {
            code: 401,
            message: 'Unhautorized',
          },
        };
        return error;
      } else {
        return v.json();
      }
    })
    .then((r) => {
      if (r.error) {
        return Promise.reject(r.error);
      }
      return (r = r.data);
    })
    .catch((err) => {
      if (err === 'TypeError: Failed to fetch') {
        return Promise.reject({ code: 500, message: 'Something was wrong' });
      } else {
        return Promise.reject(err);
      }
    });
};

export const apiPost = (url, obj, params) => () => {
  return fetch(urlWithParams(url, params), {
    method: 'POST',
    body: JSON.stringify(obj),
    headers: localStorage.getItem('jwtToken')
      ? new Headers({
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'Content-type': 'application/json',
        })
      : new Headers({ 'Content-type': 'application/json' }),
  })
    .then((v) => {
      if (v.status === 405) {
        let error = {
          error: {
            code: 404,
            message: v.json().error,
          },
        };
        return error;
      } else {
        if (v.status === 401) {
          localStorage.removeItem('jwtToken');
          window.location.href = '/login';
          let error = {
            error: {
              code: 401,
              message: 'Unhautorized',
            },
          };
          return error;
        } else {
          return v.json();
        }
      }
    })
    .then((r) => {
      if (r?.error) {
        return Promise.reject(r.error);
      }
      return r;
    })
    .catch((err) => {
      console.log(err);
      if (err === 'TypeError: Failed to fetch') {
        return Promise.reject({ code: 500, message: 'Something was wrong' });
      } else {
        return Promise.reject(err);
      }
    });
};

export const apiPostFile = (url, obj, params) => () => {
  return fetch(urlWithParams(url, params), {
    method: 'POST',
    body: JSON.stringify(obj),
    headers: localStorage.getItem('jwtToken')
      ? new Headers({
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'Content-type': 'application/json',
        })
      : new Headers({ 'Content-type': 'application/json' }),
  })
    .then((v) => {
      if (v.status === 405) {
        let error = {
          error: {
            code: 404,
            message: v.json().error,
          },
        };
        return error;
      } else {
        if (v.status === 401) {
          localStorage.removeItem('jwtToken');
          window.location.href = '/login';
          let error = {
            error: {
              code: 401,
              message: 'Unhautorized',
            },
          };
          return error;
        } else {
          return v.blob();
        }
      }
    })
    .then((r) => {
      if (r?.error) {
        return Promise.reject(r.error);
      }
      return r;
    })
    .catch((err) => {
      console.log(err);
      if (err === 'TypeError: Failed to fetch') {
        return Promise.reject({ code: 500, message: 'Something was wrong' });
      } else {
        return Promise.reject(err);
      }
    });
};

export const apiPostFileUpload = (url, formData, params) => () => {
  return fetch(urlWithParams(url, params), {
    method: 'POST',
    body: formData,
    headers: localStorage.getItem('jwtToken')
      ? new Headers({
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        })
      : undefined,
  })
    .then((v) => {
      if (v.status === 405) {
        let error = {
          error: {
            code: 404,
            message: v.json().error,
          },
        };
        return error;
      } else {
        if (v.status === 401) {
          localStorage.removeItem('jwtToken');
          window.location.href = '/login';
          let error = {
            error: {
              code: 401,
              message: 'Unhautorized',
            },
          };
          return error;
        } else {
          return v.json();
        }
      }
    })
    .then((r) => {
      if (r?.error) {
        return Promise.reject(r.error);
      }
      return r;
    })
    .catch((err) => {
      console.log(err);
      if (err === 'TypeError: Failed to fetch') {
        return Promise.reject({ code: 500, message: 'Something was wrong' });
      } else {
        return Promise.reject(err);
      }
    });
};

export const apiGetFile = (url, params) => () => {
  return fetch(urlWithParams(url, params), {
    method: 'GET',
    headers: localStorage.getItem('jwtToken')
      ? new Headers({
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'Content-type': 'application/json',
        })
      : new Headers({ 'Content-type': 'application/json' }),
  })
    .then((v) => {
      if (v.status === 405) {
        let error = {
          error: {
            code: 404,
            message: v.json().error,
          },
        };
        return error;
      } else {
        if (v.status === 401) {
          localStorage.removeItem('jwtToken');
          window.location.href = '/login';
          let error = {
            error: {
              code: 401,
              message: 'Unhautorized',
            },
          };
          return error;
        } else {
          const contentDisposition = v.headers.get('Content-Disposition');
          const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
          const filename = matches[1].replace(/['"]/g, ''); // Remove any surrounding quotes
          return v.blob().then((blob) => ({ blob, filename }));
        }
      }
    })
    .then((r) => {
      if (r?.error) {
        return Promise.reject(r.error);
      }
      return r;
    })
    .catch((err) => {
      console.log(err);
      if (err === 'TypeError: Failed to fetch') {
        return Promise.reject({ code: 500, message: 'Something was wrong' });
      } else {
        return Promise.reject(err);
      }
    });
};

export const apiDelete = (url, params) => () => {
  return fetch(urlWithParams(url, params), {
    method: 'DELETE',
    headers: localStorage.getItem('jwtToken')
      ? new Headers({
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        })
      : new Headers({ 'Content-type': 'application/json' }),
  })
    .then(async (v) => {
      if (v.status === 401) {
        localStorage.removeItem('jwtToken');
        window.location.href = '/login';
        let error = {
          error: {
            code: 401,
            message: 'Unhautorized',
          },
        };
        return error;
      } else {
        return v.json();
      }
    })
    .then((r) => {
      if (r.error) {
        return Promise.reject(r.error);
      }
      return r;
    })
    .catch((err) => {
      if (err === 'TypeError: Failed to fetch') {
        return Promise.reject({ code: 500, message: 'Something was wrong' });
      } else {
        return Promise.reject(err);
      }
    });
};
