import { useRef, useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { useTable, useFilters, useSortBy, useColumnOrder, usePagination } from 'react-table';
import { renderColumnFilter, fuzzyTextFilterFn } from './helpers';
import * as actions from '../../../actions';
import { NetworkMonitoringContext } from 'context';

export const HUAFILTER = 'HUA';
export const NOKFILTER = 'NOK';

const DEFAULT_COLUMNS_CONFIG = {
  isStriped: true,
  selectionLimit: 1,
  highlightSelection: false,
  autoSelection: false,
  isDisabled: false,
  expandColumns: false,
};

const DEFAULT_TOOLBAR_CONFIG = {
  showFilter: false,
  showExport: false,
  showRowNumber: false,
  showImportColumns: false,
  showExportColumns: false,
};

const DEFAULT_EXPORT_CONFIG = {
  excelName: 'export.xlsx',
  sheetName: 'Sheet1',
  columnsToSkip: [],
  dateRangeNeeded: false,
};

export const useFutureTableController = ({
  columnsConfig,
  toolbarConfig,
  exportConfig,
  placeholder,
  onFilterChange,
  data,
  columnVisible,
  defaultColumns,
  filterMap,
  fetchData,
  tableKey,
  controlledPageCount,
  updateVisibleColumns,
  sharedColumnCollapseData,
  updateSharedColumnCollapseData,
  paginationEnabled,
  excludeColumns,
  fetchParams,
  tableIdRequired,
  table_id,
}) => {
  const columnsOptions = columnsConfig ? { ...DEFAULT_COLUMNS_CONFIG, ...columnsConfig } : DEFAULT_COLUMNS_CONFIG;
  const toolbarOptions = toolbarConfig ? { ...DEFAULT_TOOLBAR_CONFIG, ...toolbarConfig } : DEFAULT_TOOLBAR_CONFIG;
  const exportOptions = exportConfig ? { ...DEFAULT_EXPORT_CONFIG, ...exportConfig } : DEFAULT_EXPORT_CONFIG;

  const filterRef = useRef({});
  const filterInnerRef = useRef({});
  const tableRef = useRef();

  const firstRender = useRef(true);

  const [isLoading, setIsLoading] = useState(false);

  const [expandedColumnIds, setExpandedColumnIds] = useState([]);
  const [expandedColumnWidth, setExpandedColumnWidth] = useState({});

  const [loadingColumnsSelection, setLoadingColumsSelection] = useState(false);

  const [huaFilter, setHuaFilter] = useState(false);
  const [nokFilter, setNokFilter] = useState(false);
  const [isExpandedAll, setIsExpandedAll] = useState(false);
  const initialFilterColumnCollapseData = () => {
    return Object.keys(sharedColumnCollapseData).length > 0 ? sharedColumnCollapseData : columnVisible;
  };
  const [filterColumnCollapseData, setFilterColumnCollapseData] = useState(initialFilterColumnCollapseData);

  const { technology } = useContext(NetworkMonitoringContext);

  useEffect(() => {
    if (updateSharedColumnCollapseData) {
      setFilterColumnCollapseData({ ...sharedColumnCollapseData });
    }
  }, [sharedColumnCollapseData, updateSharedColumnCollapseData]);

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, value) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(value).toLowerCase()) : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(() => {
    return {
      Filter: renderColumnFilter({
        placeholder,
        ref: filterRef,
        innerRef: filterInnerRef,
        disabled: false,
        onFilterChange,
      }),
      FilterReachable: renderColumnFilter({
        placeholder,
        ref: filterRef,
        innerRef: filterInnerRef,
        disabled: false,
        onFilterChange,
      }),
      DisabledFilter: renderColumnFilter({
        placeholder,
        innerRef: null,
        disabled: true,
        onFilterChange,
      }),
      DisabledFilterReachable: renderColumnFilter({
        placeholder,
        ref: filterRef,
        innerRef: filterInnerRef,
        disabled: true,
        onFilterChange,
      }),
    };
  }, [placeholder, columnsOptions.dynamicColumns, onFilterChange]);

  const { columns, toolbarFilter } = useMemo(() => {
    if (!columnsConfig.dynamicColumns && defaultColumns) {
      setIsLoading(true);
      const filteredColumns = defaultColumns.filter((item) => {
        if (excludeColumns) {
          return !excludeColumns.includes(item.accessor);
        }
        return item;
      });

      const calculatedColumns = filteredColumns.map((column) => ({
        ...column,
      }));

      const calculatedToolbarFilter = filteredColumns.reduce((toolbarPrev, toolbarNext) => {
        return [
          ...toolbarPrev,
          {
            id: toolbarNext.accessor,
            value: true,
            fixed: toolbarNext.fixed,
            label: toolbarNext.accessor
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
          },
        ];
      }, []);

      setIsLoading(false);

      return {
        columns: calculatedColumns,
        toolbarFilter: calculatedToolbarFilter,
      };
    }
    setIsLoading(false);
    return { columns: [], toolbarFilter: [] };
  }, [defaultColumns, columnsConfig.dynamicColumns, excludeColumns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setColumnOrder,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    setAllFilters,
    state: { pageIndex, pageSize, filters, sortBy },
  } = useTable(
    {
      data,
      filterTypes,
      defaultColumn,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 100,
        filters: filterMap,
        sortBy: [],
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
      manualFilters: paginationEnabled,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useColumnOrder,
    usePagination
  );

  useEffect(() => {
    setAllFilters([]);
  }, [technology]);

  const prevSortBy = useRef(sortBy);

  useEffect(() => {
    if (filterMap.length > 0) {
      filterMap.forEach((filter) => {
        setFilter(filter.id, filter.value);
      });
    }
  }, [filterMap]);

  const parsedFilters = useMemo(() => {
    if (!paginationEnabled) return {};
    let parsedFilters = {};
    filters.forEach((filter) => {
      if (filter.id && (filter.value || filter.value === '')) {
        parsedFilters[filter.id] = filter.value;
      }
    });
    return parsedFilters;
  }, [filters]);

  const prevFilters = useRef(parsedFilters);

  const fetchAndUpdateData = useCallback(async () => {
    if (!fetchData || (tableIdRequired && !table_id)) return;

    const sortingParams = {
      queryOrderColumn: sortBy[0]?.id,
      queryOrder: sortBy[0]?.desc ? 'desc' : 'asc',
    };

    const fetchDataParams = {
      tableKey,
      pageSize,
      setLoading: setIsLoading,
      filters: parsedFilters,
      sorting: sortingParams,
      ...fetchParams,
    };

    const tableIdParams = table_id ? { table_id } : {};

    try {
      if (
        firstRender.current ||
        JSON.stringify(prevSortBy.current) !== JSON.stringify(sortBy) ||
        JSON.stringify(prevFilters.current) !== JSON.stringify(parsedFilters)
      ) {
        fetchData({ ...fetchDataParams, ...tableIdParams, pageIndex: 0 });
        prevSortBy.current = sortBy;
        prevFilters.current = parsedFilters;
        gotoPage(0);
        firstRender.current = false;
      }
    } catch (err) {
      console.log(err);
    }
  }, [pageIndex, pageSize, parsedFilters, sortBy]);

  useEffect(() => {
    if (paginationEnabled && fetchData && !firstRender.current) {
      const sortingParams = {
        queryOrderColumn: sortBy[0]?.id,
        queryOrder: sortBy[0]?.desc ? 'desc' : 'asc',
      };

      const fetchDataParams = {
        tableKey,
        pageSize,
        setLoading: setIsLoading,
        filters: parsedFilters,
        sorting: sortingParams,
        ...fetchParams,
      };

      const tableIdParams = table_id ? { table_id } : {};

      try {
        fetchData({ ...fetchDataParams, ...tableIdParams, pageIndex });
      } catch (err) {
        console.log(err);
      }
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    fetchAndUpdateData();
  }, [fetchAndUpdateData]);

  useEffect(() => {
    if (table_id) {
      gotoPage(0);
    }
  }, [table_id]);

  const [visibleColumns, setVisibleColumns] = useState([]);

  const handleVendorSelector = async (filter, state, shouldUpdate = true) => {
    if (updateVisibleColumns && updateSharedColumnCollapseData) {
      setLoadingColumsSelection(true);
      try {
        const collapseKeys = Object.keys(sharedColumnCollapseData).filter((key) => key.includes(filter));

        collapseKeys.forEach((mainKey) => {
          const keysToUpdate = Object.keys(sharedColumnCollapseData[mainKey]);
          keysToUpdate.forEach((key) => {
            sharedColumnCollapseData[mainKey][key] = state;
          });
        });

        if (shouldUpdate) {
          await actions.postCellsConfiguration(sharedColumnCollapseData, { tech_id: technology }).then(() => {
            updateVisibleColumns();
            updateSharedColumnCollapseData(sharedColumnCollapseData);
          });
        }

        if (filter === HUAFILTER) {
          setHuaFilter(state);
        } else if (filter === NOKFILTER) {
          setNokFilter(state);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingColumsSelection(false);
      }
    }
  };

  const handleVisibleColumnsCollapseChange = async (event, columnState, filteredColumns) => {
    if (updateVisibleColumns && updateSharedColumnCollapseData) {
      const collapseKey = event.target.id;
      setLoadingColumsSelection(true);

      try {
        const keysToUpdate = Object.keys(filteredColumns[collapseKey]);
        keysToUpdate.forEach((key) => {
          sharedColumnCollapseData[collapseKey][key] = !columnState;
          columnState && setFilter(key, '');
        });

        const updatedColumns = await actions.postCellsConfiguration(sharedColumnCollapseData, { tech_id: technology });

        if (updatedColumns === null) {
          const updatedSharedColumnCollapseData = await actions.getCellsConfiguration({ tech_id: technology });
          updateVisibleColumns();
          updateSharedColumnCollapseData(updatedSharedColumnCollapseData.data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingColumsSelection(false);
      }
    }
  };

  const handleVisibleColumnsChange = async (event, collapseKey) => {
    const columnId = event.target.id;
    const updateColumnValue = !sharedColumnCollapseData[collapseKey][columnId];
    if (updateVisibleColumns && updateSharedColumnCollapseData) {
      try {
        setLoadingColumsSelection(true);
        sharedColumnCollapseData[collapseKey][columnId] = updateColumnValue;
        const updatedColumns = await actions.postCellsConfiguration(sharedColumnCollapseData, { tech_id: technology });
        !updateColumnValue && setFilter(columnId, '');
        if (updatedColumns === null) {
          const updatedSharedColumnCollapseData = await actions.getCellsConfiguration({ tech_id: technology });
          updateSharedColumnCollapseData(updatedSharedColumnCollapseData.data);
          updateVisibleColumns();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingColumsSelection(false);
      }
    } else {
      setVisibleColumns(updateColumnValue);
    }
  };

  useEffect(() => {
    if (!!sharedColumnCollapseData && updateSharedColumnCollapseData && fetchData) {
      const sortingParams = {
        queryOrderColumn: sortBy[0]?.id,
        queryOrder: sortBy[0]?.desc ? 'desc' : 'asc',
      };

      const fetchDataParams = {
        tableKey,
        pageSize,
        setLoading: setIsLoading,
        filters: parsedFilters,
        sorting: sortingParams,
        ...fetchParams,
      };

      const tableIdParams = table_id ? { table_id } : {};

      try {
        fetchData({ ...fetchDataParams, ...tableIdParams, pageIndex });
      } catch (err) {
        console.log(err);
      }
    }
  }, [sharedColumnCollapseData]);

  useEffect(() => {
    setVisibleColumns(toolbarFilter);
  }, [toolbarFilter]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filterRef.current && filters && filters.length > 0) {
        filters.forEach(({ id, value }) => {
          const filter = filterRef.current[id];
          if (filter) {
            filter.props.onChange({
              target: {
                value: value,
              },
            });

            const filterInner = filterInnerRef.current[id];
            if (filterInner) {
              filterInner.value = value;
            }
          }
        });
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [filters, filterRef, data]);

  useEffect(() => {
    if (columns) {
      const columnOrder = columns
        .sort((a, b) => {
          if (a.fixed && !b.fixed) {
            return -1;
          }
          if (b.fixed && !a.fixed) {
            return 1;
          }
          return 0;
        })
        .map((c) => c.accessor);
      setColumnOrder(columnOrder);
    }
  }, [columns, setColumnOrder]);

  const checkSearchIsEmpty = () => {
    if (data.length !== 1) return false;
    return Object.keys(data[0]).every((key) => data[0][key] === null);
  };

  const handleExpandColumn = (columnId) => {
    const isColumnExpanded = expandedColumnIds.includes(columnId);
    const updatedColumnsIds = isColumnExpanded ? expandedColumnIds.filter((id) => id !== columnId) : [...expandedColumnIds, columnId];
    setExpandedColumnIds(updatedColumnsIds);

    if (!isColumnExpanded) {
      setExpandedColumnWidth((prevWidths) => ({ ...prevWidths, [columnId]: 200 }));
    } else {
      setExpandedColumnWidth((prevWidths) => {
        const updatedWidths = { ...prevWidths };
        delete updatedWidths[columnId];
        return updatedWidths;
      });
    }
  };

  useEffect(() => {
    const expandAll = () => {
      const updateColumnsIds = Object.keys(data[0]);
      setExpandedColumnIds(updateColumnsIds);

      const updatedColumnsWidths = Object.keys(data[0]).reduce((acc, curr) => {
        acc[curr] = 200;
        return acc;
      }, {});
      setExpandedColumnWidth(updatedColumnsWidths);
    };

    if (isExpandedAll && data.length > 0) {
      expandAll();
    } else {
      setExpandedColumnIds([]);
      setExpandedColumnWidth({});
    }
  }, [isExpandedAll, data]);

  const handleExpandAll = () => {
    setIsExpandedAll(!isExpandedAll);
  };
  const [fixedColsPositions, setFixedColsPositions] = useState({});

  useEffect(() => {
    const fixedColumns = columns.filter((column) => column.fixed);
    let position = 0;
    const newFixedColsPositions = {};
    for (const column of fixedColumns) {
      newFixedColsPositions[column.accessor] = position;
      if (expandedColumnWidth[column.accessor]) {
        position += expandedColumnWidth[column.accessor];
      } else {
        position += 200;
      }
    }
    setFixedColsPositions(newFixedColsPositions);
  }, [expandedColumnWidth, columns, expandedColumnIds]);

  return {
    tableRef,
    columnsOptions,
    toolbarOptions,
    exportOptions: { ...exportOptions, parsedFilters: parsedFilters },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns: visibleColumns,
    handleVisibleColumnsChange,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex,
    pageSize,
    isLoading,
    setIsLoading,
    searchIsEmpty: checkSearchIsEmpty(),
    handleVisibleColumnsCollapseChange,
    expandedColumnIds,
    handleExpandColumn,
    expandedColumnWidth,
    setExpandedColumnWidth,
    loadingColumnsSelection,
    huaFilter,
    nokFilter,
    handleVendorSelector,
    handleExpandAll,
    isExpandedAll,
    fixedColsPositions,
    filterColumnCollapseData,
    setAllFilters,
  };
};
