/** @jsxImportSource @emotion/react */
import { Row, Col, Input, Button } from 'reactstrap';
import { styles } from './Filters.styles';
import Card from 'components/Card';
import { useFilterController } from './Filters.controller';
import { GlobalContext } from 'context';
import { useContext } from 'react';

export const Filters = ({ selectionRange, handleDateSelector, handleClear }) => {
  const { isActiveButton, isDisabledButton, handleTechnologyButton } = useFilterController();
  const { technologies } = useContext(GlobalContext);

  return (
    <Col xs={12} md={12}>
      <Card className="card-stats" bodyHeight="auto">
        <Row css={styles.row} id="tabs-row">
          <Col xs={4} md={4}>
            <div css={styles.container}>
              <div className={'icon'} css={styles.iconContainer({ color: '#b10279' })}>
                <i className="fas fa-calendar-alt" css={styles.icon} />
              </div>
              <div onClick={handleDateSelector} style={styles.inputContainer}>
                <Input
                  id="dateRangeInput"
                  name="text"
                  placeholder="Select a date range"
                  value={`${selectionRange?.startDateFormat} - ${selectionRange?.endDateFormat}`}
                  readOnly
                  css={styles.dateInput}
                />
              </div>
            </div>
          </Col>
          <Col xs={4} md={4} css={styles.container}>
            {technologies.map((button) => (
              <Button
                key={button.tech_id}
                active={isActiveButton(button)}
                color="future"
                onClick={() => handleTechnologyButton(button)}
                disabled={isDisabledButton(button)}
              >
                {button.tech}
              </Button>
            ))}
            <Button
              active={isActiveButton({ tech_id: null })}
              color="future"
              onClick={() => handleTechnologyButton({ tech_id: null })}
              disabled={isDisabledButton({ tech_id: null })}
            >
              All
            </Button>
          </Col>
          <Col xs={4} md={4}>
            <div css={styles.container}>
              <div className={'icon'} css={styles.iconContainer({ color: '#f81253', pointer: true })} onClick={handleClear}>
                <i className="fas fa-broom" css={styles.icon} title="Reset filters" />
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
