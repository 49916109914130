import { useContext, useEffect } from 'react';
import * as actions from 'actions';
import { useFetchData } from 'hooks/useFetchData';
import { useFetchDynamicCols } from 'hooks/useFetchDynamicCols';
import { GlobalContext } from 'context';

export const useRangeOptimizationController = () => {
  const { tablesConfig } = useContext(GlobalContext);

  const table1Config = tablesConfig[25] || {};
  const table2Config = tablesConfig[26] || {};

  const {
    pageData: table1Data,
    pageCount: table1ControlledPage,
    fetchData: fetchTable1Data,
    setTableKey: setTable1TableKey,
  } = useFetchData(actions.rangeOptimizationWorkitems);

  const {
    pageData: table2Data,
    pageCount: table2ControlledPageCount,
    fetchData: fetchTable2Data,
    setTableKey: setTable2TableKey,
  } = useFetchData(actions.completeRangeOptimization);

  const { dynamicCols, dynamicColsLoading } = useFetchDynamicCols([table1Config.table_id, table2Config.table_id]);

  useEffect(() => {
    setTable1TableKey(table1Config.table_id);
    setTable2TableKey(table2Config.table_id);
  }, [setTable1TableKey, setTable2TableKey, table1Config.table_id, table2Config.table_id]);

  return {
    controlledPageCount: {
      [table1Config.table_id]: table1ControlledPage[table1Config.table_id],
      [table2Config.table_id]: table2ControlledPageCount[table2Config.table_id],
    },
    defaultColumns: dynamicCols,
    dynamicColsLoading,
    fetchTable1Data,
    fetchTable2Data,
    table1Data,
    table2Data,
    table1Config,
    table2Config,
  };
};
