/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { useNetworkMonitoringController } from './NetworkMonitoring.controller';
import CellConfiguration from './components/CellConfiguration';
import TabSelector from 'components/TabSelector';
import NetworkChanges from './components/NetworkChanges';
import WorksHistory from './components/WorksHistory';
import Popup from 'reactjs-popup';
import Card from 'components/Card';
import { DateRangePicker } from 'react-date-range';
import { FormGroup, Button } from 'reactstrap';
import { styles } from './NetworkMonitoring.styles';
import { NetworkMonitoringContext } from 'context';
import Filters from './components/Filters';

const NetworkMonitoring = () => {
  const {
    baseLayers,
    table2Loading,
    table1Loading,
    table4Loading,
    table3Loading,
    resetFilters,
    fetchDateParams,
    fetchParamsTable2,
    fetchTable1Data,
    fetchTable1DynamicCols,
    fetchTable2Data,
    fetchTable3Data,
    fetchTable4Data,
    fetchTable5Data,
    fetchTable6Data,
    getTableConfig,
    graphLoading,
    handleClickCellRow,
    handleClickDateRange,
    handleResetColumnVisible,
    handleSelectDateRange,
    loading,
    mapLayers,
    maxCalendarDate,
    onClosePopup,
    onClickDateRange,
    pieChartOuterRadius,
    popup,
    renderCustomizedLabel,
    table1Config,
    table1DynamicColsLoading,
    table2Config,
    table3Config,
    table4Config,
    table5Config,
    table6Config,
    table5Loading,
    table6Loading,
    tabOptions,
    tempSelectionRange,
    updateVisibleColumns,
    view,
    zoomHandler,
    zoomThreshold,
  } = useNetworkMonitoringController();

  const { selectionRange, activeTab, setActiveTab } = useContext(NetworkMonitoringContext);

  const renderTab = (activeTab) => {
    switch (activeTab) {
      case 'CELL_CONFIG':
        return (
          <CellConfiguration
            fetchTable1Data={fetchTable1Data}
            fetchTable2Data={fetchTable2Data}
            fetchParamsTable2={fetchParamsTable2}
            baseLayers={baseLayers}
            mapLayers={mapLayers}
            zoomHandler={zoomHandler}
            view={view}
            zoomThreshold={zoomThreshold}
            loading={loading}
            fetchDynamicCols={fetchTable1DynamicCols}
            handleResetColumnVisible={handleResetColumnVisible}
            dynamicColsLoading={table1DynamicColsLoading}
            handleClickCellRow={handleClickCellRow}
            updateVisibleColumns={updateVisibleColumns}
            table2Loading={table2Loading}
            table1Loading={table1Loading}
            getTableConfig={getTableConfig}
            table1Config={table1Config}
            table2Config={table2Config}
          />
        );
      case 'NETWORK_CHANGES':
        return (
          <NetworkChanges
            fetchTable3Data={fetchTable3Data}
            fetchTable4Data={fetchTable4Data}
            fetchDateParams={fetchDateParams}
            getTableConfig={getTableConfig}
            table3Config={table3Config}
            table4Config={table4Config}
            table4Loading={table4Loading}
            table3Loading={table3Loading}
            graphLoading={graphLoading}
            table5Loading={table5Loading}
            table6Loading={table6Loading}
            pieChartOuterRadius={pieChartOuterRadius}
            renderCustomizedLabel={renderCustomizedLabel}
          />
        );
      case 'WORK_ITEMS':
        return (
          <WorksHistory
            fetchTable5Data={fetchTable5Data}
            fetchTable6Data={fetchTable6Data}
            fetchDateParams={fetchDateParams}
            table5Config={table5Config}
            table6Config={table6Config}
            graphLoading={graphLoading}
            pieChartOuterRadius={pieChartOuterRadius}
            renderCustomizedLabel={renderCustomizedLabel}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Popup
        className="popup-auto"
        open={popup.dateRange}
        closeOnDocumentClick
        onClose={onClosePopup}
        contentStyle={styles.popupContent}
        overlayStyle={styles.dataModalOverlay}
      >
        <Card bodyHeight="auto">
          <DateRangePicker ranges={[tempSelectionRange]} onChange={handleSelectDateRange} maxDate={maxCalendarDate} />
          <FormGroup style={{ textAlign: 'right', marginRight: '0.5rem' }}>
            <Button color="future" onClick={handleClickDateRange}>
              Select
            </Button>
          </FormGroup>
        </Card>
      </Popup>
      <Filters selectionRange={selectionRange} handleDateSelector={onClickDateRange} handleClear={resetFilters} />
      <TabSelector options={tabOptions} selected={activeTab} onChange={setActiveTab} showScrollIcons />
      {renderTab(activeTab)}
    </>
  );
};

export default NetworkMonitoring;
