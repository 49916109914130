import { AppLayout } from '@reddieoffice/reddie_react_lib';
import { useContext } from 'react';
import { useLayoutController } from './Layout.controller';
import { GlobalContext } from 'context';

const Layout = ({ children, routeList, activeRouteName }) => {
  const { username } = useContext(GlobalContext);

  const { handleChangePassword, logOut } = useLayoutController();

  const user = {
    name: username,
    user_id: Math.floor(Math.random() * 1000) + 1,
    email: username,
  };

  return (
    <AppLayout
      user={user}
      routeList={routeList}
      isFooterFluid
      activeRouteName={activeRouteName}
      handleLogOut={logOut}
      handleChangePassword={handleChangePassword}
    >
      {children}
    </AppLayout>
  );
};

export { Layout };
