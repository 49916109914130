import { createContext, useCallback, useMemo, useReducer } from 'react';
import { NetworkMonitoringReducer } from './NetworkMonitoringReducer';
import { selectionRangeDefault } from 'views/NetworkMonitoring/constants';

const CONTEXT_INITIAL_STATE = {
  table1: { total: 0, data: [] },
  table2: { total: 0, data: [] },
  table3: { total: 0, data: [] },
  table4: { total: 0, data: [] },
  table5: { total: 0, data: [] },
  table6: { total: 0, data: [] },
  dynamicColumns: {
    table1: [],
    table2: [],
    table3: [],
    table4: [],
    table5: [],
    table6: [],
  },
  controlledPageCount: {
    table1: 0,
    table2: 0,
    table3: 0,
    table4: 0,
    table5: 0,
    table6: 0,
  },
  cellSelected: { table1: '', table2: '', table4: '', table6: '' },
  parameterSelected: { table4: '', table6: '' },
  mapCellSelected: '',
  selectionRange: selectionRangeDefault,
  sharedColumnCollapseData: {},
  chartsData: {
    barChanges: [],
    barWorks: [],
    pieChanges: { data: [], colors: [] },
    pieWorks: { data: [], colors: [] },
  },
  technology: 1,
  mapTechSelection: { 1: true, 2: false, 3: false },
  activeTab: 'CELL_CONFIG',
  filterResetKey: 0,
};

export const NetworkMonitoringContext = createContext({});

export const NetworkMonitoringProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NetworkMonitoringReducer, CONTEXT_INITIAL_STATE);

  const setPageData = useCallback(({ page, data }) => {
    dispatch({ type: 'SET_PAGE_DATA', payload: { page, data } });
  }, []);

  const setDynamicColumns = useCallback(({ table, data }) => {
    dispatch({ type: 'SET_DYNAMIC_COLUMNS', payload: { table, data } });
  }, []);

  const setFilterResetKey = useCallback(() => {
    dispatch({ type: 'RESET_FILTERS' });
  }, []);

  const setControlledPageCount = useCallback(({ table, count }) => {
    dispatch({ type: 'SET_CONTROLLED_PAGE_COUNT', payload: { table, count } });
  }, []);

  const setCellSelected = useCallback(({ table, cellName }) => {
    dispatch({ type: 'SET_CELL_SELECTED', payload: { table, cellName } });
  }, []);

  const setParameterSelected = useCallback(({ table, parameterName }) => {
    dispatch({ type: 'SET_PARAMETER_SELECTED', payload: { table, parameterName } });
  }, []);

  const setMapCellSelected = useCallback(({ cellName }) => {
    dispatch({ type: 'SET_MAP_SELECTED', payload: cellName });
  }, []);

  const setAllCellSelected = useCallback((cellName) => {
    dispatch({ type: 'SET_ALL_CELL_SELECTED', payload: cellName });
  }, []);

  const setAllParameterSelected = useCallback((parameterName) => {
    dispatch({ type: 'SET_ALL_PARAMETER_SELECTED', payload: parameterName });
  }, []);

  const setSelectionRange = useCallback((range) => {
    dispatch({ type: 'SET_SELECTION_RANGE', payload: range });
  }, []);

  const setChartsData = useCallback((charts) => {
    dispatch({ type: 'SET_CHARTS_DATA', payload: charts });
  }, []);

  const setSharedColumnCollapseData = useCallback((data) => {
    dispatch({ type: 'SET_SHARED_COLUMN_COLLAPSE_DATA', payload: data });
  }, []);

  const setTechnology = useCallback((technology) => {
    dispatch({ type: 'SET_TECHNOLOGY', payload: technology });
  }, []);

  const setActiveTab = useCallback((tab) => {
    dispatch({ type: 'SET_ACTIVE_TAB', payload: tab });
  }, []);

  const setMapTechSelection = useCallback((selection) => {
    dispatch({ type: 'SET_MAP_TECH_SELECTION', payload: selection });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setPageData,
      setDynamicColumns,
      setControlledPageCount,
      setCellSelected,
      setParameterSelected,
      setAllCellSelected,
      setAllParameterSelected,
      setSelectionRange,
      setSharedColumnCollapseData,
      setChartsData,
      setTechnology,
      setActiveTab,
      setMapTechSelection,
      setFilterResetKey,
      setMapCellSelected,
    }),
    [
      state,
      setPageData,
      setDynamicColumns,
      setControlledPageCount,
      setCellSelected,
      setParameterSelected,
      setAllCellSelected,
      setAllParameterSelected,
      setSelectionRange,
      setSharedColumnCollapseData,
      setChartsData,
      setTechnology,
      setActiveTab,
      setMapTechSelection,
      setFilterResetKey,
      setMapCellSelected,
    ]
  );

  return <NetworkMonitoringContext.Provider value={contextValue}>{children}</NetworkMonitoringContext.Provider>;
};
