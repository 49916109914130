import { useTrafficSteeringController } from './TrafficSteering.controller';
import { FutureTable } from 'components/Table/FutureTable';
import Card from 'components/Card';
import { Col } from 'reactstrap';

export const TrafficSteering = () => {
  const { pageData, controlledPageCount, fetchData, defaultColumns, dynamicColsLoading, tableConfig } = useTrafficSteeringController();

  return (
    <Col>
      <Card title={tableConfig?.table_title} className={pageData.data?.length === 0 ? 'card-7' : ''} isLoading={dynamicColsLoading}>
        <FutureTable
          tableKey={tableConfig?.table_id}
          data={pageData.data || []}
          defaultColumns={defaultColumns[tableConfig?.table_id]}
          columnsConfig={{
            isStriped: false,
            dynamicColumns: false,
            expandColumns: true,
          }}
          toolbarConfig={{
            showExport: true,
            showRowNumber: true,
          }}
          paginationEnabled={true}
          exportConfig={{ id: tableConfig?.table_id, excelName: tableConfig?.file_name, sheetName: tableConfig?.file_name }}
          pagData={pageData}
          fetchData={fetchData}
          controlledPageCount={controlledPageCount[tableConfig?.table_id]}
          table_id={tableConfig?.table_id}
        />
      </Card>
    </Col>
  );
};
