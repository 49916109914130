/** @jsxImportSource @emotion/react */
import { StyledWrapper, styles } from './Spinner.styles';
import { HashLoader } from 'react-spinners';

export const Spinner = ({ height, overlay }) => {
  return (
    <StyledWrapper css={styles.wrapper(height, overlay)}>
      <HashLoader
        className="loader"
        color="#e73357"
        cssOverride={{
          color: 'rgb(231, 51, 87)',
          opacity: 0.7,
          position: 'absolute',
          top: '40%',
          transform: 'translate(-50%, -50%), rotate(20)',
        }}
        size={120}
      />
    </StyledWrapper>
  );
};
