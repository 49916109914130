import React from 'react';

// reactstrap components
import { Table } from 'reactstrap';

function SimpleTable({ columns, data }) {
  return (
    <Table responsive>
      <thead className="text-primary">
        <tr>
          {columns.map((prop, key) => {
            if (key === columns.length - 1)
              return (
                <th key={key} className="text-right">
                  {prop}
                </th>
              );
            return <th key={key}>{prop}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((prop, key) => {
          return (
            <tr key={key}>
              {prop.data.map((prop, key) => {
                if (key === columns.length - 1)
                  return (
                    <td key={key} className="text-right">
                      {prop}
                    </td>
                  );
                return <td key={key}>{prop}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default SimpleTable;
