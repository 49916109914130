import { useCallback } from 'react';
import * as actions from 'actions';
import { showChangePasswordAlert, showSuccessAlert, showErrorAlert } from 'utils';

export const useLayoutController = () => {
  const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('jwtToken');
    window.location = '/login';
  };

  const callbackAsyncFn = useCallback(async () => {
    const alertValues = {
      currentPassword: document.getElementById('currentPassword')?.value,
      newPassword: document.getElementById('newPassword')?.value,
    };
    try {
      await actions
        .changePassword({
          old_password: alertValues.currentPassword,
          password: alertValues.newPassword,
          confirm_password: alertValues.newPassword,
        })
        .then((response) => {
          showSuccessAlert({
            title: response.message,
            onClose: logOut,
          });
        })
        .catch((error) => {
          showErrorAlert({ title: 'Error', text: error.message });
        });
    } catch (error) {
      showErrorAlert({ title: 'Error', text: error.message });
    }
  }, []);

  const handleChangePassword = () => showChangePasswordAlert({ callbackAsyncFn });

  return {
    logOut,
    handleChangePassword,
  };
};
