/* eslint-disable no-unused-vars */

const propDelayRangeLimit = [
  [0, 0],
  [0, 10],
  [10, 20],
  [20, 30],
  [30, 40],
  [40, 50],
  [50, 100],
];

let opacityForPD = [];
let propDelayLegend = [];
let range = [];
let str = '';
let opacity = 0;
var x = 0;

for (x = 0; x < propDelayRangeLimit.length; x++) {
  range = propDelayRangeLimit[x];
  if (range[0] === range[1]) {
    str = range[0] + '%';
  } else {
    str = range[0] + '% - ' + range[1] + '%';
  }

  opacity = (range[0] + range[1]) / 100;

  propDelayLegend.push(str);
  opacityForPD.push(opacity);
}

const propDelayStyle = {
  propDelayRangeLimit: propDelayRangeLimit,
  propDelayLegend: propDelayLegend,
  color: 'green',
  borderColor: '#9C9C9C',
  color4g: '#006699',
  borderColor4g: '#9C9C9C',
  opacity: opacityForPD,
  fillOpacity: 0.7,
  weight: 1,
};

const agentsRangeLimit = [
  [-130, -117],
  [-117, -107],
  [-107, -97],
  [-97, -87],
  [-87, 0],
];

let legendForAgents = [];
range = [];
str = '';

for (x = 0; x < agentsRangeLimit.length; x++) {
  range = agentsRangeLimit[x];
  if (range[0] === range[1]) {
    str = range[0] + 'dBm';
  } else {
    str = range[0] + 'dBm to ' + range[1] + 'dBm';
  }

  legendForAgents.push(str);
}

const agentsStyle = {
  colorsForAgents: ['#198946', '#516A36', '#8B4A25', '#CB2713', '#FF0A05'],
  agentsRangeLimit: agentsRangeLimit,
  legendForAgents: legendForAgents,
  borderColor: '#000000',
  opacity: 0,
  fillOpacity: 0.7,
  weight: 1,
  radiusMin: 3,
  radiusMax: 10,
};

const MAP_DEBOUNCE_TIME = 1500;

module.exports = {
  env: process.env.NODE_ENV || 'development',
  //Initial map zoom
  propDelayStyle: propDelayStyle,
  agentsStyle: agentsStyle,
  // colorDlearfcn: ['#DBC461', '#BE3455', '#348796', '#4F7942', '#FB700E', '#AE6CAE'],
  colorDlearfcn: ['#e5f7ff', '#b3e6ff', '#80d5ff', '#4dc4ff', '#1ab3ff', '#0099e6'],
  // specify popup options
  popupOptions: { className: 'custom', maxWidth: '500', autoPan: false },
  // specify debounce time for map
  mapDebounceTime: MAP_DEBOUNCE_TIME,
};
