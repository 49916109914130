import { useCallback, useEffect, useRef, useState } from 'react';
import * as actions from 'actions';

export const useFetchDynamicCols = (tablesIds) => {
  const [dynamicCols, setDynamicCols] = useState([]);
  const [dynamicColsLoading, setDynamicColsLoading] = useState(false);
  const [dynamicColsError, setDynamicColsError] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const tablesIdsRef = useRef(tablesIds);

  const fetchDynamicCols = useCallback(async () => {
    if (tablesIdsRef.current[0] === undefined) return;

    setDynamicColsLoading(true);
    try {
      const results = await Promise.all(tablesIdsRef.current.map((tableId) => actions.cellColumns({ id: `${tableId}` })));
      const newDynamicCols = results.reduce((cols, { data }, index) => {
        cols[tablesIdsRef.current[index]] = data;
        return cols;
      }, {});
      if (isMounted.current) {
        setDynamicCols(newDynamicCols);
      }
    } catch (error) {
      setDynamicColsError(error);
    }
    setDynamicColsLoading(false);
  }, [tablesIdsRef]);

  useEffect(() => {
    fetchDynamicCols();
  }, [fetchDynamicCols]);

  useEffect(() => {
    if (JSON.stringify(tablesIdsRef.current) !== JSON.stringify(tablesIds)) {
      tablesIdsRef.current = tablesIds;
      fetchDynamicCols();
    }
  }, [tablesIds, fetchDynamicCols]);

  return {
    dynamicCols,
    dynamicColsLoading,
    dynamicColsError,
    fetchDynamicCols,
  };
};
