import { useFetchDynamicCols } from 'hooks/useFetchDynamicCols';
import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import {
  tabOptions,
  selectionRangeDefault,
  mapVoyagerUrl,
  colorDlearfcnLTE,
  colorDlnarfcnNR,
  colorDlearfcnGSM,
  actionsList,
  defaultCell,
  defaultWeek,
  defaultTech,
  defaultCenter,
  defaultZoom,
  defaultBbox,
  zoomThreshold,
} from './constants';
import { NetworkMonitoringContext } from 'context';
import { useFetchData } from 'hooks/useFetchData';
import * as actions from 'actions';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import * as utilsCharts from 'utils/charts';
import { useLocation } from 'react-router-dom';
import * as mapBuilder from 'utils/mapBuilder';
import _ from 'lodash';
import { GlobalContext } from 'context';
import { showErrorAlert } from 'utils';

export const useNetworkMonitoringController = () => {
  const zoomLevel = useRef(13);
  const [view, setView] = useState({ latLong: defaultCenter, zoom: defaultZoom });
  const [dlearfcn, setDlearfcn] = useState({});
  const baseLayers = useRef(undefined);
  const mapLayers = useRef({ base: [], others: [] });
  const mapBounds = useRef(defaultBbox);
  const tech = defaultTech;
  const [prevSelectedCell, setPrevSelectedCell] = useState('');
  const [loading, setLoading] = useState(false);
  const [cellMap, setCellMap] = useState({ cell_name: '', site_name: '', sector: '', tech: '', action: '' });
  const location = useLocation();
  const initialRender = useRef(true);
  const week = defaultWeek;
  const [reloadMap, setReloadMap] = useState(false);
  const [popup, setPopup] = useState({ dateRange: false, cellInfoHistory: false });
  const [tempSelectionRange, setTempSelectionRange] = useState(selectionRangeDefault);
  const [table1Loading, setTable1Loading] = useState(false);
  const [table2Loading, setTable2Loading] = useState(false);
  const [table3Loading, setTable3Loading] = useState(false);
  const [table4Loading, setTable4Loading] = useState(false);
  const [table5Loading, setTable5Loading] = useState(false);
  const [table6Loading, setTable6Loading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);

  let pieChartOuterRadius = 200;

  if (window.innerWidth <= 1024) {
    pieChartOuterRadius = 60;
  } else if (window.innerWidth <= 1366) {
    pieChartOuterRadius = 120;
  } else if (window.innerWidth <= 1440) {
    pieChartOuterRadius = 160;
  } else if (window.innerWidth <= 1920) {
    pieChartOuterRadius = 180;
  }

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.9;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const labelsFontSize = () => {
      if (window.innerWidth <= 1024) {
        return '12px';
      } else if (window.innerWidth <= 1440) {
        return '18px';
      } else if (window.innerWidth <= 1920) {
        return '20px';
      } else {
        return '24px';
      }
    };

    return (
      <>
        <text
          x={x}
          y={y}
          fill="black"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          style={{
            fontSize: labelsFontSize(),
          }}
        >
          {value}
        </text>
      </>
    );
  };

  const {
    activeTab,
    cellSelected,
    mapCellSelected,
    mapTechSelection,
    selectionRange,
    setActiveTab,
    setAllCellSelected,
    setAllParameterSelected,
    setCellSelected,
    setChartsData,
    setDynamicColumns,
    setFilterResetKey,
    setMapCellSelected,
    setMapTechSelection,
    setPageData,
    setSelectionRange,
    setSharedColumnCollapseData,
    setTechnology,
    setControlledPageCount,
    technology,
  } = useContext(NetworkMonitoringContext);

  const { tablesConfig } = useContext(GlobalContext);

  const getTableConfig = (groupId) => {
    for (const key in tablesConfig) {
      if (tablesConfig.hasOwnProperty(key)) {
        const table = tablesConfig[key];
        if (table.group_id === groupId && table.technology_id === technology) {
          return table;
        }
      }
    }
    return null;
  };

  const [table1Config, table2Config, table3Config, table4Config, table5Config, table6Config, chart1Config, chart2Config, chart3Config, chart4Config] =
    useMemo(() => {
      if (tablesConfig && Object.keys(tablesConfig).length) {
        return [
          getTableConfig(1),
          getTableConfig(2),
          getTableConfig(3),
          getTableConfig(6),
          tablesConfig[19],
          tablesConfig[22],
          getTableConfig(4),
          getTableConfig(5),
          tablesConfig[20],
          tablesConfig[21],
        ];
      }
      return Array(10).fill(null);
    }, [tablesConfig, getTableConfig]);

  const fetchParamsTable2 = useMemo(
    () => ({
      cell_name: cellSelected['table2'],
      startDate: selectionRange['startDateFormat'] ?? format(new Date(), 'yyyy-MM-dd'),
      endDate: selectionRange['endDateFormat'] ?? format(new Date(), 'yyyy-MM-dd'),
    }),
    [cellSelected, selectionRange]
  );

  const fetchDateParams = useMemo(
    () => ({
      startDate: selectionRange['startDateFormat'] ?? format(new Date(), 'yyyy-MM-dd'),
      endDate: selectionRange['endDateFormat'] ?? format(new Date(), 'yyyy-MM-dd'),
    }),
    [selectionRange]
  );

  const {
    dynamicCols: table1DynamicCols,
    dynamicColsLoading: table1DynamicColsLoading,
    fetchDynamicCols: fetchTable1DynamicCols,
  } = useFetchDynamicCols([table1Config?.table_id]);

  const {
    dynamicCols: table2DynamicCols,
    dynamicColsLoading: table2DynamicColsLoading,
    fetchDynamicCols: fetchTable2DynamicCols,
  } = useFetchDynamicCols([table2Config?.table_id]);

  const { dynamicCols: networkChangesDynamicCols } = useFetchDynamicCols([table3Config?.table_id, table4Config?.table_id]);

  const { dynamicCols: worksHistoryDynamicCols } = useFetchDynamicCols([table5Config?.table_id, table6Config?.table_id]);

  const { pageData: table1PageData, pageCount: table1PageCount, fetchData: fetchTable1Data } = useFetchData(actions.cells);

  const {
    pageData: table2PageData,
    pageCount: table2PageCount,
    fetchData: fetchTable2Data,
  } = useFetchData(actions.cellsHistory, false, fetchParamsTable2);

  const {
    pageData: table3PageData,
    pageCount: table3PageCount,
    fetchData: fetchTable3Data,
  } = useFetchData(actions.dailyChangesCell, false, fetchDateParams);

  const {
    pageData: table4PageData,
    pageCount: table4PageCount,
    fetchData: fetchTable4Data,
  } = useFetchData(actions.dailyChanges, false, fetchDateParams);

  const {
    pageData: table5PageData,
    pageCount: table5PageCount,
    fetchData: fetchTable5Data,
  } = useFetchData(actions.worksOrdersCell, false, fetchDateParams);

  const {
    pageData: table6PageData,
    pageCount: table6PageCount,
    fetchData: fetchTable6Data,
  } = useFetchData(actions.worksOrders, false, fetchDateParams);

  useEffect(() => {
    if (activeTab === 'CELL_CONFIG' && !!table2Config) {
      fetchTable2Data({
        ...fetchParamsTable2,
        pageSize: 10000,
        pageIndex: 0,
        sorting: {},
        filters: {},
        setLoading: (state) => setTable2Loading(state),
        tableKey: table2Config.table_id,
        table_id: table2Config?.table_id,
      });
    }
  }, [cellSelected, fetchTable2Data, fetchParamsTable2, selectionRange, table2Config, activeTab, technology]);

  useEffect(() => {
    if (table1Config && table1DynamicCols[table1Config.table_id] && table1DynamicCols[table1Config.table_id].length) {
      setDynamicColumns({ table: 'table1', data: table1DynamicCols[table1Config.table_id] });
    }
  }, [table1DynamicCols, setDynamicColumns, table1Config]);

  useEffect(() => {
    if (table2Config && table2DynamicCols && table2DynamicCols[table2Config.table_id]?.length) {
      setDynamicColumns({ table: 'table2', data: table2DynamicCols[table2Config.table_id] });
    }
  }, [table2DynamicCols, setDynamicColumns, table2Config]);

  useEffect(() => {
    if (table3Config && networkChangesDynamicCols && networkChangesDynamicCols[table3Config.table_id]?.length) {
      setDynamicColumns({ table: 'table3', data: networkChangesDynamicCols[table3Config.table_id] });
    }
    if (table4Config && networkChangesDynamicCols && networkChangesDynamicCols[table4Config.table_id]?.length) {
      setDynamicColumns({ table: 'table4', data: networkChangesDynamicCols[table4Config.table_id] });
    }
  }, [networkChangesDynamicCols, setDynamicColumns, table3Config, table4Config]);

  useEffect(() => {
    if (table5Config && worksHistoryDynamicCols && worksHistoryDynamicCols[table5Config.table_id]?.length) {
      setDynamicColumns({ table: 'table5', data: worksHistoryDynamicCols[table5Config.table_id] });
    }
    if (table6Config && worksHistoryDynamicCols && worksHistoryDynamicCols[table6Config.table_id]?.length) {
      setDynamicColumns({ table: 'table6', data: worksHistoryDynamicCols[table6Config.table_id] });
    }
  }, [worksHistoryDynamicCols, setDynamicColumns, table5Config, table6Config]);

  useEffect(() => {
    if (table1Config && table1PageData && table1PageData.data && table1PageData.data.length) {
      setControlledPageCount({ table: 'table1', count: table1PageCount[table1Config.table_id] });
      setPageData({ page: 'table1', data: table1PageData });
    }
  }, [table1PageData, setControlledPageCount, table1PageCount, setPageData, table1Config]);

  useEffect(() => {
    if (table2Config && table2PageData && table2PageData.data && table2PageData.data.length) {
      setControlledPageCount({ table: 'table2', count: table2PageCount[table2Config.table_id] });
      setPageData({ page: 'table2', data: table2PageData });
    }
  }, [table2PageData, setControlledPageCount, table2PageCount, setPageData, table2Config]);

  useEffect(() => {
    if (table3Config && table3PageData && table3PageData.data && table3PageData.data.length) {
      setControlledPageCount({ table: 'table3', count: table3PageCount[table3Config.table_id] });
      setPageData({ page: 'table3', data: table3PageData });
    }
  }, [table3PageData, setControlledPageCount, table3PageCount, setPageData, table3Config]);

  useEffect(() => {
    if (table4Config && table4PageData && table4PageData.data && table4PageData.data.length) {
      setControlledPageCount({ table: 'table4', count: table4PageCount[table4Config.table_id] });
      setPageData({ page: 'table4', data: table4PageData });
    }
  }, [table4PageData, setControlledPageCount, table4PageCount, setPageData, table4Config]);

  useEffect(() => {
    if (table5Config && table5PageData && table5PageData.data && table5PageData.data.length) {
      setControlledPageCount({ table: 'table5', count: table5PageCount[table5Config.table_id] });
      setPageData({ page: 'table5', data: table5PageData });
    }
  }, [table5PageData, setControlledPageCount, table5PageCount, setPageData, table5Config]);

  useEffect(() => {
    if (table6Config && table6PageData && table6PageData.data && table6PageData.data.length) {
      setControlledPageCount({ table: 'table6', count: table6PageCount[table6Config.table_id] });
      setPageData({ page: 'table6', data: table6PageData });
    }
  }, [table6PageData, setControlledPageCount, table6PageCount, setPageData, table6Config]);

  const handleClickCellRow = async (cell) => {
    try {
      const cellLatLong = [cell?.latitude, cell?.longitude];
      if (cellLatLong) setView({ latLong: cellLatLong, zoom: 16 });
      const name = cell.cell_name;
      setAllCellSelected({ cellName: name });
    } catch (err) {
      console.error('FC Error: ', err);
    }
  };

  /**
   * @summary Fetch cells columns configuration
   */
  useEffect(() => {
    const fetchCellsColumnsConfiguration = async () => {
      try {
        const cellsColumnsConfiguration = await actions.getCellsConfiguration({ tech_id: technology });
        if (cellsColumnsConfiguration && cellsColumnsConfiguration.data) {
          setSharedColumnCollapseData(cellsColumnsConfiguration.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchCellsColumnsConfiguration();
  }, [setSharedColumnCollapseData, technology]);

  /**
   * @summary reset columns configuration to default
   */
  const handleResetColumnVisible = async (setIsLoading) => {
    setIsLoading(true);
    try {
      const resetResponse = await actions.resetColumnVisible({ tech_id: technology });
      if (resetResponse) {
        const cellsColumnsConfiguration = await actions.getCellsConfiguration({ tech_id: technology });
        if (cellsColumnsConfiguration && cellsColumnsConfiguration.data) {
          setSharedColumnCollapseData(cellsColumnsConfiguration.data);
        }
        fetchTable1DynamicCols();
        fetchTable1Data({
          pageSize: 100,
          pageIndex: 0,
          sorting: {},
          filters: {},
          setLoading: (state) => setTable1Loading(state),
          tableKey: table1Config.table_id,
          table_id: table1Config.table_id,
        });
        fetchTable2DynamicCols();
        fetchTable2Data({
          ...fetchParamsTable2,
          pageSize: 100,
          pageIndex: 0,
          sorting: {},
          filters: {},
          setLoading: (state) => setTable2Loading(state),
          tableKey: table2Config.table_id,
          table_id: table2Config.table_id,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateVisibleColumns = async () => {
    fetchTable1DynamicCols();
    fetchTable1Data({
      pageSize: 100,
      pageIndex: 0,
      sorting: {},
      filters: {},
      setLoading: (state) => setTable1Loading(state),
      tableKey: table1Config.table_id,
      table_id: table1Config.table_id,
    });
    fetchTable2DynamicCols();
    fetchTable2Data({
      ...fetchParamsTable2,
      pageSize: 100,
      pageIndex: 0,
      sorting: {},
      filters: {},
      setLoading: (state) => setTable2Loading(state),
      tableKey: table2Config.table_id,
      table_id: table2Config.table_id,
    });
  };

  /* DATE SELECTION */

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const maxCalendarDate = new Date(today);

  const onClosePopup = async (e) => {
    setPopup({ ...popup, dateRange: false, cellInfoHistory: false });
  };

  const onClickDateRange = async (e) => {
    setPopup({ ...popup, dateRange: true });
  };

  const handleSelectDateRange = async (ranges) => {
    try {
      let start_date = format(ranges.selection.startDate, 'yyyy-MM-dd').toUpperCase();
      let end_date = format(ranges.selection.endDate, 'yyyy-MM-dd').toUpperCase();
      setTempSelectionRange({
        ...tempSelectionRange,
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        startDateFormat: start_date,
        endDateFormat: end_date,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickDateRange = async () => {
    try {
      setSelectionRange(tempSelectionRange);
      setFilterResetKey();
      onClosePopup();
    } catch (err) {
      setPopup({ ...popup, dateRange: false });
      Swal.close();
    }
  };

  const dailyChartsLoad = useCallback(async () => {
    if (!chart1Config || !chart2Config) return;
    setGraphLoading(true);
    const dailyChangesDay = actions.dailyChangesDay({
      pageSize: 100,
      pageIndex: 0,
      table_id: chart1Config?.table_id,
      ...fetchDateParams,
    });

    const dailyChangesParameters = actions.dailyChangesParameters({
      pageSize: 100,
      pageIndex: 0,
      table_id: chart2Config?.table_id,
      ...fetchDateParams,
    });

    Promise.all([dailyChangesDay, dailyChangesParameters])
      .then(async (values) => {
        setChartsData({
          barChanges: utilsCharts.barData(values[0].data),
          pieChanges: utilsCharts.pieData(values[1].data),
        });
      })
      .then(() => {
        setGraphLoading(false);
      });
  }, [fetchDateParams, setChartsData, chart1Config, chart2Config]);

  const worksChartsLoad = useCallback(async () => {
    if (!chart3Config || !chart4Config) return;
    setGraphLoading(true);
    const worksOrdersDay = actions.worksOrdersDay({
      pageSize: 100,
      pageIndex: 0,
      table_id: chart3Config?.table_id,
      ...fetchDateParams,
    });

    const worksOrdersParameters = actions.worksOrdersParameters({
      pageSize: 100,
      pageIndex: 0,
      table_id: chart4Config?.table_id,
      ...fetchDateParams,
    });

    Promise.all([worksOrdersDay, worksOrdersParameters])
      .then(async (values) => {
        setChartsData({
          barWorks: utilsCharts.barData(values[0].data),
          pieWorks: utilsCharts.pieData(values[1].data),
        });
      })
      .then(() => {
        setGraphLoading(false);
      });
  }, [fetchDateParams, setChartsData, chart3Config, chart4Config]);

  useEffect(() => {
    if (activeTab === 'NETWORK_CHANGES') {
      dailyChartsLoad();
    }
    if (activeTab === 'WORK_ITEMS') {
      worksChartsLoad();
    }
  }, [dailyChartsLoad, worksChartsLoad, activeTab]);

  /* LEAFLET MAP */
  const resetFitBounds = () => {
    const newArr = mapLayers.current.others.map((object) => {
      if (object.fitBounds === true) {
        return { ...object, fitBounds: false };
      }
      return object;
    });

    mapLayers.current = { ...mapLayers.current, others: newArr };
  };

  const mapLoad = async () => {
    zoomLevel.current = 13;
    actions.dlearfcn([mapVoyagerUrl]).then(async (response) => {
      let dlearfcnObject = {
        LTE: {},
        NR: {},
        GSM: {},
      };

      response.data.LTE.forEach((value, index) => {
        dlearfcnObject.LTE[value.toString()] = colorDlearfcnLTE[index];
      });

      response.data.NR.forEach((value, index) => {
        dlearfcnObject.NR[value.toString()] = colorDlnarfcnNR[index];
      });

      response.data.GSM.forEach((value, index) => {
        dlearfcnObject.GSM[value.toString()] = colorDlearfcnGSM[index];
      });

      const layers = await mapBuilder.buildBaseLayers([mapVoyagerUrl]);

      baseLayers.current = layers;

      buildTopology().then((data) => {
        setDlearfcn(dlearfcnObject);
        if (view && view.latLong && view.zoom) {
          setView({ latLong: view.latLong, zoom: view.zoom });
        } else {
          setView({ latLong: defaultCenter, zoom: defaultZoom });
        }
      });
    });
  };

  useEffect(() => {
    if (activeTab === 'CELL_CONFIG') {
      mapLoad();
      setReloadMap(true);
    }
  }, [activeTab]);

  useEffect(() => {
    if (location.state) {
      setCellMap(location.state.cell_obj);
    }
  }, [location]);

  useEffect(() => {
    const updateMap = async () => {
      if (activeTab === 'CELL_CONFIG') {
        setLoading(true);
        const response = await buildTopology(mapBounds.current, true);
        if (!response) {
          showErrorAlert({ title: 'Error', text: 'Error loading map' });
        }
        setLoading(false);
      }
    };

    if (!initialRender.current && technology) {
      updateMap();
    }
  }, [technology]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (!_.isEqual(cellMap, defaultCell)) {
        var buttonAction = cellMap.action;

        if (buttonAction === actionsList.clean) {
          mapLayers.current = { ...mapLayers.current, others: [] };
          setCellMap(defaultCell);
        } else if (buttonAction === actionsList.service) {
          mapBuilder.buildServiceAreaView(cellMap, week, tech).then((layers) => {
            mapLayers.current = { ...mapLayers.current, others: layers };
            resetFitBounds();
          });
        }
      }
    }
  }, [cellMap]);

  const handleClickMapCell = useCallback(
    async (cell) => {
      try {
        const cellLatLong = [cell?.latitude, cell?.longitude];
        if (cellLatLong) setView({ latLong: cellLatLong, zoom: 16 });
        const name = cell.cell_name;
        setAllCellSelected({ cellName: name });
      } catch (err) {
        console.error('FC Error: ', err);
      }
    },
    [setAllCellSelected]
  );

  const handleUpdateMapTechSelection = (technologyId) => {
    const mapTechSelectedUpdated = Object.keys(mapTechSelection).map((tech) => {
      if (tech !== technologyId.toString()) {
        return { [tech]: false };
      }
      return { [tech]: true };
    });
    setMapTechSelection(Object.assign({}, ...mapTechSelectedUpdated));
  };

  const buildTopology = useCallback(
    async (bounds, reload) => {
      const topology = await mapBuilder.buildTopologyView(
        technology,
        bounds || mapBounds.current,
        true,
        cellSelected['table2'],
        cellSelected['table4'],
        selectionRange['endDateFormat'],
        dlearfcn,
        reload,
        mapLayers.current['base'],
        handleClickMapCell,
        setCellSelected,
        setMapCellSelected,
        setPrevSelectedCell,
        setTechnology,
        handleUpdateMapTechSelection
      );
      if (topology && topology.length > 0) {
        mapLayers.current = { base: topology[0] };
        return topology;
      } else {
        showErrorAlert({ title: 'Error', text: 'Error loading map' });
      }
    },
    [
      technology,
      cellSelected,
      mapCellSelected,
      selectionRange,
      dlearfcn,
      handleClickMapCell,
      setCellSelected,
      setMapCellSelected,
      handleUpdateMapTechSelection,
    ]
  );

  const zoomHandler = useMemo(
    () => async (zoomValue, bounds, reload) => {
      try {
        if (zoomValue !== undefined && baseLayers.current !== undefined) {
          const reloadValue = reload || (cellSelected['table2'] !== '' && cellSelected['table2'] !== prevSelectedCell) || reloadMap;
          if (reloadValue) {
            setLoading(true);
          }
          setLoading(true);
          zoomLevel.current = zoomValue;
          if (zoomValue > zoomThreshold) {
            mapBounds.current = bounds;
          } else {
            mapBounds.current = defaultBbox;
          }

          await buildTopology(bounds, reloadValue);
          setReloadMap(false);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [zoomLevel, baseLayers, cellSelected, prevSelectedCell, reloadMap, buildTopology]
  );

  const refreshData = (activeTab) => {
    switch (activeTab) {
      case 'CELL_CONFIG':
        fetchTable1Data({
          pageSize: 100,
          pageIndex: 0,
          sorting: {},
          filters: {},
          setLoading: (state) => setTable1Loading(state),
          table_id: table1Config?.table_id,
        });
        fetchTable2Data({
          ...fetchParamsTable2,
          pageSize: 100,
          pageIndex: 0,
          sorting: {},
          filters: {},
          setLoading: (state) => setTable2Loading(state),
          tableKey: table2Config.table_id,
          table_id: table2Config?.table_id,
        });
        break;
      case 'NETWORK_CHANGES':
        fetchTable3Data({
          ...fetchDateParams,
          pageSize: 100,
          pageIndex: 0,
          sorting: {},
          filters: {},
          setLoading: (state) => setTable3Loading(state),
          tableKey: table3Config.table_id,
          table_id: table3Config?.table_id,
        });
        fetchTable4Data({
          ...fetchDateParams,
          pageSize: 100,
          pageIndex: 0,
          sorting: {},
          setLoading: (state) => setTable4Loading(state),
          tableKey: table4Config.table_id,
          table_id: table4Config?.table_id,
        });
        break;
      case 'WORK_ITEMS':
        fetchTable5Data({
          ...fetchDateParams,
          pageSize: 100,
          pageIndex: 0,
          sorting: {},
          filters: {},
          setLoading: (state) => setTable5Loading(state),
          tableKey: table5Config.table_id,
          table_id: table5Config?.table_id,
        });
        fetchTable6Data({
          ...fetchDateParams,
          pageSize: 100,
          pageIndex: 0,
          sorting: {},
          filters: {},
          setLoading: (state) => setTable6Loading(state),
          tableKey: table6Config.table_id,
          table_id: table6Config?.table_id,
        });
        break;
      default:
        break;
    }
  };

  const resetFilters = async () => {
    try {
      setTechnology(1);
      setSelectionRange(selectionRangeDefault);
      setTempSelectionRange(selectionRangeDefault);
      setAllCellSelected({ cellName: '' });
      setAllParameterSelected({ parameterName: '' });
      setCellSelected({ table: 'table1', cellName: '' });
      setPageData({ page: 'table2', data: { data: [], total: 0 } });
      refreshData(activeTab);
      setFilterResetKey();
      if (activeTab === 'CELL_CONFIG') {
        mapLoad();
        setReloadMap(true);
      }
    } catch (err) {
      Swal.close();
    }
  };

  return {
    baseLayers,
    table2Loading,
    table1Loading,
    table4Loading,
    table3Loading,
    resetFilters,
    fetchDateParams,
    fetchParamsTable2,
    fetchTable1Data,
    fetchTable1DynamicCols,
    fetchTable2Data,
    fetchTable3Data,
    fetchTable4Data,
    fetchTable5Data,
    fetchTable6Data,
    table5Loading,
    table6Loading,
    getTableConfig,
    graphLoading,
    handleClickCellRow,
    handleClickDateRange,
    handleResetColumnVisible,
    handleSelectDateRange,
    loading,
    mapLayers,
    maxCalendarDate,
    onClosePopup,
    onClickDateRange,
    pieChartOuterRadius,
    popup,
    renderCustomizedLabel,
    setActiveTab,
    table1Config,
    table1DynamicColsLoading,
    table2Config,
    table3Config,
    table4Config,
    table5Config,
    table6Config,
    tabOptions,
    tempSelectionRange,
    updateVisibleColumns,
    view,
    zoomHandler,
  };
};
