import { useState, useEffect, useCallback, useRef } from 'react';

export const useFetchData = (fetchAction) => {
  const [pageData, setPageData] = useState({});
  const [pageCount, setPageCount] = useState({});
  const [tableKey, setTableKey] = useState(null);
  const isMounted = useRef(true);
  const abortController = useRef(new AbortController());

  const fetchData = useCallback(
    async (
      { pageSize, pageIndex, setLoading, filters, sorting, tableKey, idRequired = false, initialProps, renderFetchNeeded = true, ...rest },
      signal
    ) => {
      const { queryOrder, queryOrderColumn } = sorting;
      const fetchSorted = queryOrder && queryOrderColumn ? { queryOrder, queryOrderColumn } : {};

      try {
        if (!renderFetchNeeded) {
          return;
        }

        setLoading(true);
        abortController.current.abort();
        abortController.current = new AbortController();

        const response = await fetchAction(
          {
            pageSize: pageSize,
            pageIndex: pageIndex,
            ...fetchSorted,
            ...filters,
            ...(idRequired ? { search_id: tableKey } : {}),
            ...(tableKey ? tableKey : {}),
            ...rest,
            ...initialProps,
          },
          signal || abortController.current.signal
        );

        if (isMounted.current) {
          if (response) {
            const { data, total, ...rest } = response;
            setPageData({ page: tableKey, data: data || rest, total });
            const pageCount = Math.ceil(total / pageSize);
            setPageCount((prevState) => {
              return { ...prevState, [tableKey]: Math.max(pageCount, 1) };
            });
          } else {
            setPageData({ page: tableKey, data: [], total: 0 });
            setPageCount((prevState) => {
              return { ...prevState, [tableKey]: 0 };
            });
          }
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchAction]
  );

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    pageData,
    pageCount,
    fetchData,
    setTableKey,
  };
};
