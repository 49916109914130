/** @jsxImportSource @emotion/react */
import classnames from 'classnames';
import { Toolbar } from './components/Toolbar';
import { TableHeader } from './components/TableHeader';
import { TableBody } from './components/TableBody';
import { PaginationBar } from './components/PaginationBar';
import { useFutureTableController } from './FutureTable.controller';
import { StyledTableWrapper, styles } from './FutureTable.styles';
import { Spinner } from 'components/Spinner';

const FutureTable = ({
  data,
  defaultColumns,
  columnVisible,
  selectedRowIds = [],
  rowIdKey = 'id',
  placeholder,
  filterMap = [],
  onFilterChange,
  onRowClick,
  columnsConfig,
  toolbarConfig,
  paginationEnabled = false,
  exportConfig,
  pagData,
  fetchData,
  tableKey = '',
  controlledPageCount,
  updateVisibleColumns,
  sharedColumnCollapseData = [],
  updateSharedColumnCollapseData,
  handleResetColumnVisible,
  actionsColumn = false,
  handleDelete,
  excludeColumns,
  fetchParams = {},
  parentIsLoading = false,
  tableIdRequired = false,
  table_id,
  disabledCollapseColumns = [],
  ...restProps
}) => {
  const {
    tableRef,
    columnsOptions,
    toolbarOptions,
    exportOptions,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    handleVisibleColumnsChange,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex,
    pageSize,
    isLoading,
    setIsLoading,
    searchIsEmpty,
    handleVisibleColumnsCollapseChange,
    expandedColumnIds,
    handleExpandColumn,
    expandedColumnWidth,
    setExpandedColumnWidth,
    loadingColumnsSelection,
    huaFilter,
    nokFilter,
    handleVendorSelector,
    handleExpandAll,
    isExpandedAll,
    fixedColsPositions,
    filterColumnCollapseData,
    setAllFilters,
  } = useFutureTableController({
    columnsConfig,
    toolbarConfig,
    exportConfig,
    placeholder,
    onFilterChange,
    data,
    columnVisible,
    defaultColumns,
    filterMap,
    fetchData,
    tableKey,
    controlledPageCount,
    updateVisibleColumns,
    sharedColumnCollapseData,
    updateSharedColumnCollapseData,
    paginationEnabled,
    excludeColumns,
    fetchParams,
    tableIdRequired,
    table_id,
  });

  const totalRows = paginationEnabled ? pagData.total : rows.length;

  const renderSpinner = () => {
    return (
      <tbody>
        <tr css={styles.flex}>
          <td>
            <Spinner height="90vh" />
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <StyledTableWrapper
      css={styles.tableWrapper(columnsOptions.isDisabled)}
      className={classnames('FutureTableWrapper primary-pagination', restProps.className, {
        '-highlight': selectedRowIds.length < columnsOptions.selectionLimit,
      })}
    >
      <Toolbar
        rowNumber={searchIsEmpty ? 0 : totalRows}
        toolbarOptions={toolbarOptions}
        handleVisibleColumnsChange={handleVisibleColumnsChange}
        handleVisibleColumnsCollapseChange={handleVisibleColumnsCollapseChange}
        exportOptions={exportOptions}
        collapseColumns={filterColumnCollapseData}
        handleResetColumnVisible={handleResetColumnVisible}
        loadingColumnsSelection={loadingColumnsSelection}
        huaFilter={huaFilter}
        nokFilter={nokFilter}
        handleVendorSelector={handleVendorSelector}
        handleExpandAll={handleExpandAll}
        isExpandedAll={isExpandedAll}
        setIsLoading={setIsLoading}
        updateVisibleColumns={updateVisibleColumns}
        updateSharedColumnCollapseData={updateSharedColumnCollapseData}
        resetFilters={setAllFilters}
        disabledCollapseColumns={disabledCollapseColumns}
      />
      <table {...getTableProps()} ref={tableRef} className="future-table" css={styles.table}>
        <TableHeader
          headerGroups={headerGroups}
          columnsOptions={columnsOptions}
          filterNamesReachable={filterMap.map((filter) => filter.id)}
          visibleColumns={visibleColumns}
          expandedColumnIds={expandedColumnIds}
          handleExpandColumn={handleExpandColumn}
          expandedColumnWidth={expandedColumnWidth}
          setExpandedColumnWidth={setExpandedColumnWidth}
          fixedColsPositions={fixedColsPositions}
          actionsColumn={actionsColumn}
        />
        {!isLoading && !loadingColumnsSelection && !parentIsLoading ? (
          <TableBody
            getTableBodyProps={getTableBodyProps}
            columnsOptions={columnsOptions}
            rowIdKey={rowIdKey}
            rows={rows}
            prepareRow={prepareRow}
            selectedRowIds={selectedRowIds}
            onRowClick={searchIsEmpty ? null : onRowClick}
            visibleColumns={visibleColumns}
            page={page}
            expandedColumnIds={expandedColumnIds}
            expandedColumnWidth={expandedColumnWidth}
            setExpandedColumnWidth={setExpandedColumnWidth}
            tableRef={tableRef}
            fixedColsPositions={fixedColsPositions}
            actionsColumn={actionsColumn}
            handleDelete={handleDelete}
          />
        ) : (
          renderSpinner()
        )}
      </table>
      <PaginationBar
        paginationEnabled={paginationEnabled}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
      {columnsOptions.isPaginated && <div className="pagination-bottom" />}
    </StyledTableWrapper>
  );
};

export { FutureTable };
