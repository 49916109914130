import { format } from 'date-fns';

export const tabOptions = [
  {
    name: 'CELL_CONFIG',
    icon: 'now-ui-icons design_vector',
    color: '#b10279',
  },
  {
    name: 'NETWORK_CHANGES',
    icon: 'now-ui-icons business_chart-bar-32',
    color: '#d10062',
  },
  {
    name: 'WORK_ITEMS',
    icon: 'now-ui-icons business_badge',
    color: '#f81253',
  },
];

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export const selectionRangeDefault = {
  startDate: new Date(yesterday),
  endDate: new Date(yesterday),
  key: 'selection',
  startDateFormat: format(new Date(yesterday), 'yyyy-MM-dd').toUpperCase(),
  endDateFormat: format(new Date(yesterday), 'yyyy-MM-dd').toUpperCase(),
};

//Map constants -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const mapVoyagerUrl = process.env.MAPS_VOYAGER_URL || {
  label: 'Voyager map',
  url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png',
};

export const colorDlearfcnLTE = ['#e5f7ff', '#b3e6ff', '#80d5ff', '#4dc4ff', '#1ab3ff', '#0099e6'];
export const colorDlnarfcnNR = ['#ffe5e5', '#ffb3b3', '#ff8080', '#ff4d4d', '#ff1a1a', '#e60000'];
export const colorDlearfcnGSM = [
  // '#fff2e6', '#ffddb3',
  '#ffcc80',
  '#ffb366',
  '#ff9933',
  '#ff8000',
];

export const actionsList = {
  empty: '',
  clean: 'Clean',
  service: 'Service',
  coverage: 'Coverage',
  xfeeders: 'Xfeeders',
  rssi: 'RSSI',
  unbalanced: 'Unbalanced',
};

export const defaultCell = { cell_name: '', site_name: '', sector: '', tech: '', action: actionsList.empty };
export const defaultWeek = '';
export const defaultTech = 'LTE';
export const defaultCenter = process.env.REACT_APP_DEFAULT_CENTER ? process.env.REACT_APP_DEFAULT_CENTER.split(',').map(Number) : [52.51758, 13.388165];
export const defaultZoom = process.env.REACT_APP_DEFAULT_ZOOM || 15;
export const defaultBbox = [13.903198242187502, 49.41454708028363, 7.503662109375001, 52.22443459871999];
export const zoomThreshold = process.env.REACT_APP_ZOOM_THRESHOLD || 10;
