export const extractFeatureCollection = async (data) => {
  try {
    return data.featurecollection.features.map((d) => {
      delete d.properties['_id'];
      delete d.properties['longitude'];
      delete d.properties['latitude'];
      delete d.properties['fixed_geom'];

      return d.properties;
    });
  } catch (err) {
    throw new Error(err);
  }
};
const parseColumn = (data) => {
  return data.charAt(0).toUpperCase() + data.slice(1).replace('_', ' ');
};

export const createColumns = async (columns) => {
  try {
    if (columns !== undefined) {
      return Object.keys(columns).map((e) => {
        return {
          Header: parseColumn(e),
          accessor: e,
          sortable: true,
          filterable: true,
        };
      });
    } else {
      return [];
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getBounds = (bounds) => {
  return [bounds.getEast(), bounds.getSouth(), bounds.getWest(), bounds.getNorth()];
};

export const parseDateUTC = (date) => {
  return new Date(Date.parse(`${date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')} UTC`));
};

export const formatDateToLocal = (parsedDateUTC, userTimeZone, locale) => {
  return parsedDateUTC.toLocaleString(locale, {
    timeZone: userTimeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

export const getTechnologyNameFromId = (techId) => {
  switch (techId) {
    case 1:
      return 'LTE';
    case 2:
      return 'NR';
    case 3:
      return 'GSM';
    default:
      return '';
  }
};

export const getTechnologyIdFromName = (techName) => {
  switch (techName) {
    case 'LTE':
      return 1;
    case 'NR':
      return 2;
    case 'GSM':
      return 3;
    default:
      return 0;
  }
};
