export const useColumnHeaderController = ({ column, columnsOptions, filterNamesReachable }) => {
  const isTooltip = !columnsOptions.isDisabled && column.sortable;
  const sortable = column.sortable && !columnsOptions.isDisabled;
  const sortDirection = column.isSortedDesc ? 'sort-down' : 'sort-up';

  const disabled = columnsOptions.isDisabled ? 'Disabled' : '';
  const reachable = filterNamesReachable.includes(column.id) ? 'Reachable' : '';
  const columnFilter = column.filterable ? column.render(`${disabled}Filter${reachable}`) : null;

  const filterSet = new Set(column.filteredRows?.map((row) => row.values[column.id]));

  return {
    isTooltip,
    sortable,
    sortDirection: column.isSorted ? sortDirection : 'sort',
    columnFilter,
    filterSet,
  };
};
