import { useContext, useEffect, useMemo, useState } from 'react';
import * as actions from 'actions';
import { useFetchData } from 'hooks/useFetchData';
import { useFetchDynamicCols } from 'hooks/useFetchDynamicCols';
import { GlobalContext } from 'context';

export const useDLRSAdvancedController = () => {
  const {
    pageData: provisionedPageData,
    pageCount: provisionedControlledPageCount,
    fetchData: fetchProvisionedData,
    setTableKey: setProvisionedTableKey,
  } = useFetchData(actions.dlrsAdvanced);

  const {
    pageData: discardedPageData,
    pageCount: discardedControlledPageCount,
    fetchData: fetchDiscardedData,
    setTableKey: setDiscardedTableKey,
  } = useFetchData(actions.dlrsAdvancedDiscarded);

  const { tablesConfig } = useContext(GlobalContext);
  const [selectedCell, setSelectedCell] = useState('');

  const filterMap = useMemo(() => [{ id: 'cell_name', value: selectedCell }], [selectedCell]);

  const provisionedTableConfig = tablesConfig[73] || {};
  const discardTableConfig = tablesConfig[77] || {};

  const { dynamicCols: provisionedCols, dynamicColsLoading: provisionedColsLoading } = useFetchDynamicCols([provisionedTableConfig?.table_id]);
  const { dynamicCols: discardCols, dynamicColsLoading: discardColsLoading } = useFetchDynamicCols([discardTableConfig?.table_id]);

  useEffect(() => {
    setProvisionedTableKey(provisionedTableConfig.table_id);
    setDiscardedTableKey(discardTableConfig.table_id);
  }, [setProvisionedTableKey, setDiscardedTableKey, provisionedTableConfig.table_id, discardTableConfig.table_id]);

  const handleSelectCell = (cell) => {
    setSelectedCell(cell.cell_name);
  };

  return {
    provisionedPageData,
    discardedPageData,
    provisionedControlledPageCount,
    discardedControlledPageCount,
    fetchProvisionedData,
    fetchDiscardedData,
    provisionedCols,
    discardCols,
    provisionedColsLoading,
    discardColsLoading,
    provisionedTableConfig,
    discardTableConfig,
    filterMap,
    handleSelectCell,
  };
};
