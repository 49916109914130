import styled from '@emotion/styled';

export const StyledTableBody = styled.tbody``;

const tableBodyStyles = (disabled) => ({
  display: 'flex',
  flex: '99999 1 auto',
  flexDirection: 'column',
  minWidth: 'fit-content',
  opacity: disabled ? 0.2 : 1,
});

const tdStyles = (expanded, expandedColumnWidth, fixed, position) => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  padding: '7px 5px',
  overflow: 'hidden',
  transition: '0.3s ease',
  transitionProperty: 'width, min-width, padding, opacity',
  margin: 'auto',
  minWidth: expanded ? (expandedColumnWidth > 200 ? `${expandedColumnWidth}px` : '200px') : '200px',
  width: expanded ? `${expandedColumnWidth}px !important` : 0,
  textAlign: 'center',

  ':last-child': {
    borderRight: 0,
  },
  position: fixed ? 'sticky' : 'relative',
  zIndex: fixed ? 1 : 0,
  left: position || position === 0 ? `${position}px` : 'auto',
  background: '#fff',

  i: {
    cursor: 'pointer',
    color: '#b10279',
  },
});

export const styles = {
  tableBody: tableBodyStyles,
  td: tdStyles,
};
