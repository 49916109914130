import { FormGroup, Input } from 'reactstrap';
import { matchSorter } from 'match-sorter';
import { debounce } from 'lodash';

const renderColumnFilter = ({ placeholder, ref, innerRef, disabled, onFilterChange }) => {
  return function returnFilter({ column: { filterValue, setFilter, id } }) {
    const debouncedChangeHandler = debounce((e) => {
      if (e.target.value !== filterValue) setFilter(e.target.value || undefined);
    }, 1000);

    const debouncedOnFilterChange = debounce((e) => {
      if (e.target.value !== filterValue) onFilterChange(e, id);
    }, 1000);

    return (
      <FormGroup>
        <Input
          ref={ref ? (el) => (ref.current[id] = el) : null}
          innerRef={innerRef ? (el) => (innerRef.current[id] = el) : null}
          placeholder={placeholder || 'Search'}
          type="search"
          onChange={(e) => {
            debouncedChangeHandler(e);
            if (onFilterChange) debouncedOnFilterChange(e);
          }}
          disabled={disabled}
        />
      </FormGroup>
    );
  };
};

function fuzzyTextFilterFn(rows, id, filterValue) {
  // This is an autoRemove method on the filter function that
  // when given the new filter value and returns true, the filter
  // will be automatically removed. Normally this is just an undefined
  // check, but here, we want to remove the filter if it's not a number

  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
fuzzyTextFilterFn.autoRemove = (val) => !val;

export { renderColumnFilter, fuzzyTextFilterFn };
