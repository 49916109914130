import { useState, useContext, useEffect } from 'react';
import { saveAs } from 'file-saver';
import * as actions from 'actions';
import Swal from 'sweetalert2';
import { showWarningAlert } from 'utils';
import { NetworkMonitoringContext } from 'context';

const INITIAL_DROPDOWNS_VISIBILITY = {
  filter: false,
};

const INITIAL_COLLAPSE_STATE = {
  collapse1: false,
  collapse2: false,
};

export const useToolbarController = ({
  exportOptions,
  collapseColumns,
  updateVisibleColumns,
  updateSharedColumnCollapseData,
  setIsLoading,
  resetFilters,
}) => {
  const [dropdownVisibility, setDropdownVisibility] = useState(INITIAL_DROPDOWNS_VISIBILITY);
  const [collapseState, setCollapseState] = useState(INITIAL_COLLAPSE_STATE);
  const { selectionRange, cellSelected, technology } = useContext(NetworkMonitoringContext);
  const [searchTerm, setSearchTerm] = useState('');

  const [filteredColumns, setFilteredColums] = useState(collapseColumns);

  useEffect(() => {
    const filterObject = (obj, searchTerm) => {
      const result = {};
      const exactMatch = searchTerm[0] === '=';
      const term = exactMatch ? searchTerm.slice(1) : searchTerm;

      for (const [key, value] of Object.entries(obj)) {
        if (
          (exactMatch && key.toLocaleLowerCase() === term.toLocaleLowerCase()) ||
          (!exactMatch && key.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
        ) {
          result[key] = value;
        } else {
          const subResult = {};
          for (const [subKey, subValue] of Object.entries(value)) {
            if (
              (exactMatch && subKey.toLocaleLowerCase() === term.toLocaleLowerCase()) ||
              (!exactMatch && subKey.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
            ) {
              subResult[subKey] = subValue;
            }
          }
          if (Object.keys(subResult).length > 0) {
            result[key] = subResult;
          }
        }
      }
      return result;
    };

    if (searchTerm) {
      const filtered = filterObject(collapseColumns, searchTerm);
      setFilteredColums(filtered);
    } else {
      setFilteredColums(collapseColumns);
    }
  }, [searchTerm, collapseColumns]);

  const showExcelLoading = () => {
    Swal.fire({
      title: 'Creating Excel',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const showColumnImportSuccess = (message, onConfirm) => {
    Swal.fire({
      title: message,
      icon: 'success',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  };

  const toggleDropdownVisibility = (name) =>
    setDropdownVisibility((prevState) => {
      return {
        ...INITIAL_DROPDOWNS_VISIBILITY,
        [name]: !prevState[name],
      };
    });

  const toggleCollapse = (collapseId) => {
    setCollapseState({
      ...collapseState,
      [collapseId]: !collapseState[collapseId],
    });
  };

  const handleFilterClick = () => toggleDropdownVisibility('filter');

  const handleExportClick = async () => {
    try {
      const cellSelectedNeeded = exportOptions?.cellSelectedNeeded ? { cell_name: cellSelected['table2'] } : {};
      const dateRangeNeeded = exportOptions?.dateRangeNeeded
        ? { startDate: selectionRange['startDateFormat'], endDate: selectionRange['endDateFormat'] }
        : {};
      showExcelLoading();
      await actions
        .downloadExcel({ id: exportOptions.id }, { ...exportOptions.parsedFilters, ...dateRangeNeeded, ...cellSelectedNeeded })
        .then((data) => {
          if (data.type === 'application/json') {
            Swal.close();
            showWarningAlert({ title: 'The file is too large and has been queued. You will receive it via e-mail.', body: '' });
          } else {
            saveAs(data, `${exportOptions.excelName}.csv`);
            Swal.close();
          }
        });
    } catch {
      Swal.close();
    }
  };

  const handleImportColumnsClick = async (e) => {
    const technologySelected = { tech_id: technology };
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    await actions
      .importColumns(formData, technologySelected)
      .then((message) => {
        showColumnImportSuccess(message.message, async () => {
          try {
            setIsLoading(true);
            const cellsColumnsConfiguration = await actions.getCellsConfiguration({ tech_id: technology });
            if (cellsColumnsConfiguration && cellsColumnsConfiguration.data) {
              updateSharedColumnCollapseData(cellsColumnsConfiguration.data);
              updateVisibleColumns();
              resetFilters([]);
            }
          } catch (error) {
            showWarningAlert({ title: 'There was an unexpected error', body: error });
          } finally {
            setIsLoading(false);
            e.target.value = '';
          }
        });
      })
      .catch((error) => {
        showWarningAlert({ title: 'Something requires your attention', body: error });
      });
  };

  const handleExportColumnsClick = async () => {
    const technologySelected = { tech_id: technology };
    await actions.exportColumns(technologySelected).then(({ blob, filename }) => {
      saveAs(blob, filename);
    });
  };

  return {
    dropdownVisibility,
    handleFilterClick,
    handleExportClick,
    handleImportColumnsClick,
    handleExportColumnsClick,
    collapseState,
    toggleCollapse,
    searchTerm,
    setSearchTerm,
    filteredColumns,
  };
};
