/** @jsxImportSource @emotion/react */
import { styles } from './CustomTooltip.styles';

export const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div css={styles.tooltip}>
        <span>{`${payload[0].name}`}</span>
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
