import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import _, { debounce } from 'lodash';

import { mapDebounceTime } from '../../../variables/customMap';
import MapTable from 'components/Map/MapTable/MapTable';

import * as utils from 'utils';

function MapWrapper({ baseLayers, permanentLayers, featureLayers, zoomHandler, setView, zoomThreshold, setMapTechSelection, mapTechSelection }) {
  const map = useRef(null);
  const layerControl = useRef();
  const tableData = useRef({ headers: [], data: [], title: '' });
  const [zoomLevel, setZoomLevel] = useState();
  const [moveLevel, setMoveLevel] = useState();
  const [showTable, setShowTable] = useState(false);
  const [lastBounds, setLastBounds] = useState(undefined);

  const updateMapTechSelection = () => {
    const actualLayers = layerControl.current.getOverlays();
    const updatedMapTechSelection = {};
    let index = 1;

    for (const key in actualLayers) {
      updatedMapTechSelection[index] = actualLayers[key].inMap;
      index++;
    }
    setMapTechSelection(updatedMapTechSelection);
  };

  useEffect(() => {
    map.current = L.map('map', {
      center: setView.latlong,
      zoom: setView.zoom,
      scrollWheelZoom: false,
    });

    // map.current.on('zoomend', function() {
    //   console.log('zoomend', map.current.getZoom())
    //   setZoomLevel(map.current.getZoom())
    // });

    map.current.on('moveend', function () {
      if (map.current !== undefined && map.current !== null) {
        setZoomLevel(map.current.getZoom());
        const bounds = utils.getBounds(map.current.getBounds());
        setMoveLevel(bounds);
      }
    });

    map.current.on('overlayadd', updateMapTechSelection);
    map.current.on('overlayremove', updateMapTechSelection);

    // map.current.on('popupopen', function(e) {
    //   document.location.hash = '#tab-0';
    // });

    // Add method to layer control class
    L.Control.Layers.include({
      getOverlays: function () {
        // create hash to hold all layers
        var control, layers;
        layers = {};
        control = this;

        // loop thru all layers in control
        control._layers.forEach(function (obj) {
          var layerName;

          // check if layer is an overlay
          if (obj.overlay) {
            // get name of overlay
            layerName = obj.name;
            // store whether it's present on the map or not
            return (layers[layerName] = { obj: obj, inMap: control._map.hasLayer(obj.layer) });
          }
        });

        return layers;
      },
    });

    layerControl.current = L.control.layers({}, {}).addTo(map.current);
  }, []);

  useEffect(() => {
    map.current.setView(setView.latLong, setView.zoom);
  }, [setView]);

  useEffect(() => {
    if (baseLayers && baseLayers.layers) {
      baseLayers.layers.forEach((element) => {
        map.current.addLayer(element);
      });
    }
  }, [baseLayers]);

  useEffect(() => {
    const resetOverlays = () => {
      const actualOverlays = layerControl.current.getOverlays();
      for (const overlayId in actualOverlays) {
        layerControl.current.removeLayer(actualOverlays[overlayId].obj.layer);
        map.current.removeLayer(actualOverlays[overlayId].obj.layer);
      }
    };

    const addPermanentLayers = () => {
      permanentLayers.forEach((permanentLayer) => {
        layerControl.current.addOverlay(permanentLayer.layer, permanentLayer.title);
      });
    };

    resetOverlays();
    addPermanentLayers();
  }, [permanentLayers]);

  useEffect(() => {
    const checkTechSelection = () => {
      Object.keys(mapTechSelection).forEach((tech) => {
        let techName = '';
        switch (tech) {
          case '1':
            techName = 'LTE';
            break;
          case '2':
            techName = 'NR';
            break;
          case '3':
            techName = 'GSM';
            break;
          default:
            break;
        }
        const layer = permanentLayers.find((layer) => layer.title === techName);
        if (layer) {
          if (mapTechSelection[tech] === true) {
            map.current.addLayer(layer.layer);
          } else {
            map.current.removeLayer(layer.layer);
          }
        }
      });
      layerControl.current._update();
    };

    checkTechSelection();
  }, [mapTechSelection, permanentLayers]);

  useEffect(() => {
    const zoomHandlerDebounced = debounce((level, bounds, reload) => {
      getCalculateBounds().then((data) => {
        if (data.reload === true && baseLayers !== undefined) {
          setLastBounds(data.originalBounds);
        }
        zoomHandler(level, data.bounds, data.reload);
      });
    }, mapDebounceTime);

    zoomHandlerDebounced(zoomLevel);

    return () => {
      zoomHandlerDebounced.cancel();
    };
  }, [moveLevel]);

  const getCalculateBounds = async () => {
    let reload = true;
    const overlayCenter = map.current.getCenter();
    const actualZoom = map.current.getZoom();
    let mapZoom;
    if (actualZoom < zoomThreshold) {
      mapZoom = zoomThreshold;
    } else if (actualZoom < setView.zoom) {
      mapZoom = actualZoom;
    } else {
      mapZoom = setView.zoom;
    }
    const pixWidth = 1165;
    const pixOffsetX = pixWidth / 2;
    const pixOffsetY = (pixOffsetX * setView.zoom) / 16;

    const centerPoint = map.current.project(overlayCenter, mapZoom);
    const latLng1 = map.current.unproject(L.point([centerPoint.x - pixOffsetX, centerPoint.y + pixOffsetY]), mapZoom);
    const latLng2 = map.current.unproject(L.point([centerPoint.x + pixOffsetX, centerPoint.y - pixOffsetY]), mapZoom);
    const bbox = L.latLngBounds(latLng1, latLng2);

    if (lastBounds !== undefined && lastBounds.contains(overlayCenter) && zoomLevel >= setView.zoom) {
      reload = false;
    }

    return { originalBounds: bbox, bounds: utils.getBounds(bbox), reload };
  };

  useEffect(() => {
    var actualLayers = layerControl.current.getOverlays();

    if (!featureLayers) return;
    // TO-DO: Seguro que se puede hacer mejor
    // Loop over featureLayers passed as props to check if we have already any of them in the map
    for (var i = 0, len = featureLayers.length; i < len; i++) {
      for (var overlayId in actualLayers) {
        if (overlayId === featureLayers[i].title) {
          // In affirmative case, delete from map before adding again
          layerControl.current.removeLayer(actualLayers[overlayId].obj.layer);
          map.current.removeLayer(actualLayers[overlayId].obj.layer);
        }
      }

      map.current.addLayer(featureLayers[i].layer);
      layerControl.current.addOverlay(featureLayers[i].layer, featureLayers[i].title);
      if (featureLayers[i].fitBounds) {
        if (_.isEmpty(featureLayers[i].layer.getBounds()) === false) {
          map.current.fitBounds(featureLayers[i].layer.getBounds());
        }
      }
      if (featureLayers[i].data) {
        tableData.current = featureLayers[i].data;
        setShowTable(true);
      }
    }

    let missing = false;
    // Loop over actualLayers to check if we have in the map a layer not included in layers passed as props
    for (overlayId in actualLayers) {
      for (i = 0, len = featureLayers.length; i < len; i++) {
        if (overlayId === featureLayers[i].title) {
          missing = true;
        }
      }

      if (missing === false) {
        // In affirmative case, delete from map
        layerControl.current.removeLayer(actualLayers[overlayId].obj.layer);
        map.current.removeLayer(actualLayers[overlayId].obj.layer);
      }

      missing = false;
    }
    if (_.isEmpty(layerControl.current.getOverlays())) {
      tableData.current = { headers: [], data: [], title: '' };
      setShowTable(false);
    }
  }, [featureLayers]);

  return (
    <>
      <div id="map" ref={map} />
      {showTable && <MapTable header={tableData.current.headers} columns={tableData.current.body} title={tableData.current.title} />}
    </>
  );
}

export default MapWrapper;
