const NetworkMonitoringReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE_DATA':
      return {
        ...state,
        [action.payload.page]: action.payload.data,
      };
    case 'SET_DYNAMIC_COLUMNS':
      return {
        ...state,
        dynamicColumns: {
          ...state.dynamicColumns,
          [action.payload.table]: action.payload.data,
        },
      };
    case 'RESET_FILTERS':
      return { ...state, filterResetKey: state.filterResetKey + 1 };
    case 'SET_CONTROLLED_PAGE_COUNT':
      return {
        ...state,
        controlledPageCount: {
          ...state.controlledPageCount,
          [action.payload.table]: action.payload.count,
        },
      };
    case 'SET_CELL_SELECTED':
      return {
        ...state,
        cellSelected: { ...state.cellSelected, [action.payload.table]: action.payload.cellName },
      };
    case 'SET_PARAMETER_SELECTED':
      return {
        ...state,
        parameterSelected: { ...state.parameterSelected, [action.payload.table]: action.payload.parameterName },
      };
    case 'SET_MAP_SELECTED':
      return {
        ...state,
        mapCellSelected: action.payload,
      };
    case 'SET_ALL_CELL_SELECTED':
      return {
        ...state,
        cellSelected: {
          ...state.cellSelected,
          table2: action.payload.cellName,
          table4: action.payload.cellName,
          table6: action.payload.cellName,
        },
      };
    case 'SET_ALL_PARAMETER_SELECTED':
      return {
        ...state,
        parameterSelected: {
          ...state.parameterSelected,
          table4: action.payload.parameterName,
          table6: action.payload.parameterName,
        },
      };
    case 'SET_SELECTION_RANGE':
      return {
        ...state,
        selectionRange: action.payload,
      };
    case 'SET_SHARED_COLUMN_COLLAPSE_DATA':
      return {
        ...state,
        sharedColumnCollapseData: action.payload,
      };
    case 'SET_CHARTS_DATA':
      return {
        ...state,
        chartsData: {
          ...state.chartsData,
          ...action.payload,
        },
      };
    case 'SET_TECHNOLOGY':
      return {
        ...state,
        technology: action.payload,
      };
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload,
      };
    case 'SET_MAP_TECH_SELECTION':
      return {
        ...state,
        mapTechSelection: action.payload,
      };
    default:
      return state;
  }
};

export { NetworkMonitoringReducer };
