const modalStyles = {
  maxWidth: '400px',
  textAlign: 'center',
};

const modalButtonStyles = {
  marginRight: '15px',
};

export const styles = {
  modal: modalStyles,
  modalButton: modalButtonStyles,
};
