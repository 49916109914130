const globalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        username: action.payload,
      };
    case 'SET_USER_ADMIN':
      return {
        ...state,
        isUserAdmin: action.payload,
      };
    case 'SET_TABLES_CONFIG':
      return {
        ...state,
        tablesConfig: action.payload,
      };
    case 'SET_USER_TIME_ZONE':
      return {
        ...state,
        userTimeZone: action.payload,
      };
    case 'SET_TECHNOLOGIES':
      return {
        ...state,
        technologies: action.payload,
      };
    default:
      return state;
  }
};

export { globalReducer };
