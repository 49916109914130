import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Global } from '@emotion/react';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import Login from 'views/Login';
import { GlobalProvider, CSONModulesProvider, NetworkMonitoringProvider } from 'context';
import { useAppController } from 'App.controller';
import { useRoutePaths } from 'routes';
import { Layout } from 'layouts';
import { routes } from 'routes';

const AppState = () => {
  const { globalStyles } = useAppController();
  const { appRoutes } = useRoutePaths();
  return (
    <>
      <NetworkMonitoringProvider>
        <CSONModulesProvider>
          <Global styles={globalStyles} />
          <Suspense fallback="loading">
            <I18nextProvider i18n={i18n}>
              <BrowserRouter>
                <Switch>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Layout routeList={routes}>
                    {appRoutes}
                    <Redirect to="/supervisor" />
                  </Layout>
                </Switch>
              </BrowserRouter>
            </I18nextProvider>
          </Suspense>
        </CSONModulesProvider>
      </NetworkMonitoringProvider>
    </>
  );
};

const App = () => {
  return (
    <GlobalProvider>
      <AppState />
    </GlobalProvider>
  );
};

export default App;
