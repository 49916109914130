/** @jsxImportSource @emotion/react */
import Card from 'components/Card';
import { Row, Col, Button } from 'reactstrap';
import { styles } from './MissingNeighbours.styles';
import { useMissingNeighboursController } from './MissingNeighbours.controller';
import { FutureTable } from 'components/Table/FutureTable';

export const MissingNeighbours = () => {
  const {
    data,
    missingNeighbors,
    tecnology,
    missingNeighboursTablesPageCount,
    tablesGroupName,
    selectedTables,
    renderedButtons,
    defaultColumns,
    dynamicColsLoading,
    fetchTableData,
    handleClickTecnologySelector,
    handleClickTableButton,
  } = useMissingNeighboursController();

  return (
    <Col xs={12} md={12}>
      <Card isLoading={!missingNeighbors.data} bodyHeight="auto">
        <Row>
          {Object.keys(missingNeighbors).length > 0 &&
            Object.keys(missingNeighbors.data).map((button) => (
              <Col css={styles.buttonCol} key={button}>
                <Button
                  color="future"
                  block
                  css={styles.button}
                  active={tecnology === button}
                  onClick={() => handleClickTecnologySelector(button, missingNeighbors.data[button])}
                >
                  {button}
                </Button>
              </Col>
            ))}
        </Row>
      </Card>
      <Row>
        <Col md={2}>
          {tecnology && (
            <Card title={`${tecnology} tables`}>
              <div css={styles.buttonContainer}>
                {renderedButtons &&
                  renderedButtons.map((button) => (
                    <Button
                      key={button.label}
                      style={{ maxWidth: '95%' }}
                      color="future"
                      block
                      active={tablesGroupName === button.label}
                      onClick={() => handleClickTableButton(button)}
                    >
                      {button.label}
                    </Button>
                  ))}
              </div>
            </Card>
          )}
        </Col>
        {selectedTables &&
          selectedTables.map(({ id, table_title, file_name }) => (
            <Col md={10 / selectedTables.length} key={id}>
              <Card title={table_title} isLoading={dynamicColsLoading}>
                <FutureTable
                  data={data[id]?.data || []}
                  columnsConfig={{
                    isStriped: false,
                    dynamicColumns: false,
                    expandColumns: true,
                  }}
                  toolbarConfig={{
                    showExport: true,
                    showRowNumber: true,
                  }}
                  defaultColumns={defaultColumns[[id]]}
                  paginationEnabled={true}
                  exportConfig={{ excelName: file_name, sheetName: file_name, id: id }}
                  pagData={data[id] || { data: [], pageCount: 0 }}
                  fetchData={fetchTableData}
                  tableKey={id}
                  controlledPageCount={missingNeighboursTablesPageCount[id]}
                />
              </Card>
            </Col>
          ))}
      </Row>
    </Col>
  );
};
