import { useCallback, useContext, useEffect, useState } from 'react';
import * as actions from 'actions';
import { CSONModulesContext } from 'context';
import { useFetchData } from 'hooks/useFetchData';
import { useFetchDynamicCols } from 'hooks/useFetchDynamicCols';

export const useMissingNeighboursController = () => {
  const [tecnology, setTecnology] = useState('');
  const [tablesGroupName, setTablesGroupName] = useState();
  const [renderedButtons, setRenderedButtons] = useState([]);

  const { pageData: missingNeighborsData, fetchData: fetchMissingNeighborsData } = useFetchData(actions.missingNeighbors);

  const {
    pageData: missingNeighboursTablesData,
    pageCount: missingNeighboursTablesPageCount,
    fetchData: fetchMissingNeighboursTablesData,
  } = useFetchData(actions.missingNeighboursTables, true);

  const { data, selectedTables, setSelectedTables, cleanSelectedTables, setData } = useContext(CSONModulesContext);

  const selectedTableIds = selectedTables.length ? selectedTables.map((table) => table.id) : [];

  const { dynamicCols, dynamicColsLoading } = useFetchDynamicCols(selectedTableIds);

  useEffect(() => {
    cleanSelectedTables();
    fetchMissingNeighborsData({ pageSize: 100, pageIndex: 0, setLoading: () => {}, filters: {}, sorting: {} });
  }, [cleanSelectedTables, fetchMissingNeighborsData]);

  useEffect(() => {
    const { page, data, total } = missingNeighboursTablesData;
    setData({ table: page, data: { data, total } });
  }, [missingNeighboursTablesData, setData]);

  const fetchTableData = useCallback(
    async ({ tableKey, pageSize, pageIndex, setLoading, filters, sorting }) => {
      fetchMissingNeighboursTablesData({
        pageSize,
        pageIndex,
        setLoading,
        filters,
        sorting,
        tableKey: tableKey,
        idRequired: true,
      });
    },
    [fetchMissingNeighboursTablesData]
  );

  const handleClickTableButton = (tecnologyTables) => {
    const tables = tecnologyTables.table;

    setTablesGroupName(tecnologyTables.label);
    setSelectedTables(tables);

    tables.forEach(async ({ id }) => {
      fetchMissingNeighboursTablesData({
        tableKey: id,
        pageSize: 100,
        pageIndex: 0,
        setLoading: () => {},
        sorting: {},
        filters: {},
        idRequired: true,
      });
    });
  };

  const handleClickTecnologySelector = (tecnology, tecnologyData) => {
    setTecnology(tecnology);
    setRenderedButtons(tecnologyData);
    handleClickTableButton(tecnologyData[0]);
  };

  return {
    data,
    selectedTables,
    missingNeighboursTablesPageCount,
    tablesGroupName,
    fetchTableData,
    missingNeighbors: missingNeighborsData,
    missingNeighboursTables: missingNeighboursTablesData,
    tecnology,
    renderedButtons,
    handleClickTecnologySelector,
    handleClickTableButton,
    defaultColumns: dynamicCols,
    dynamicColsLoading,
  };
};
