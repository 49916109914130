import ReactDOM from 'react-dom';
import App from 'App';
import '../node_modules/reactjs-popup/dist/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/now-ui-dashboard.scss?v=1.5.0';
import 'assets/css/demo.css';
import '../node_modules/react-date-range/dist/styles.css'; // main style file
import '../node_modules/react-date-range/dist/theme/default.css'; // theme css file

ReactDOM.render(<App />, document.getElementById('root'));
