/** @jsxImportSource @emotion/react */
import Card from 'components/Card';
import { Col, Button } from 'reactstrap';
import { styles } from './Coordinates&Overshooters.styles';
import { FutureTable } from 'components/Table/FutureTable';
import { useCoordinatesOvershootersController } from './Coordinates&Overshooters.controller';

export const CoordinatesOvershooters = () => {
  const { data, selectedTableLabel, selectedTables, tables, pageCount, fetchTableData, handleClickTableButton, defaultColumns, dynamicColsLoading } =
    useCoordinatesOvershootersController();

  return (
    <>
      <Col md={2}>
        <Card title={'Available tables'}>
          <div css={styles.buttonContainer}>
            {tables &&
              tables.map((button) => (
                <Button
                  key={button.label}
                  style={{ maxWidth: '95%' }}
                  color="future"
                  block
                  active={selectedTableLabel === button.label}
                  onClick={() => handleClickTableButton(button)}
                >
                  {button.label}
                </Button>
              ))}
          </div>
        </Card>
      </Col>
      {selectedTables &&
        selectedTables.map(({ id, table_title, file_name }) => (
          <Col md={10 / selectedTables.length} key={id}>
            <Card title={table_title} isLoading={dynamicColsLoading}>
              <FutureTable
                data={data[id]?.data || []}
                defaultColumns={defaultColumns[id]}
                columnsConfig={{
                  isStriped: false,
                  dynamicColumns: false,
                  expandColumns: true,
                }}
                toolbarConfig={{
                  showExport: true,
                  showRowNumber: true,
                }}
                paginationEnabled={true}
                exportConfig={{ excelName: file_name, sheetName: file_name, id: id }}
                pagData={data[id] || { data: [], pageCount: 0 }}
                fetchData={fetchTableData}
                tableKey={id}
                controlledPageCount={pageCount[id]}
              />
            </Card>
          </Col>
        ))}
    </>
  );
};
