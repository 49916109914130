const rowStyles = {
  height: '100%',
  flexWrap: 'nowrap',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const iconContainerStyles = ({ color, pointer = false }) => {
  return {
    margin: '0 15px',
    color: color,
    cursor: pointer ? 'pointer' : 'default',
  };
};

const iconStyles = {
  fontSize: 'large',
};

const flexStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const containerStyles = {
  ...flexStyles,
  height: '100%',
};

const inputContainerStyles = {
  width: '100%',
  maxWidth: '20rem',
  textAlign: 'center',
  cursor: 'pointer',
};

const dateInputStyles = {
  textAlign: 'center',
  '&.form-control[readOnly]': {
    backgroundColor: 'transparent',
    color: '#2c2c2c',
    cursor: 'pointer',
  },
};

export const styles = {
  row: rowStyles,
  iconContainer: iconContainerStyles,
  icon: iconStyles,
  container: containerStyles,
  inputContainer: inputContainerStyles,
  dateInput: dateInputStyles,
};
