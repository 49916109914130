const INITIAL_MAIN_PANEL_OVERFLOW_VALUE = 'auto';

const HEIGHT_180 = 180;
const HEIGHT_250 = 250;
const HEIGHT_300 = 300;
const HEIGHT_650 = 650;
const HEIGHT_720 = 720;
const HEIGHT_780 = 780;
const HEIGHT_850 = 850;

const TABLE_KEYS = {
  CODIRECTED_NEIGHBORS_AUDIT_2G: 'codirected_neighbors_audit_2g',
  CODIRECTED_NEIGHBORS_AUDIT_5G: 'codirected_neighbors_audit_5g',
  DLRS_ADVANCED: 'dlrs_advanced_boost',
  RANGE_OPTIMIZATION_WORK_ITEMS: 'range_optimization_workitems',
  COMPLETE_RANGE_OPTIMIZATION: 'range_optimization',
  TRAFFIC_STEERING: 'traffic_steering',
  NUMBER_DAILY_CHANGES_CELL: 'number_daily_changes_cell',
  DAILY_CHANGES: 'daily_changes',
  NUMBER_WORKS_CELL: 'number_works_cell',
  WORKS_ITEMS: 'works_items',
};

export { INITIAL_MAIN_PANEL_OVERFLOW_VALUE, HEIGHT_180, HEIGHT_250, HEIGHT_300, HEIGHT_650, HEIGHT_720, HEIGHT_780, HEIGHT_850, TABLE_KEYS };
