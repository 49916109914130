/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, FormGroup, Form, Row, Col } from 'reactstrap';
import logo from 'assets/img/FC_logo.png';
import { StyledInput, styles } from './Login.styles.jsx';
import { Card } from 'components/Card/Card.jsx';
import { useLoginController } from './Login.controller.js';

export const Login = () => {
  const { user, pass, login, onChangeUser, onChangePass } = useLoginController();

  const loginCardFooter = (
    <Button className="btn-fill" css={styles.footer} color="future" type="button" disabled={user === null || pass === null} onClick={login}>
      Submit
    </Button>
  );

  return (
    <>
      <div className="content" css={styles.content}>
        <Card title="Login" footerContent={loginCardFooter} bodyHeight="auto" height="25rem" width="48rem">
          <Row>
            <Col css={styles.imageCol}>
              <img src={logo} css={styles.image} alt="logo"></img>
            </Col>
            <Col>
              <Form>
                <label>User</label>
                <FormGroup>
                  <StyledInput css={styles.input} type="email" name="user" onChange={onChangeUser} />
                </FormGroup>
                <label>Password</label>
                <FormGroup>
                  <StyledInput css={styles.input} type="password" name="password" onChange={onChangePass} />
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
