import styled from '@emotion/styled';
import { Input } from 'reactstrap';

export const StyledPaginationBar = styled.div``;

export const StyledButton = styled.button``;

export const StyledIcon = styled.i``;

export const StyledInput = styled(Input)``;

const flexStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
};

const buttonStyles = {
  border: 0,
  borderRadius: '30px !important',
  transition: ' all 0.3s',
  minWidth: '30px',
  textAlign: 'center',
  height: '30px',
  lineHeight: '30px',
  color: '#2c2c2c',
  background: 'transparent',
  outline: 'none',
};

const inputStyles = {
  display: 'inline-block',
  width: '5rem',
};

const selectStyles = {
  ...inputStyles,
  width: '6.5rem',
};

const toolbarIconStyles = {
  fontSize: '16px',
};

const toolbarWrapperStyles = {
  ...flexStyles,
  flexWrap: 'wrap',
};

export const styles = {
  paginationBar: flexStyles,
  button: buttonStyles,
  icon: toolbarIconStyles,
  input: inputStyles,
  select: selectStyles,
  toolbarWrapper: toolbarWrapperStyles,
};
