import { keyframes } from '@emotion/react';

const fadeInAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const cardStyles = (height, width) => {
  return {
    border: 0,
    borderRadius: '0.1875rem',
    display: 'inline-block',
    position: 'relative',
    width: width ?? '100%',
    marginBottom: '20px',
    boxShadow: '0 1px 15px 1px rgba(39, 39, 39, 0.1)',
    animation: `${fadeInAnimation} ease 1s`,
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    height: height ?? '',

    '.card-7': {
      height: '7em',
    },

    ' .card-60': {
      height: '62em',
    },
  };
};

const cardHeaderStyles = {
  padding: '15px 15px 0',
  border: 0,
  minHeight: '7em',
  backgroundColor: 'transparent',
};

const cardTitleStyles = {
  marginTop: '10px',
  textAlign: 'center',
  color: '#2a2c39',
};

const cardBodyStyles = (bodyHeight) => {
  return {
    padding: '15px 15px 10px 15px',
    height: bodyHeight,
  };
};

const cardFooterStyles = {
  display: 'flex',
  justifyContent: 'end',
  backgroundColor: 'transparent',
  border: 'none',
};

export const styles = {
  card: cardStyles,
  cardBody: cardBodyStyles,
  cardHeader: cardHeaderStyles,
  cardTitle: cardTitleStyles,
  cardFooter: cardFooterStyles,
};
