import { useState } from 'react';
import * as actions from 'actions';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

export const useCSONModulesController = () => {
  const [activeTab, setActiveTab] = useState('CODIRECTED_NEIGHBOURS_AUDIT');

  const showPdfLoading = () => {
    Swal.fire({
      title: 'Dowloading PDF',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const handleDownloadFile = async (fileName) => {
    try {
      showPdfLoading();
      await actions.downloadCSONInfo({ name: fileName }).then((data) => {
        saveAs(data, `${fileName}.pdf`);
      });
      Swal.close();
    } catch {
      Swal.close();
    }
  };

  const tabOptions = [
    {
      name: 'CODIRECTED_NEIGHBOURS_AUDIT',
      icon: 'fas fa-users',
      color: '#520052',
      handleDownloadClick: () => {
        handleDownloadFile('CODIRECTED_NEIGHBOURS_AUDIT');
      },
    },
    {
      name: 'AUTO_CELLRANGE_OPTIMIZATION',
      icon: 'fab fa-sellsy',
      color: '#930682',
      handleDownloadClick: () => {
        handleDownloadFile('AUTO_CELLRANGE_OPTIMIZATION');
      },
    },
    {
      name: 'MISSING_NEIGHBOURS',
      icon: 'fas fa-user-times',
      color: '#b10279',
      handleDownloadClick: () => {
        handleDownloadFile('MISSING_NEIGHBOURS');
      },
    },
    {
      name: 'COORDINATES AND OVERSHOOTERS AUDIT',
      icon: 'fas fa-map-marker-alt',
      color: '#d10062',
      handleDownloadClick: () => {
        handleDownloadFile('COORDINATES AND OVERSHOOTERS AUDIT');
      },
    },
    {
      name: 'DLRS ADVANCED BOOST',
      icon: 'fas fa-bolt',
      color: '#f81253',
      handleDownloadClick: () => {
        handleDownloadFile('DLRS ADVANCED BOOST');
      },
    },
    {
      name: 'TRAFFIC STEERING',
      icon: 'fas fa-random',
      color: '#ff293b',
      handleDownloadClick: () => {
        handleDownloadFile('TRAFFIC STEERING');
      },
    },
  ];

  return {
    activeTab,
    setActiveTab,
    tabOptions,
  };
};
