import { useCallback, useContext, useEffect, useState } from 'react';
import * as actions from 'actions';
import { CSONModulesContext } from 'context';
import { useFetchData } from 'hooks/useFetchData';
import { useFetchDynamicCols } from 'hooks/useFetchDynamicCols';

export const useCoordinatesOvershootersController = () => {
  const [selectedTableLabel, setSelectedTableLabel] = useState('');
  const [tables, setTables] = useState([]);

  const { data, setData, selectedTables, setSelectedTables, cleanSelectedTables } = useContext(CSONModulesContext);

  const { pageData: coordinatesOvershootersData, fetchData: fetchCoordinatesOvershootersData } = useFetchData(actions.coordinatesOvershooters);

  const selectedTableIds = selectedTables.length ? selectedTables.map((table) => table.id) : [];

  const { dynamicCols, dynamicColsLoading } = useFetchDynamicCols(selectedTableIds);
  const {
    pageData: missingNeighboursTablesData,
    pageCount: missingNeighboursTablesPageCount,
    fetchData: fetchMissingNeighboursTablesData,
  } = useFetchData(actions.missingNeighboursTables);

  const handleClickTableButton = useCallback(
    async (selectedTables) => {
      const tables = selectedTables.table;
      setSelectedTableLabel(selectedTables.label);

      setSelectedTables(tables);
      tables.forEach(async ({ id }) => {
        fetchMissingNeighboursTablesData({
          tableKey: id,
          pageSize: 100,
          pageIndex: 0,
          setLoading: () => {},
          sorting: {},
          filters: {},
          idRequired: true,
        });
      });
    },
    [fetchMissingNeighboursTablesData, setSelectedTableLabel, setSelectedTables]
  );

  useEffect(() => {
    cleanSelectedTables();
    fetchCoordinatesOvershootersData({ pageSize: 100, pageIndex: 0, setLoading: () => {}, filters: {}, sorting: {} });
  }, [cleanSelectedTables, fetchCoordinatesOvershootersData]);

  useEffect(() => {
    if (coordinatesOvershootersData && coordinatesOvershootersData.data) {
      setTables(coordinatesOvershootersData.data);
      handleClickTableButton(coordinatesOvershootersData.data[0]);
    }
  }, [coordinatesOvershootersData, handleClickTableButton]);

  useEffect(() => {
    const { page, data, total } = missingNeighboursTablesData;
    setData({ table: page, data: { data, total } });
  }, [missingNeighboursTablesData, setData]);

  const fetchTableData = useCallback(
    async ({ tableKey, pageSize, pageIndex, setLoading, filters, sorting }) => {
      fetchMissingNeighboursTablesData({
        pageSize,
        pageIndex,
        setLoading,
        filters,
        sorting,
        tableKey: tableKey,
        idRequired: true,
      });
    },
    [fetchMissingNeighboursTablesData]
  );

  return {
    data,
    selectedTableLabel,
    selectedTables,
    tables,
    pageCount: missingNeighboursTablesPageCount,
    defaultColumns: dynamicCols,
    dynamicColsLoading,
    fetchTableData,
    handleClickTableButton,
  };
};
