import { useEffect } from 'react';
import { NetworkMonitoringContext } from 'context';
import { useContext, useState } from 'react';

export const useCellConfigurationController = () => {
  const [expanded, setExpanded] = useState(false);
  const [transition, setTransition] = useState();
  const { setCellSelected } = useContext(NetworkMonitoringContext);

  useEffect(() => {
    const wTransTiming = expanded ? 'ease-in-out' : 'linear';
    const wTransDelay = expanded ? '0.1s' : '0.5s';
    const oTransDelay = expanded ? '0.45s' : '';

    setTransition(`width 0s ${wTransTiming} ${wTransDelay}, opacity 0.5s ease-in ${oTransDelay}`);
  }, [expanded]);

  const onCellInfoFilterChange = (event) => {
    const target = event.target;
    const parent1 = target.parentNode;
    const parent2 = parent1 ? parent1.parentNode : null;
    if (parent2 && parent2.id === 'cell_name') {
      setCellSelected({ table: 'table1', cellName: target.value });
    }
  };

  return { expanded, transition, setExpanded, onCellInfoFilterChange };
};
