import { useDLRSAdvancedController } from './DLRSAdvanced.controller';
import { FutureTable } from 'components/Table/FutureTable';
import Card from 'components/Card';
import { Col } from 'reactstrap';

export const DLRSAdvanced = () => {
  const {
    provisionedPageData,
    discardedPageData,
    provisionedControlledPageCount,
    discardedControlledPageCount,
    fetchProvisionedData,
    fetchDiscardedData,
    provisionedCols,
    discardCols,
    provisionedColsLoading,
    discardColsLoading,
    provisionedTableConfig,
    discardTableConfig,
    filterMap,
    handleSelectCell,
  } = useDLRSAdvancedController();

  return (
    <Col>
      <Card
        title={provisionedTableConfig?.table_title}
        isLoading={provisionedColsLoading}
        className={provisionedPageData.data?.length === 0 ? 'card-7' : ''}
      >
        {provisionedCols[provisionedTableConfig?.table_id]?.length > 0 && (
          <FutureTable
            tableKey={provisionedTableConfig?.table_id}
            data={provisionedPageData.data || []}
            defaultColumns={provisionedCols[provisionedTableConfig?.table_id]}
            onRowClick={handleSelectCell}
            filterMap={filterMap}
            columnsConfig={{
              isStriped: false,
              dynamicColumns: false,
              expandColumns: true,
            }}
            toolbarConfig={{
              showExport: true,
              showRowNumber: true,
            }}
            paginationEnabled={true}
            exportConfig={{
              excelName: provisionedTableConfig?.file_name,
              sheetName: provisionedTableConfig?.file_name,
              id: provisionedTableConfig?.table_id,
            }}
            pagData={provisionedPageData}
            fetchData={fetchProvisionedData}
            controlledPageCount={provisionedControlledPageCount[provisionedTableConfig?.table_id]}
            table_id={provisionedTableConfig?.table_id}
          />
        )}
      </Card>
      <Card title={discardTableConfig?.table_title} isLoading={discardColsLoading} className={discardedPageData.data?.length === 0 ? 'card-7' : ''}>
        {discardCols[discardTableConfig?.table_id]?.length > 0 && (
          <FutureTable
            tableKey={discardTableConfig?.table_id}
            data={discardedPageData.data || []}
            defaultColumns={discardCols[discardTableConfig?.table_id]}
            onRowClick={handleSelectCell}
            filterMap={filterMap}
            columnsConfig={{
              isStriped: false,
              dynamicColumns: false,
              expandColumns: true,
            }}
            toolbarConfig={{
              showExport: true,
              showRowNumber: true,
            }}
            paginationEnabled={true}
            exportConfig={{ excelName: discardTableConfig?.file_name, sheetName: discardTableConfig?.file_name, id: discardTableConfig?.table_id }}
            pagData={discardedPageData}
            fetchData={fetchDiscardedData}
            controlledPageCount={discardedControlledPageCount[discardTableConfig?.table_id]}
            table_id={discardTableConfig?.table_id}
          />
        )}
      </Card>
    </Col>
  );
};
