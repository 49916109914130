/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { StyledPaginationBar, StyledButton, StyledIcon, StyledInput, styles } from './PaginationBar.styles';

const PaginationBar = ({
  paginationEnabled,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}) => {
  const pagination = useMemo(
    () =>
      paginationEnabled ? (
        <div css={styles.toolbarWrapper}>
          <div>
            <StyledButton onClick={() => gotoPage(0)} css={styles.button} disabled={!canPreviousPage} title="First page">
              <StyledIcon className="fa fa-angle-double-left" css={styles.icon} />
            </StyledButton>
            <StyledButton onClick={() => previousPage()} css={styles.button} disabled={!canPreviousPage} title="Previous page">
              <StyledIcon className="fa fa-angle-left" css={styles.icon} />
            </StyledButton>
            <StyledButton onClick={() => nextPage()} css={styles.button} disabled={!canNextPage} title="Next page">
              <StyledIcon className="fa fa-angle-right" css={styles.icon} />
            </StyledButton>
            <StyledButton onClick={() => gotoPage(pageCount - 1)} css={styles.button} disabled={!canNextPage} title="Last page">
              <StyledIcon className="fa fa-angle-double-right" css={styles.icon} />
            </StyledButton>
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
          <div>
            <span style={{ paddingLeft: '15px' }}>
              Go to page:{' '}
              <StyledInput
                type="number"
                defaultValue={1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                css={styles.input}
              />
            </span>{' '}
            <StyledInput
              type="select"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              css={styles.select}
            >
              {[50, 100, 500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </StyledInput>
          </div>
        </div>
      ) : null,
    [paginationEnabled, pageIndex, pageSize, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize]
  );

  return (
    <>
      <StyledPaginationBar css={styles.paginationBar}>{pagination}</StyledPaginationBar>
    </>
  );
};

export { PaginationBar };
