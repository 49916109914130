import styled from '@emotion/styled';

export const StyledTableHead = styled.thead``;

const tableHeadStyles = (filterable) => ({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  position: 'sticky',
  top: 0,
  zIndex: 2,
  userSelect: 'none',
  marginBottom: filterable ? '2rem' : 0,

  ':focus': {
    outline: 'none',
  },
});

const tableHeaderStyles = {
  fontSize: '12px',
  textTransform: 'uppercase',
  color: '#9a9a9a',
  backgroundColor: 'white',
  fontWeight: 400,
  marginTop: 0,
  marginLeft: 0,
  minHeight: '85px',

  flex: '1 0 auto',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  padding: '7px 5px',
  overflow: 'hidden',
  transition: '0.3s ease',
  transitionProperty: 'width, min-width, padding, opacity',
  margin: 'auto',

  textAlign: 'left',
  lineHeight: '2.5em !important',
  boxShadow: 'inset 0 0 0 0 transparent',
  minWidth: '200px',
  width: '200px',
};

const headerContentStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  '&:focus': {
    outline: 'none',
  },
};

const texOverflowStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const styles = {
  tableHead: tableHeadStyles,
  tableHeader: tableHeaderStyles,
  headerContent: headerContentStyles,
  headerText: texOverflowStyles,
};
