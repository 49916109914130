/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { useColumnHeaderController } from './ColumnHeader.controller';
import { StyledTableHeader, StyledHeaderContent, StyledIcon, StyledHeaderText, StyledToolbar, styles } from './ColumnHeader.styles';

const ColumnHeader = ({
  column,
  columnsOptions,
  filterNamesReachable,
  isSomeColumnFilterable,
  expandedColumnIds,
  handleExpandColumn,
  expandedColumnWidth,
  fixedColsPositions,
}) => {
  const { isTooltip, sortable, sortDirection, columnFilter, filterSet } = useColumnHeaderController({
    column,
    columnsOptions,
    filterNamesReachable,
  });

  const sort = useMemo(
    () =>
      sortable ? (
        <StyledIcon
          className={`fas fa-${sortDirection}`}
          css={styles.sortableIcon(sortDirection)}
          {...column.getHeaderProps(column.getSortByToggleProps())}
          title="Sort column"
        />
      ) : null,
    [sortable, sortDirection, column]
  );

  const expandColumn = useMemo(() =>
    columnsOptions.expandColumns ? (
      <StyledIcon className={`fas fa-arrows-alt-h`} title="Expand column" css={styles.icon} onClick={() => handleExpandColumn(column.id)} />
    ) : null
  );

  return (
    <StyledTableHeader
      className="table-th table-resizable-header"
      data-toggle="tooltip"
      title={column.Header}
      key={column.id}
      css={styles.tableHeader(
        isSomeColumnFilterable,
        expandedColumnIds.includes(column.id),
        expandedColumnWidth[column.id],
        column.fixed,
        fixedColsPositions[column.id]
      )}
    >
      <StyledHeaderContent css={styles.headerContent}>
        <StyledHeaderText css={styles.headerText}>{column.render('Header')}</StyledHeaderText>
        {isTooltip && (
          <StyledToolbar css={styles.toolbar}>
            {expandColumn}
            {sort}
          </StyledToolbar>
        )}
      </StyledHeaderContent>
      {!!columnFilter && <div id={column.id}>{columnFilter}</div>}
    </StyledTableHeader>
  );
};

export { ColumnHeader };
